import { useEffect, useState } from "react";
import { API } from "../../../../../controller/api/api";
import Select from "react-select";
import {
  Notifica,
  NotificaWarning,
  PrimeiraMaiuscula,
} from "../../../../../controller/util";
import { Button } from "../../../../../component/button";
import { InputPedido } from "../../../../pedido/componente/input";
import { FaFirstAid, FaPlus, FaTrash } from "react-icons/fa";
import { ModalComponent } from "../../../../../component/modal";
import {
  MascaraMonetaria,
  TransformarValor,
  formatarValor,
} from "../../../../../controller/data";
import { InputAdornment, TextField } from "@mui/material";
import { CustoMercadoriaVendida } from "../cmv";

export function FichaTecnica({ codigo, ValorVenda }) {
  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "#f5f5f5",
      minHeight: "34px",
      height: "34px",
      borderColor: "black",
      transition: "border-color 0.1s ease 0s",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#A8001C"
        : state.isFocused
        ? "#e5e5e5"
        : null,
      color: state.isSelected ? "white" : null,
    }),
  };

  const [Option, setOption] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [FichaProduto, setFichaProduto] = useState([]);
  const [isModal, setModal] = useState(false);
  const [mensagem, setmensagem] = useState("");
  const [Ingredientes, setIngredientes] = useState([]);
  const [CodigoDelet, setCodigoDelet] = useState(0);
  const [BodyCMV, setBodyCMV] = useState({});

  useEffect(() => {
    API.get(`v1/consulta/todos/ingredientes`).then((response) => {
      setIngredientes(response.data);

      let result = [];

      for (var i = 0; i < response.data.length; i++) {
        result.push({
          value: i,
          label: `${PrimeiraMaiuscula(response.data[i].descricao)} (${
            response.data[i].unidade
          })`,
        });
      }

      setOption(result);
    });

    API.get(`/v1/util/grava/ingrediente/ficha/produto/${codigo}`).then(
      (response) => {
        setFichaProduto(response.data);
        // console.log(response.data);
      }
    );
  }, []);

  function AddIngreditente() {
    // console.log(selectedOption);
    if (!selectedOption) {
      NotificaWarning("Nenhum ingrediente selecionado!");
      return false;
    }

    if (
      FichaProduto.some(
        (item) => item.idIngredientes === Ingredientes[selectedOption.value].id
      )
    ) {
      NotificaWarning("Ingrediente já compõem a ficha!");
      return false;
    }

    const novoItem = {
      id: 0,
      idProduto: codigo,
      idIngredientes: Ingredientes[selectedOption.value].id,
      quantidade: 0,
      descricao: Ingredientes[selectedOption.value].descricao,
      unidade: Ingredientes[selectedOption.value].unidade,
      custo: Ingredientes[selectedOption.value].custo,
    };

    // console.log(novoItem);

    setFichaProduto((prevState) => [...prevState, novoItem]);

    Notifica(
      `${PrimeiraMaiuscula(
        Ingredientes[selectedOption.value].descricao
      )} adicionado a ficha!`
    );
  }

  function Delete(id, descricao) {
    setCodigoDelet(id);
    setModal(true);
    setmensagem(`Deseja excluir o ${PrimeiraMaiuscula(descricao)}?`);
  }

  function ConfirmDelete() {
    const novosItens = FichaProduto.filter((_, idx) => idx !== CodigoDelet);
    setFichaProduto(novosItens);
    setModal(false);
  }

  function SalvarFicha() {
    API.post(`/v1/util/grava/ingrediente/ficha/produto`, FichaProduto);
    API.post(`/v2/cmv`, BodyCMV);

    Notifica("Ficha Técnica Salva Com Sucesso!");
  }

  function Altera(Index, Valor) {
    const novosItens = [...FichaProduto];
    novosItens[Index].quantidade = TransformarValor(Valor);
    setFichaProduto(novosItens);
  }

  return (
    <>
      <div style={{ overflowY: "scroll" }}>
        <Select
          value={selectedOption}
          onChange={setSelectedOption}
          options={Option}
          isSearchable={true}
          placeholder="Selecione ou pesquise..."
          styles={customStyles}
        />

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "10px",
            height: "35px",
            width: "100%",
            marginTop: "10px",
          }}
        >
          <Button
            descricao="Adicionar"
            evento={AddIngreditente}
            borderColor="#138138"
            fontColor="#138138"
            BorderRadius="5px"
            icon={<FaPlus />}
          />
        </div>

        <h1>
          <b>Composição do Produto</b>
        </h1>

        <div
          style={{ overflowY: "scroll", height: "500px", marginBottom: "20px" }}
        >
          {FichaProduto.map((dados, index) => (
            <div
              key={index}
              style={{
                border: "1px solid #ddd",
                borderRadius: "10px",
                padding: "20px",
                paddingTop: "40px", // Aumenta o espaçamento superior para acomodar o ícone
                marginBottom: "15px",
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                position: "relative",
                backgroundColor: "#fff",
              }}
            >
              <FaTrash
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  cursor: "pointer",
                  color: "#A8001C",
                  fontSize: "1.2em",
                  transition: "color 0.2s",
                }}
                onMouseEnter={(e) => (e.currentTarget.style.color = "#ff4d4d")}
                onMouseLeave={(e) => (e.currentTarget.style.color = "#A8001C")}
                onClick={() => {
                  Delete(index, dados.descricao);
                }}
              />
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "15px",
                }}
              >
                <h1 style={{ margin: 0, fontSize: "1.5em", flexGrow: 1 }}>
                  {PrimeiraMaiuscula(dados.descricao)}
                </h1>
                <span
                  style={{
                    display: "block",
                    backgroundColor: "#A8001C",
                    color: "white",
                    borderRadius: "20px",
                    padding: "5px 10px",
                    fontSize: "0.9em",
                    textAlign: "center",
                    marginLeft: "10px",
                  }}
                >
                  {PrimeiraMaiuscula(dados.unidade)}
                </span>
              </div>

              <div
                style={{ display: "flex", gap: "15px", alignItems: "center" }}
              >
                <InputPedido
                  Descricao="Quantidade"
                  Retorno={Altera}
                  index={index}
                  Valor={formatarValor(dados.quantidade)}
                />
                <TextField
                  label={"Valor"}
                  size="small"
                  style={{ flexGrow: 1 }}
                  value={formatarValor(dados.quantidade * dados.custo)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">R$</InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
          ))}
        </div>
        <h1 style={{ marginBottom: "10px" }}>
          <b>Composição de Preço</b>
        </h1>
        <CustoMercadoriaVendida
          codigo={codigo}
          ficha={FichaProduto}
          setBodyCMV={setBodyCMV}
          ValorVenda={ValorVenda}
        />
        <Button
          descricao="Salvar Ficha Técnica"
          evento={SalvarFicha}
          color="white"
          borderColor="#a4001b"
          fontColor="#a4001b"
          BorderRadius="5px"
        />

        {isModal ? (
          <ModalComponent
            question={mensagem}
            onYesClick={ConfirmDelete}
            onNoClick={() => setModal(false)}
          />
        ) : null}
      </div>
    </>
  );
}
