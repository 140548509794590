import { HeaderComponent } from "../../component/header";
import { API } from "../../controller/api/api";
import { ComponenteCategoria } from "./component/categoria";
import { useState, useEffect } from "react";
import { NovaCategoria } from "./component/novacategoria";
import { Button } from "../../component/button";
import { Notifica, PrimeiraMaiuscula } from "../../controller/util";
import { ParametroConfiguracao } from "../../controller/parametros";
import { FaPizzaSlice } from "react-icons/fa";
import { DataAtual, PrimeiroDiaDoMes } from "../../controller/data";
import { ShowModal } from "../../component/modalPai";
import { InputPedido } from "../pedido/componente/input";
import { VendasProdutosCardapio } from "../../component/venda/produto";
import { Toggle } from "../../component/toggle";
import { BuscaLocal, GravaLocal } from "../../controller/storage";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import LoadingOverlay from "../../component/load";

export function ProductPage() {
  const [Type, setType] = useState(1);
  const [Codigo, setCodigo] = useState(0);
  const [isCategory, setIsCategory] = useState(false);
  const [isIFood, setIsIFood] = useState(false);

  const [DadosCategoria, setDadosCategoria] = useState([]);
  const [Carregados, setCarregados] = useState(0);
  const [loading, setLoading] = useState(false);

  const [DataInicial, setDataInicial] = useState(PrimeiroDiaDoMes());
  const [DataFinal, setDataFinal] = useState(DataAtual());
  const [TipoGrafico, setTipoGrafico] = useState(0);
  const [ModalFiltro, setModalFiltro] = useState(false);
  const [OcutarRelatorio, setOcutarRelatorio] = useState(
    BuscaLocal("OcutarRelatorio") == "true" ? true : false
  );

  const [CampoPesquisa, setCampoPesquisa] = useState("");
  const [CategoriaSelecionada, setCategoriaSelecionada] = useState(null);

  useEffect(() => {
    if (Carregados != DadosCategoria?.length) {
      if (Carregados > DadosCategoria?.length) {
        setLoading(false);
      } else {
        // setLoading(true);
      }
    } else {
      setLoading(false);
    }
  }, [Carregados]);

  useEffect(() => {
    // setLoading(true);
    const fetchData = async () => {
      try {
        const response = await API.get("/v1/categorias");
        // setLoading(true);
        setCarregados(0);
        setDadosCategoria(response.data);

        const ifood = await ParametroConfiguracao("ifoodIntegracao");
        setIsIFood(ifood == 1);
      } catch (error) {}
    };

    fetchData();
  }, []);

  function NewCategory() {
    setType(1);
    setCodigo(0);
    setIsCategory(true);
  }

  function AlteraCategoria(codigo) {
    setType(0);
    setCodigo(codigo);
    setIsCategory(true);
  }

  function CarregarDadosiFood() {
    API.get("/v1/util/ifood/importa/produto");
    Notifica("Os dados estão sendo importados!");
  }

  function AlteraValoresInput(type, valor) {
    if (type == "Data Inicial") {
      setDataInicial(valor);
      // BuscarDados();
    }
    if (type == "Data Final") {
      setDataFinal(valor);
      // BuscarDados();
    }
  }

  function Retorno() {
    // DadosCategoria
    setCarregados((prevState) => prevState + 1);
  }

  const handleCategoriaSelecionada = (categoriaId) => {
    const categoriaDiv = document.getElementById(categoriaId);
    if (categoriaDiv) {
      categoriaDiv.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  function Consulta() {
    const response = API.get("/v1/categorias");
    // setLoading(true);
    setCarregados(0);
    setDadosCategoria(response.data);
    setIsCategory(false);
  }

  return (
    <>
      {loading && <LoadingOverlay />}{" "}
      <HeaderComponent ModalFiltro={() => setModalFiltro(true)}>
        <div
          style={{
            height: "100%",
            width: "100%",
            display: loading ? "none" : "flex",
            justifyContent: "center",
          }}
        >
          <div style={{ height: "100%", width: "65vw" }}>
            {!OcutarRelatorio ? (
              <>
                <h1 style={{ fontSize: "1.6rem" }}>
                  <strong>Relatório de Vendas</strong>
                </h1>
                <VendasProdutosCardapio
                  dataInicial={DataInicial}
                  dataFinal={DataFinal}
                  tipo={TipoGrafico}
                />
              </>
            ) : null}
            <div
              id="divPesquisa"
              style={{
                position: "sticky",
                top: 60,
                zIndex: 95, // Z-index opcional para garantir que fique sobre outros elementos
                backgroundColor: "#fff", // Cor de fundo opcional
                padding: "10px",
                marginBottom: "10px",
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h1 style={{ fontSize: "1.6rem" }}>
                  <strong>Cardápio</strong>
                </h1>
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      width: "210px",
                      height: "35px",
                      marginRight: "10px",
                      marginBottom: "15px",
                    }}
                  >
                    <Button
                      descricao="Nova Categoria"
                      icon={<FaPizzaSlice />}
                      color="white"
                      borderColor="#a4001b"
                      fontColor="#a4001b"
                      BorderRadius="5px"
                      evento={NewCategory}
                    />
                  </div>
                  {isIFood ? (
                    <>
                      <div style={{ width: "210px", height: "35px" }}>
                        <Button
                          descricao="Carregar Dados iFood"
                          color="white"
                          borderColor="#a4001b"
                          fontColor="#a4001b"
                          BorderRadius="5px"
                          evento={CarregarDadosiFood}
                        />
                      </div>
                    </>
                  ) : null}
                </div>
              </div>

              {/* const [CampoPesquisa, setCampoPesquisa] = useState('');
  const [CategoriaSelecionada, setCategoriaSelecionada] = useState(null); */}

              {/* Div que fica fixa no topo */}

              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  width: "100%",
                  marginBottom: "10px",
                }}
              >
                <div style={{ width: "80%" }}>
                  <TextField
                    size="small"
                    label={"Pesquisa Por Nome"}
                    variant="outlined"
                    onChange={(e) => setCampoPesquisa(e.target.value)}
                    // onFocus={(e) =>
                    //   (e.target.style.backgroundColor = "rgba(168, 0, 28, 0.15)")
                    // }
                    value={CampoPesquisa}
                    fullWidth
                  />
                </div>
                <div style={{ width: "25%" }}>
                  <FormControl fullWidth>
                    <InputLabel id={"Categoria"}>Categoria</InputLabel>
                    <Select
                      labelId={"Categoria"}
                      id={"Categoria"}
                      value={CategoriaSelecionada}
                      label={"Categoria"}
                      onChange={(e) => [
                        setCategoriaSelecionada(e.target.value),
                        handleCategoriaSelecionada(e.target.value),
                      ]}
                      size="small"
                    >
                      {DadosCategoria.map((item, index) => (
                        <MenuItem key={index} value={item.codigo}>
                          {PrimeiraMaiuscula(item.descricao)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>

            {DadosCategoria.map((categoria, index) => (
              <ComponenteCategoria
                key={index}
                categoria={categoria}
                Retorno={Retorno}
                pesquisa={CampoPesquisa}
                AlteraCategoria={AlteraCategoria}
              />
            ))}
          </div>
        </div>

        {isCategory ? (
          <NovaCategoria type={Type} codigo={Codigo} close={Consulta} />
        ) : null}

        {ModalFiltro ? (
          <>
            <ShowModal closeButton={() => setModalFiltro(false)}>
              <div style={{ width: "250px", paddingTop: "20px" }}>
                <InputPedido
                  Tipo="date"
                  Descricao="Data Inicial"
                  Retorno={AlteraValoresInput}
                  Valor={DataInicial}
                  sx={{ marginBottom: "15px" }}
                />

                <InputPedido
                  Tipo="date"
                  Descricao="Data Final"
                  Retorno={AlteraValoresInput}
                  Valor={DataFinal}
                  sx={{ marginBottom: "15px" }}
                />

                <FormControl fullWidth>
                  <InputLabel id="tipo-grafico">Tipo do Gráfico</InputLabel>
                  <Select
                    value={TipoGrafico}
                    id="tipo-grafico"
                    label="Tipo do Gráfico"
                    onChange={(e) => setTipoGrafico(e.target.value)}
                    size="small"
                  >
                    <MenuItem value={0}>Unidade</MenuItem>
                    <MenuItem value={1}>Valor</MenuItem>
                  </Select>
                </FormControl>
                <Toggle
                  Titulo={`Ocultar Relatório Venda`}
                  Change={() => [
                    setOcutarRelatorio(!OcutarRelatorio),
                    GravaLocal("OcutarRelatorio", !OcutarRelatorio),
                  ]}
                  Checked={OcutarRelatorio}
                />
              </div>
            </ShowModal>
          </>
        ) : null}
      </HeaderComponent>
    </>
  );
}
