import { useEffect, useState } from "react";
import { API } from "../../controller/api/api";
import { ShowModal } from "../../component/modalPai";
import { ApagaLocal, BuscaLocal, GravaLocal } from "../../controller/storage";
import { isMobileDevice } from "../../controller/mobile";
import { MascaraMonetaria, RemoveMascara } from "../../controller/data";
import {
  Box,
  Button,
  Typography,
  Grid,
  Card,
  CardContent,
} from "@mui/material";

export function PageBloqueado({ children }) {
  const [Data, setData] = useState("");
  const [Dias, setDias] = useState(-1);
  const [Faturas, setFaturas] = useState([]);
  const [MostraNotificacao, setMostraNotificacao] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState("rgb(168, 0, 28)");
  const [MostraButtonFechar, setMostraButtonFechar] = useState(true);

  const handleMouseOver = () => {
    setBackgroundColor("rgba(168, 0, 28, 0.9)");
  };

  const handleMouseOut = () => {
    setBackgroundColor("rgb(168, 0, 28)");
  };

  useEffect(() => {
    let DataUltima = BuscaLocal("data-notificacao");
    let Data = new Date();
    let DataAtual = formatDateToYMD(Data);

    if (DataUltima !== DataAtual) {
      fetchData();
    }
  }, []);

  const fetchData = async () => {
    const CACHE_KEY = "bloqueio_data";
    const CACHE_EXPIRATION_TIME = 60 * 60 * 1000;

    const cachedData = JSON.parse(localStorage.getItem(CACHE_KEY));

    if (
      cachedData &&
      Date.now() - cachedData.timestamp < CACHE_EXPIRATION_TIME
    ) {
      setData(cachedData.data.vencimento);
      setDias(cachedData.data.dias);

      if (parseInt(cachedData.data.dias) <= 7 && isMobileDevice) {
        setMostraNotificacao(true);
      }
      if (parseInt(cachedData.data.dias) < 1) {
        setMostraButtonFechar(false);
      }
    }

    API.get(`v2/dados/bloqueio`).then((response) => {
      if (response) {
        const data = response.data;
        if (
          data !==
          "REST request failed: Error sending data: (12002) O tempo limite da operação foi atingido"
        ) {
          if (data.faturas) {
            const faturas = data.faturas;

            if (data.faturas) {
              const faturasProximas = faturas.filter((fatura) => {
                const diasRestantes = calcularDiasRestantes(fatura.vencimento);
                console.log(fatura.vencimento);
                return diasRestantes <= 15; // Filtra faturas com vencimento em até 15 dias
              });
              setFaturas(faturasProximas);
            }
          }

          setData(data.vencimento);
          if (parseInt(data.dias) <= 7 && isMobileDevice) {
            setMostraNotificacao(true);
          }
          if (parseInt(data.dias) < 1) {
            setMostraButtonFechar(false);
          }
          setDias(data.dias);

          const newCachedData = { data, timestamp: Date.now() };
          GravaLocal(CACHE_KEY, JSON.stringify(newCachedData));
        }
      }
    });
  };

  function calcularDiasRestantes(vencimento) {
    // Converte a data de dd/mm/yyyy para o formato aceito pelo objeto Date
    const partes = vencimento.split("/"); // Divide a string "dd/mm/yyyy" em partes [dia, mês, ano]
    const dataVencimento = new Date(partes[2], partes[1] - 1, partes[0]); // Cria a data no formato Date(ano, mês, dia)

    const hoje = new Date();
    const diferenca = dataVencimento - hoje;

    return Math.ceil(diferenca / (1000 * 60 * 60 * 24));
  }

  function formatDateToYMD(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  function CloseNotificacao() {
    let Data = new Date();
    GravaLocal("data-notificacao", formatDateToYMD(Data));
    setMostraNotificacao(!MostraNotificacao);
  }

  function AtualizarNotificacao() {
    ApagaLocal("bloqueio_data");
    ApagaLocal("data-notificacao");
    fetchData();
  }

  return (
    <>
      <div>
        {MostraNotificacao ? (
          <ShowModal altura={40} largura={0}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <img
                src="/goopedir-logo.png"
                style={{
                  width: "120px",
                  borderRadius: "100px",
                  marginBottom: "20px",
                }}
              />
              <Typography variant="h5" fontWeight="bold">
                Aviso de Bloqueio
              </Typography>

              {MostraButtonFechar ? (
                <>
                  <Typography variant="body1" align="center" mt={2}>
                    Seu sistema vence em <b>{Dias}</b> dias. Após o vencimento,
                    o site/sistema ficarão inacessíveis.
                  </Typography>

                  <Grid container spacing={2} mt={2}>
                    {Faturas.map((fatura, index) => (
                      <Grid item xs={12} key={index}>
                        <Card>
                          <CardContent>
                            <Typography variant="body2">
                              <b>Vencimento:</b> {fatura.vencimento}
                            </Typography>
                            <Typography variant="body2" color="green">
                              <b>Total:</b>{" "}
                              {MascaraMonetaria(RemoveMascara(fatura.total))}
                            </Typography>
                            <Button
                              variant="contained"
                              color="primary"
                              href={fatura.link}
                              target="_blank"
                              fullWidth
                              sx={{ mt: 1 }}
                            >
                              Pagar Fatura
                            </Button>
                          </CardContent>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>

                  <Button
                    fullWidth
                    variant="contained"
                    color="error"
                    sx={{ mt: 3 }}
                    onClick={CloseNotificacao}
                    onMouseOver={handleMouseOver}
                    onMouseOut={handleMouseOut}
                  >
                    Fechar
                  </Button>
                </>
              ) : (
                <>
                  <Typography
                    variant="body1"
                    color="error"
                    align="center"
                    mt={2}
                  >
                    Sistema bloqueado!
                  </Typography>

                  <Button
                    fullWidth
                    variant="contained"
                    color="error"
                    sx={{ mt: 3 }}
                    onClick={AtualizarNotificacao}
                    onMouseOver={handleMouseOver}
                    onMouseOut={handleMouseOut}
                  >
                    Atualizar
                  </Button>
                </>
              )}
            </Box>
          </ShowModal>
        ) : null}

        {children}
      </div>
    </>
  );
}
