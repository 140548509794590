import axios from "axios";
import { ApagaLocal, BuscaLocal, GravaLocal } from "../storage";
import { unstable_HistoryRouter } from "react-router-dom";

export function BASEURL() {
  const url = JSON.parse(BuscaLocal("base-url"));
  return url?.host
    ? url?.host
    : document.querySelector('link[name="url-api"]').href;
}

export const axiosCheckService = axios.create({
  baseURL: BASEURL(),
});

export const API = axios.create({
  baseURL: BASEURL(),
  // baseURL: 'http://192.168.0.64:9000/',
  headers: {
    "Content-Type": "application/json; text/html; charset=utf-8",
  },
});

export const GooPedirAPI = axios.create({
  baseURL: "https://goopedir.com/",
  headers: {
    "Content-Type": "application/json; text/html; charset=utf-8",
  },
});

// Interceptador de requisição para registrar a hora de início
API.interceptors.request.use((request) => {
  request.metadata = { startTime: new Date() };
  return request;
});

// Interceptador de resposta para calcular o tempo de resposta e armazenar as informações
API.interceptors.response.use(
  (response) => {
    const endTime = new Date();
    const duration = endTime - response.config.metadata.startTime;
    if (
      response.config.url !== "whatsapp/goopedir/data" &&
      response.config.url !== "v2/status/site" &&
      response.config.url !== "v2/status" &&
      response.config.url !== "v1/util/ifood/status"
    ) {
      // //console.log(
      //   `Rota: ${response.config.url} | Tempo de resposta: ${duration} ms`
      // );
    }

    // Aqui você pode armazenar a rota e o tempo de resposta onde desejar
    return response;
  },
  (error) => {
    // const endTime = new Date();
    // const duration = endTime - error.config.metadata.startTime;
    // console.log(error.response.status);
    // ApagaLocal("ErroInterno");
    // if (error.response.status == 499) {
    //   GravaLocal("ErroInterno", "sem conexão");
    //   window.location.href = "/sem-servico"; // Redireciona para a página de erro
    // } else if (error.response.status == 499) {
    //   if (error.response.data.url) {
    //     GravaLocal("ErroInterno", error.response.data);
    //     console.log(error.response.data);
    //     window.location.href = "/sem-servico"; // Redireciona para a página de erro
    //   }
    // } else if (!error.response || error.response.status >= 500) {
    //   // Verifica se estamos dentro de um ambiente React (browser) e redireciona
    //   window.location.href = "/sem-servico"; // Redireciona para a página de erro
    // } else {
    //   ApagaLocal("ErroInterno");
    // }
    // return Promise.reject(error);
  }
);

export async function StatusAPI() {
  return API.get("v2/status")
    .then((dados) => {
      GravaLocal("DadosGeral", JSON.stringify(dados.data));
      GravaLocal("modulos", JSON.stringify(dados.data.modulos));
      GravaLocal("site", JSON.stringify(dados.data.site));
      return dados; // Certifique-se de retornar os dados aqui
    })
    .catch((error) => {
      console.error("Ocorreu um erro ao buscar os dados:", error); // É bom manter um log de erro
      throw error; // Repropaga o erro para que o .catch no uso do StatusAPI possa capturar
    });
}

export function GetParametros() {
  try {
    API.get(`v2/parametros`).then((dados) => {
      console.log(dados);
      if (dados) {
        GravaLocal("parametros", dados.data[0]);
        return dados;
      }

      // Certifique-se de retornar os dados aqui
    });
  } catch (error) {
    //////console.log(error);
    // throw error;
  }
}
