import { IconButton, InputAdornment, TextField } from "@mui/material";
import { FaRegTrashAlt } from "react-icons/fa";
import { PrimeiraMaiuscula } from "../../../controller/util";
import { useState } from "react";
import { ModalComponent } from "../../../component/modal";
import {
  MascaraMonetaria,
  MascaraNumericaTresCasas,
  RemoveMascara,
} from "../../../controller/data";

export function ItemFichaTecnica({
  Unidade,
  Descricao,
  Quantidade,
  Id,
  Remove,
  Atualiza,
  Custo,
}) {
  const [quantidade, setQuantidade] = useState(
    MascaraNumericaTresCasas(Quantidade)
  );
  const [isModal, setModal] = useState(false);

  function handleYesClick() {
    setModal(false);
    Remove(Id);
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          marginBottom: "10px",
          borderBottom: "1px solid #ccc",
          padding: "5px",
          gap: "5px",
        }}
      >
        <div
          style={{
            width: "100%",
            alignContent: "center",
          }}
        >
          <b>
            <p>{PrimeiraMaiuscula(Descricao)}</p>
          </b>
          <p>{MascaraMonetaria(Custo * RemoveMascara(quantidade))}</p>
        </div>
        <TextField
          label="Quantidade"
          id="outlined-start-adornment"
          style={{ width: "200px" }}
          value={quantidade}
          onChange={(e) => setQuantidade(e.target.value)}
          onBlur={(e) => [
            setQuantidade(
              MascaraNumericaTresCasas(RemoveMascara(e.target.value))
            ),
            Atualiza(Id, RemoveMascara(e.target.value)),
          ]}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {PrimeiraMaiuscula(Unidade)}
              </InputAdornment>
            ),
          }}
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <IconButton color="primary" onClick={() => setModal(true)}>
            <FaRegTrashAlt />
          </IconButton>
        </div>
      </div>

      {isModal ? (
        <ModalComponent
          question={"Deseja remover o Insulmo?"}
          onYesClick={handleYesClick}
          onNoClick={() => setModal(false)}
        />
      ) : null}
    </>
  );
}
