import { useEffect, useState } from "react";
import {
  FaBreadSlice,
  FaCartPlus,
  FaChartPie,
  FaClock,
  FaDesktop,
  FaFileInvoiceDollar,
  FaFilter,
  FaGift,
  FaGifts,
  FaHamburger,
  FaMapMarked,
  FaMapMarkedAlt,
  FaMinus,
  FaMobileAlt,
  FaMoneyBillWave,
  FaPencilAlt,
  FaPersonBooth,
  FaPizzaSlice,
  FaPowerOff,
  FaPrint,
  FaQrcode,
  FaReceipt,
  FaRegUser,
  FaStore,
  FaUserNinja,
  FaWallet,
  FaWhatsapp,
} from "react-icons/fa";
import { isMobileDevice } from "../../../../controller/mobile";
import { ItensSideBar } from "../itenssidebar";
import { Logoff, isLogin } from "../../../../controller/util";
import { TextIdioma } from "../../../../controller/data";
import {
  ParametroConfiguracao,
  PermissaoAcesso,
} from "../../../../controller/parametros";
import { API } from "../../../../controller/api/api";
import { PermissaoModulo } from "../../../../controller/modulos";
import { BuscaLocal } from "../../../../controller/storage";

export function SidebarComponent({ Close, Navegacao }) {
  const logoSource = "logo-goopedir-b.png";
  const [isHovered, setIsHovered] = useState(false);
  const [isLogoHovered, setIsLogoHovered] = useState(false);
  const [isComanda, setIsComanda] = useState(false);

  const iconColor = isHovered ? "#000000" : "#FFFFFF";

  const url = document.querySelector('link[name="url-server"]').href;
  const handleHover = () => {
    setIsHovered(!isHovered);
  };

  async function Load() {
    let comanda = await ParametroConfiguracao("comanda");

    if (comanda == 1 && isMobileDevice()) {
      setIsComanda(true);
    }
  }

  useEffect(() => {
    Load();
  }, []);

  let SubIten = [
    {
      link: "/cadastro-insulmos",
      descricao: "Insulmos",
      icone: <FaBreadSlice style={{ marginLeft: "5px" }} />,
      visible: PermissaoAcesso("estoque"),
      new: true,
    },
    {
      link: "/cadastro-impressora",
      descricao: "Impressora",
      icone: <FaPrint style={{ marginLeft: "5px" }} />,
      visible: PermissaoAcesso("cadImpressora"),
      new: false,
    },
    {
      link: "/cadastro-usuario",
      descricao: "Usuário",
      icone: <FaRegUser style={{ marginLeft: "5px" }} />,
      visible: PermissaoAcesso("dashboard"),
      new: false,
    },

    {
      link: "/cadastro-taxa-entrega",
      descricao: "Taxa Entrega",
      icone: <FaMapMarked style={{ marginLeft: "5px" }} />,
      visible: PermissaoAcesso("cadTaxa"),
      new: false,
    },
    {
      link: "/cadastro-motoboy",
      descricao: "Motoboy",
      icone: <FaUserNinja style={{ marginLeft: "5px" }} />,
      visible: PermissaoModulo("motoboy") && PermissaoAcesso("cadMotoboy"),
      new: false,
    },
    {
      link: "/cadastro-tipo-pagamento",
      descricao: "Tipo Pagamento",
      icone: <FaWallet style={{ marginLeft: "5px" }} />,
      visible: PermissaoAcesso("cadPaga"),
      new: false,
    },
    {
      link: "/cadastro-cliente",
      descricao: "Cliente",
      icone: <FaRegUser style={{ marginLeft: "5px" }} />,
      visible: PermissaoAcesso("cadCli"),
      new: false,
    },
    {
      link: "/cadastro-comanda",
      descricao: "Comanda",
      icone: <FaFileInvoiceDollar style={{ marginLeft: "5px" }} />,
      visible: PermissaoModulo("mesas") && PermissaoAcesso("caixa"),
      new: false,
    },
    {
      link: "/fidelidade",
      descricao: "Fidelidade",
      icone: <FaGift Click style={{ marginLeft: "5px" }} />,
      visible: PermissaoModulo("fidelidade"),
      new: false,
    },
    {
      link: "/cadastro-cupom-desconto",
      descricao: "Cupom Desconto",
      icone: <FaGifts style={{ marginLeft: "5px" }} />,
      visible: PermissaoModulo("desconto") && PermissaoAcesso("cadCupom"),
      new: false,
    },
  ];

  function NovoPedido() {
    API.get("v1/codigo/pedido").then(function (response) {
      return `/pedido/${response.data.codigo}`;
    });
  }

  return (
    <>
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.2)", // Escurecimento do fundo
          zIndex: 998, // Certifique-se de que o overlay está abaixo do modal
        }}
      />
      <div
        style={{
          width: isMobileDevice() ? "100%" : "285px",
          height: "100%",
          position: "fixed",
          left: 0,
          top: 0,
          backgroundColor: "#A8001C",
          zIndex: 99999,
          // position: 'initial'
          // padding: '20px',
        }}
      >
        <div
          style={{
            backgroundColor: "#A8001C",
            height: "60px",
            display: "flex",
            alignItems: "center",
            position: "fixed",
            top: 0,
            width: "100%",
          }}
        >
          <FaHamburger
            style={{
              marginLeft: "20px",
              cursor: "pointer",
              color: iconColor,
              transition: "transform 0.55s",
              transform: isHovered ? "rotate(-90deg)" : "rotate(0deg)",
            }}
            onClick={Close}
            onMouseEnter={handleHover}
            onMouseLeave={handleHover}
          />
          <img
            className="logo"
            src={url + logoSource}
            alt="Logo"
            style={{
              width: "100px",
              marginLeft: "20px",
              cursor: isLogoHovered ? "pointer" : "initial",
            }}
          />
        </div>
        <div
          style={{ padding: "10px", marginTop: "40px", fontSize: "1rem" }}
        ></div>

        <div style={{ overflow: "auto", height: "100%" }}>
          <ul style={{ listStyle: "none", padding: "10px" }}>
            {isLogin() > 0 ? (
              <ItensSideBar
                icone={<FaDesktop />}
                Descricao={TextIdioma("Inicio")}
                link="/principal"
                Navegacao={Navegacao}
              />
            ) : null}

            {PermissaoModulo("caixa") &&
            isLogin() > 0 &&
            isMobileDevice() == false &&
            PermissaoAcesso("caixa") ? (
              <ItensSideBar
                icone={<FaMoneyBillWave />}
                Descricao={TextIdioma("Caixa")}
                link="/caixa"
                Navegacao={Navegacao}
              />
            ) : null}

            {isLogin() > 0 &&
            isMobileDevice() == false &&
            PermissaoAcesso("dashboard") ? (
              <ItensSideBar
                icone={<FaChartPie />}
                Descricao={TextIdioma("DashBoard")}
                link="/dashboard"
                Navegacao={Navegacao}
              />
            ) : null}
            {isLogin() > 0 &&
            isMobileDevice() == false &&
            PermissaoAcesso("dashboard") ? (
              <ItensSideBar
                icone={<FaPizzaSlice />}
                Descricao={TextIdioma("Cardápio")}
                link="/cardapio"
                Navegacao={Navegacao}
              />
            ) : null}
            {isLogin() > 0 &&
            isMobileDevice() == false &&
            PermissaoAcesso("dashboard") ? (
              <ItensSideBar
                icone={<FaFilter />}
                Descricao={TextIdioma("Ativar/Pausar")}
                link="/cardapio-ativa-bloqueia"
                Navegacao={Navegacao}
              />
            ) : null}
            {PermissaoModulo("estoque") &&
            isLogin() > 0 &&
            isMobileDevice() == false &&
            PermissaoAcesso("estoque") ? (
              <ItensSideBar
                icone={<FaStore />}
                Descricao={TextIdioma("Estoque")}
                link="/estoque"
                Navegacao={Navegacao}
              />
            ) : null}

            {isLogin() > 0 &&
            isMobileDevice() == false &&
            PermissaoAcesso("dashboard") ? (
              <ItensSideBar
                icone={<FaReceipt />}
                Descricao={TextIdioma("Pedidos")}
                link="/pedidos"
                Navegacao={Navegacao}
              />
            ) : null}
            {isLogin() > 0 &&
            isMobileDevice() == false &&
            PermissaoAcesso("dashboard") ? (
              <ItensSideBar
                icone={<FaClock />}
                Descricao={TextIdioma("Horário")}
                link="/horario"
                Navegacao={Navegacao}
              />
            ) : null}

            {PermissaoModulo("pdv") &&
            isLogin() > 0 &&
            PermissaoAcesso("cadPedido") ? (
              <ItensSideBar
                icone={<FaStore />}
                Descricao={TextIdioma("Novo Pedido")}
                link={"/pedido/0"}
                Navegacao={Navegacao}
              />
            ) : null}

            {PermissaoModulo("mesas") && isLogin() > 0 && isComanda ? (
              <ItensSideBar
                icone={<FaQrcode />}
                Descricao={TextIdioma("Comanda")}
                link="/comanda"
                Navegacao={Navegacao}
              />
            ) : null}
            {PermissaoModulo("mesas") &&
            isLogin() > 0 &&
            isMobileDevice() == false ? (
              <ItensSideBar
                icone={<FaMobileAlt />}
                Descricao={TextIdioma("Mesas")}
                link="/salao"
                Navegacao={Navegacao}
              />
            ) : null}

            {PermissaoModulo("rotas") &&
            isLogin() > 0 &&
            isMobileDevice() == false &&
            PermissaoAcesso("dashboard") ? (
              <ItensSideBar
                icone={<FaMapMarkedAlt />}
                Descricao={TextIdioma("Rotas")}
                link="/controle-rotas"
                Navegacao={Navegacao}
              />
            ) : null}

            {PermissaoModulo("pix") &&
            isLogin() > 0 &&
            isMobileDevice() == false &&
            PermissaoAcesso("dashboard") ? (
              <ItensSideBar
                icone={
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width="16"
                    height="16"
                    viewBox="0 0 48 48"
                  >
                    <path
                      fill="#fff"
                      d="M11.9,12h-0.68l8.04-8.04c2.62-2.61,6.86-2.61,9.48,0L36.78,12H36.1c-1.6,0-3.11,0.62-4.24,1.76	l-6.8,6.77c-0.59,0.59-1.53,0.59-2.12,0l-6.8-6.77C15.01,12.62,13.5,12,11.9,12z"
                    ></path>
                    <path
                      fill="#fff"
                      d="M36.1,36h0.68l-8.04,8.04c-2.62,2.61-6.86,2.61-9.48,0L11.22,36h0.68c1.6,0,3.11-0.62,4.24-1.76	l6.8-6.77c0.59-0.59,1.53-0.59,2.12,0l6.8,6.77C32.99,35.38,34.5,36,36.1,36z"
                    ></path>
                    <path
                      fill="#fff"
                      d="M44.04,28.74L38.78,34H36.1c-1.07,0-2.07-0.42-2.83-1.17l-6.8-6.78c-1.36-1.36-3.58-1.36-4.94,0	l-6.8,6.78C13.97,33.58,12.97,34,11.9,34H9.22l-5.26-5.26c-2.61-2.62-2.61-6.86,0-9.48L9.22,14h2.68c1.07,0,2.07,0.42,2.83,1.17	l6.8,6.78c0.68,0.68,1.58,1.02,2.47,1.02s1.79-0.34,2.47-1.02l6.8-6.78C34.03,14.42,35.03,14,36.1,14h2.68l5.26,5.26	C46.65,21.88,46.65,26.12,44.04,28.74z"
                    ></path>
                  </svg>
                }
                Descricao={TextIdioma("PIX Pendente")}
                link="/pix-pendente"
                Navegacao={Navegacao}
              />
            ) : null}

            {PermissaoModulo("campanha") &&
            isLogin() > 0 &&
            isMobileDevice() == false &&
            PermissaoAcesso("dashboard") ? (
              <ItensSideBar
                icone={<FaWhatsapp />}
                Descricao={TextIdioma("Campanha")}
                link="/marketin"
                Navegacao={Navegacao}
              />
            ) : null}

            {isLogin() > 0 && isMobileDevice() == false ? (
              <ItensSideBar
                icone={<FaPencilAlt />}
                Descricao={TextIdioma("Cadastros")}
                link="#"
                sub={SubIten}
                Navegacao={Navegacao}
              />
            ) : null}

            <ItensSideBar
              icone={<FaPowerOff />}
              Descricao={TextIdioma("Sair")}
              link={
                BuscaLocal("url-login") ? BuscaLocal("url-login") : "/login"
              }
              Click={() => Logoff()}
              Navegacao={Navegacao}
            />
          </ul>
          <div
            style={{
              // position: "fixed",
              // bottom: "0",
              width: "95%",
              backgroundColor: "#A8001C",
              // textAlign: "center",
              padding: "10px",
              fontSize: "14px",
              width: isMobileDevice() ? "100%" : "200px",
              marginBottom: "50px",
            }}
          >
            {/* <h3 style={{ fontSize: "0.8rem" }}>
              <b>
                Todos os direitos reservados{" "}
                <span style={{ color: "white" }}>GooPedir</span>
              </b>
            </h3> */}
          </div>
        </div>
      </div>
    </>
  );
}
