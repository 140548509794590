import FormularioGenerico, { TelaGenerica } from "../../component/generico";
import { React, useEffect, useMemo, useRef, useState } from "react";
import { API } from "../../controller/api/api";
import { HeaderComponent } from "../../component/header";
import {
  NotificaWarning,
  PrimeiraMaiuscula,
  SwitchPadraoTabela,
} from "../../controller/util";
import { FaPen, FaSave } from "react-icons/fa";
import { ComponentCadastro } from "../../component/header/cadastro";
import {
  MascaraMonetaria,
  MascaraNumerica,
  RemoveMascara,
} from "../../controller/data";
import Table from "../../component/tabela";
import {
  FormControlLabel,
  FormGroup,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Switch,
  TextField,
} from "@mui/material";
import { ModalGravarDados } from "../../component/gravar";
import { InputPedido } from "../pedido/componente/input";
import LoadingOverlay from "../../component/load";
import { Parametro } from "../../controller/parametros";

let CidadePadrao = "";
let EstadoPadrao = "";
let DadosPadrao = [];

export function PageTaxaEntrega() {
  const [Taxa, setTaxa] = useState([]);
  const [loading, setLoading] = useState(false);

  const [isCadastro, setIsCadastro] = useState(false);
  const [Codigo, setCodigo] = useState(0);
  const [bairro, setBairro] = useState("");
  const [Cidade, setCidade] = useState("");
  const [Estado, setEstado] = useState("");
  const [Valor, setValor] = useState(0);
  const [TempoEntrega, setTempoEntrega] = useState(0);
  const [Ativo, setAtivo] = useState(1);

  useEffect(() => {
    BuscaDados();
    API.get(`/v2/parametros`).then((response) => {
      CidadePadrao = response.data[0].cidade;
      EstadoPadrao = response.data[0].estado;
    });
  }, []);

  function BuscaDados() {
    setIsCadastro(false);
    API.get("/v1/consulta/generica/taxa_entrega/*/*/cidade, bairro").then(
      function (data) {
        setTaxa(data.data);
        DadosPadrao = data.data;
        setLoading(false);
      }
    );
  }

  function Add() {
    setBairro("");
    setCodigo(0);
    setCidade(CidadePadrao);
    setEstado(EstadoPadrao);
    setValor(0);
    setAtivo(1);
    setTempoEntrega(Parametro("tempDelivery") ? Parametro("tempDelivery") : 0);
    setIsCadastro(true);
  }

  const columns = useMemo(
    () => [
      {
        Header: "Bairro",
        accessor: "bairro",
        size: "100px",
        format: PrimeiraMaiuscula,
      },
      {
        Header: "Tempo",
        accessor: "tempo",
        size: "50px",
        textAlign: "center",
        format: PrimeiraMaiuscula,
      },
      {
        Header: "Taxa",
        accessor: "valorTaxa",
        size: "50px",
        textAlign: "center",
        format: MascaraMonetaria,
      },
      {
        Header: "Cidade",
        accessor: "cidade",
        size: "100px",
        format: PrimeiraMaiuscula,
        textAlign: "center",
      },
      {
        Header: "Ativo",
        accessor: "ativo",
        size: "100px",
        format: SwitchPadraoTabela,
      },
      {
        Header: "Alterar",
        size: "50px",
        component: (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <IconButton color="primary">
              <FaPen />
            </IconButton>
          </div>
        ),
        func: AlterarDados,
      },
    ],
    []
  );

  function AlterarDados(dados) {
    const objetoEncontrado = DadosPadrao.find(
      (objeto) => objeto.codigo === dados
    );

    setBairro(objetoEncontrado.bairro);
    setCodigo(objetoEncontrado.codigo);
    setCidade(objetoEncontrado.cidade);
    setEstado(objetoEncontrado.estado);
    setValor(objetoEncontrado.valorTaxa);
    setAtivo(objetoEncontrado.ativo);
    setTempoEntrega(objetoEncontrado.tempo);
    setIsCadastro(true);
  }

  function SalvarDados() {
    if (bairro.length == 0) {
      NotificaWarning("Bairro não informado!");
      return false;
    }
    if (Cidade.length == 0) {
      NotificaWarning("Cidade não informada!");
      return false;
    }
    if (Estado.length == 0) {
      NotificaWarning("Estado não informado!");
      return false;
    }
    if (Estado.length > 2) {
      NotificaWarning("Sigla do estado incorreto!");
      return false;
    }

    const body = {
      tabela: "taxa_entrega",
      campo: "codigo",
      valor: Codigo,
      campos: [
        { campo: "cidade", valor: Cidade },
        { campo: "bairro", valor: bairro },
        { campo: "valor_taxa", valor: parseFloat(RemoveMascara(Valor), 0) },
        { campo: "ativo", valor: Ativo ? 1 : 0 },
        { campo: "estado", valor: Estado },
        {
          campo: "tempo",
          valor: TempoEntrega ? TempoEntrega : Parametro("tempDelivery"),
        },
        { campo: "modificado_site", valor: 0 },
      ],
    };
    setLoading(true);
    API.post("v2/cadastro/geral", body).then(function (response) {
      BuscaDados();
    });
  }

  return (
    <>
      <HeaderComponent>
        {loading && <LoadingOverlay />}{" "}
        <ComponentCadastro Titulo="Taxa de Entrega" Funcao={Add} />
        <Table columns={columns} data={Taxa} codigo={"codigo"} />
        {isCadastro ? (
          <>
            <ModalGravarDados
              Descricao={Codigo == 0 ? "Cadastro" : "Alteração"}
              closeButton={() => setIsCadastro(false)}
              Funcao={SalvarDados}
            >
              <InputPedido
                Descricao="Bairro"
                Set={setBairro}
                Valor={PrimeiraMaiuscula(bairro)}
                sx={{ width: "100%", marginBottom: "15px" }}
              />
              <InputPedido
                Descricao="Cidade"
                Set={setCidade}
                Valor={PrimeiraMaiuscula(Cidade)}
                sx={{ width: "100%", marginBottom: "15px" }}
              />
              <InputPedido
                Descricao="Estado (Sigla)"
                Set={setEstado}
                Valor={Estado.toUpperCase()}
                sx={{ width: "100%", marginBottom: "15px" }}
              />
              <InputPedido
                Descricao="Taxa de Entrega"
                Set={setValor}
                Valor={MascaraMonetaria(RemoveMascara(Valor))}
                sx={{ width: "100%", marginBottom: "15px" }}
              />
              <InputPedido
                Descricao={`Tempo Entrega (${Parametro("tempDelivery")}m)`}
                Set={setTempoEntrega}
                Valor={RemoveMascara(TempoEntrega)}
                sx={{ width: "100%", marginBottom: "15px" }}
              />

              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={Ativo}
                      onChange={(e) => setAtivo(!Ativo)}
                    />
                  }
                  label="Ativo"
                />
              </FormGroup>
            </ModalGravarDados>
          </>
        ) : null}
      </HeaderComponent>
    </>
  );
}
