import {
  FaCheck,
  FaFileImage,
  FaHotjar,
  FaMedapps,
  FaPaperclip,
  FaRegTrashAlt,
  FaWhatsapp,
} from "react-icons/fa";

import { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  styled,
  TextField,
} from "@mui/material";
import axios from "axios";
import { CardMarketin } from "../card";
import { API, GooPedirAPI } from "../../../controller/api/api";
import { Notifica } from "../../../controller/util";
import { InputPedido } from "../../pedido/componente/input";
import { BuscaLocal, GravaLocal } from "../../../controller/storage";
import { ComponentDiaSemana } from "../../produto/component/novoproduct/DiaSemana";

export function NovaCampanha({ UserID, Close, DadosCopia }) {
  const getCurrentDate = () => {
    const now = new Date();
    return now.toISOString().split("T")[0]; // Retorna a data no formato YYYY-MM-DD
  };

  const getCurrentTime = () => {
    const now = new Date();
    return now.toTimeString().split(" ")[0].substring(0, 5); // Retorna a hora no formato HH:MM
  };

  const [Nome, setNome] = useState("Campanha Whatsapp - " + formatDate());
  const [Publico, setPublico] = useState(1);
  const [Horas, setHoras] = useState(24);
  const [Mensagem, setMensagem] = useState("");
  const [Imagem, setImagem] = useState(null);
  const [base64Image, setBase64Image] = useState("");
  const [NumeroTest, setNumeroTest] = useState(BuscaLocal("NumTest"));
  const [file, setFile] = useState(null);

  const [Estimativa, setEstimativa] = useState(0);
  const [Clientes, setClientes] = useState([]);
  const [TipoEnvio, setTipoEnvio] = useState("Agora");
  const [DataAgendamento, setDataAgendamento] = useState(getCurrentDate());
  const [HoraAgendamento, setHoraAgendamento] = useState(getCurrentTime());
  const [DataConclusao, setDataConclusao] = useState(getCurrentDate());
  const [RepetirCampanha, setRepetirCampanha] = useState("Não");
  const [Segunda, setSegunda] = useState(0);
  const [Terca, setTerca] = useState(0);
  const [Quarta, setQuarta] = useState(0);
  const [Quinta, setQuinta] = useState(0);
  const [Sexta, setSexta] = useState(0);
  const [Sabado, setSabado] = useState(0);
  const [Domingo, setDomingo] = useState(0);

  useEffect(() => {
    if (UserID > 0) {
      EstimativaTempo();
    }
  }, [UserID]);

  useEffect(() => {
    if (DadosCopia) {
      // //console.log(DadosCopia);
      setNome("Cópia - " + DadosCopia.nome);
      setPublico(DadosCopia.publico);
      setHoras(DadosCopia.horas);
      setMensagem(DadosCopia.msg_mensagem);
      setImagem(DadosCopia.msg_imagem);
      setBase64Image(DadosCopia.msg_imagem);
    }
  }, []);

  const handleImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      setFile(file);
      setImagem(URL.createObjectURL(file));

      const reader = new FileReader();
      reader.onloadend = () => {
        setBase64Image(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  function formatDate() {
    const date = new Date();
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Os meses são de 0 a 11
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }

  async function SendMensagem(Numero) {
    // const formData = new FormData();
    // formData.append("file", Imagem);
    // formData.append("id", Numero);
    // formData.append("caption", Mensagem);
    // console.log(Imagem);

    const postData = {
      number: Numero,
      options: {
        delay: 1200,
        presence: "composing",
      },
      mediatype: "image",
      caption: Mensagem.replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&#039;"), // Aplicando uma conversão similar ao htmlspecialchars
      media: Imagem.replace(/^data:image\/[a-z]+;base64,/, ""),
    };
    try {
      const response = await axios.post(
        `https://evolution-v1.goopedir.com/message/sendMedia/${UserID}`,
        postData,
        {
          headers: {
            "Content-Type": "application/json",
            apikey: "GOOPEDIR@2024",
          },
        }
      );
    } catch (error) {
      // console.error("Erro ao enviar o arquivo:", error);
    }
  }

  function formatPhoneNumber(phoneNumber) {
    // Remove caracteres não numéricos
    const cleaned = phoneNumber.replace(/\D/g, "");

    // Remove o dígito "9" extra apenas se estiver na posição 2
    const withExtra9 = cleaned; // Inclui o "9" extra
    const withoutExtra9 = cleaned.replace(/^(\d{2})9/, "$1"); // Remove o "9" extra apenas se estiver após o DDD

    return ["55" + withExtra9, "55" + withoutExtra9];
  }

  async function TestEnvio() {
    GravaLocal("NumTest", NumeroTest);
    let Numeros = formatPhoneNumber(NumeroTest);

    SendMensagem(Numeros[1]);
  }

  function EstimativaTempo() {
    // //console.log(Publico);

    if (UserID > 0) {
      GooPedirAPI.get(`ws/v1/marketin.php?user=${UserID}&tipo=${Publico}`).then(
        function (response) {
          // //console.log(response.data);
          setEstimativa(response.data.alcance);
          setClientes(response.data.dados);
        }
      );
    }
  }

  function CardImagem({ imagem }) {
    return (
      <>
        <div style={{ display: "flex", width: "100%", gap: "10px" }}>
          <div
            style={{
              border: "1px solid #e0e0e0",
              width: "100%",
              padding: "5px",
              borderRadius: "10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <img
              src={imagem}
              alt="foto"
              style={{ width: "50px", height: "50px", borderRadius: "5px" }}
            />
            <IconButton aria-label="alterar" color="primary" component="label">
              <FaPaperclip />
              <VisuallyHiddenInput
                type="file"
                accept="image/*"
                onChange={handleImageChange}
              />
            </IconButton>
          </div>

          <div
            style={{
              border: "1px solid #e0e0e0",
              // width: "100%",
              padding: "5px",
              borderRadius: "10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <IconButton
              aria-label="clip"
              color="primary"
              onClick={() => [setImagem(null), setFile(null)]}
            >
              <FaRegTrashAlt />
            </IconButton>
          </div>
        </div>
      </>
    );
  }

  useEffect(() => {
    EstimativaTempo();
  }, [Publico]);

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  function SalvarCampanha() {
    let body = {
      user: parseInt(UserID),
      nome: Nome,
      publico: Publico,
      horas: Horas,
      imagem: base64Image,
      mensagem: Mensagem,
      envioData: TipoEnvio == "Agora " ? getCurrentDate() : DataAgendamento,
      envioHora: TipoEnvio == "Agora " ? getCurrentTime() : HoraAgendamento,
      estimativa: Estimativa,
      clientes: Clientes,
      seg: Segunda,
      ter: Terca,
      qua: Quarta,
      qui: Quinta,
      sex: Sexta,
      sab: Sabado,
      dom: Domingo,
      conclusao: DataConclusao,
    };

    GooPedirAPI.post("ws/v1/marketin-post.php", body).then(function (response) {
      //Consultar Dados
      Notifica("Campanha salva com sucesso!");
      Close();
    });
  }
  function formatMinutes(minutes) {
    // Calcula as horas e minutos
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = Math.floor(minutes % 60);

    // Adiciona zero à esquerda se necessário
    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(remainingMinutes).padStart(2, "0");

    // Retorna a string formatada
    return `${formattedHours}:${formattedMinutes}`;
  }

  return (
    <>
      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <FaWhatsapp size={24} />
        <h1 style={{ fontSize: "1.5rem" }}>
          <b>Criação de Campanha Whatsapp</b>
        </h1>
      </div>
      <CardMarketin nome="Informação Geral">
        <InputPedido
          Descricao="Nome Campanha"
          Set={setNome}
          Valor={Nome}
          sx={{ width: "100%" }}
        />

        <FormControl sx={{ width: "100%" }}>
          <InputLabel id="publico">Público Alvo</InputLabel>
          <Select
            id="publico"
            value={Publico}
            label="Público Alvo"
            onChange={(e) => setPublico(e.target.value)}
            size="small"
          >
            <MenuItem value={1}>Todos Clientes</MenuItem>
            <MenuItem value={2}>Clientes Com Alta Frequência</MenuItem>
            <MenuItem value={3}>Clientes Com Baixa Frequência</MenuItem>
            {/* <MenuItem value={4}>Aniversariantes</MenuItem> */}
          </Select>
        </FormControl>
        <span
          style={{
            fontSize: "0.85rem",
            color: "rgba(0,0,0,0.7)",
            marginTop: "-5px",
          }}
        >
          Estimativa de alcance de <b>{Estimativa}</b> clientes com whatsapp.
          <br />
          Tempo previsto de envio da campanha{" "}
          <b>{formatMinutes(Estimativa * 0.1)}</b>h.
        </span>

        <InputPedido
          Descricao="Horas para conversão"
          Set={setHoras}
          Valor={Horas}
          sx={{ width: "100%" }}
        />
        <span
          style={{
            fontSize: "0.85rem",
            color: "rgba(0,0,0,0.7)",
            marginTop: "-5px",
          }}
        >
          Define a quantidade maáxima de horas para a conversão de clientes para
          a campanha, para que seja contabilizada nos relátorios.
        </span>
      </CardMarketin>
      <CardMarketin nome="Mensagem">
        <div
          style={{
            backgroundColor: "rgba(0,0,0,0.08)",
            borderRadius: "5px",
            padding: "10px",
            fontSize: "0.85rem",
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <FaMedapps size={60} />
          <span>
            No local onde você deseja que apareça o nome do destinatário,
            escreva <b>[NOME]</b>. Quando a mensagem for enviada, esse trecho
            será subistituido pelo nome do destinátario.
          </span>
        </div>
        <TextField
          label="Mensagem"
          value={Mensagem}
          onChange={(e) => setMensagem(e.target.value)}
          multiline
          rows={8}
          variant="outlined"
          fullWidth
        />
        <div style={{ padding: "5px", fontSize: "0.85rem", marginTop: "-5px" }}>
          <p>
            Caracteres: <b>{Mensagem.length}</b>
          </p>
          <p>
            Recomendado máximo de 500 caracteres para evitar bloqueio do
            Whatsapp.
          </p>
        </div>
        <p>
          <b>Imagem</b>
        </p>

        {Imagem ? (
          <CardImagem imagem={Imagem} />
        ) : (
          <>
            <Button
              variant="outlined"
              component="label"
              role={undefined}
              tabIndex={-1}
              startIcon={<FaFileImage />}
            >
              Imagem
              <VisuallyHiddenInput
                type="file"
                accept="image/*"
                onChange={handleImageChange}
              />
            </Button>

            <div
              style={{
                padding: "5px",
                fontSize: "0.85rem",
                marginTop: "-10px",
              }}
            >
              <p>
                <i>Dimensão mínima recomendada: 1080 x 1080</i>
              </p>
            </div>
          </>
        )}
        <InputPedido
          Descricao="Nº para receber o teste"
          Set={setNumeroTest}
          Valor={NumeroTest}
          // sx={{ width: "100%" }}
          Tipo="celular"
        />
        <Button
          variant="outlined"
          component="label"
          role={undefined}
          tabIndex={-1}
          startIcon={<FaWhatsapp />}
          onClick={TestEnvio}
        >
          Enviar Teste
        </Button>
      </CardMarketin>
      <CardMarketin nome="Quando enviar">
        <div
          style={{
            padding: "5px",
            fontSize: "0.85rem",
            marginTop: "-10px",
          }}
        >
          <p>Defina quando enviar sua campanha personalizada</p>
        </div>

        <div>
          {" "}
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            defaultValue="female"
            name="radio-buttons-group"
            value={TipoEnvio}
            onChange={(e) => setTipoEnvio(e.target.value)}
          >
            <FormControlLabel
              value="Agora"
              control={<Radio />}
              label="Enviar agora"
            />
            <FormControlLabel
              value="Agendar"
              control={<Radio />}
              label="Agendar envio"
            />
          </RadioGroup>
        </div>
        {TipoEnvio == "Agendar" ? (
          <>
            <div style={{ display: "flex", gap: "10px", padding: "10px" }}>
              <TextField
                label="Data de envio"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                value={DataAgendamento}
                onChange={(e) => setDataAgendamento(e.target.value)}
                fullWidth
              />
              <TextField
                label="Hora de envio"
                type="time"
                InputLabelProps={{
                  shrink: true,
                }}
                value={HoraAgendamento}
                onChange={(e) => setHoraAgendamento(e.target.value)}
                fullWidth
              />
            </div>
          </>
        ) : null}
      </CardMarketin>
      <CardMarketin nome="Repetir Campanha">
        <div
          style={{
            padding: "5px",
            fontSize: "0.85rem",
            marginTop: "-10px",
          }}
        >
          <p>Defina quais dias da semana repetirão, e quando ela terminara</p>
        </div>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="female"
          name="radio-buttons-group1"
          value={RepetirCampanha}
          onChange={(e) => setRepetirCampanha(e.target.value)}
        >
          <FormControlLabel
            value="Não"
            control={<Radio />}
            label="Não Repetir"
          />
          <FormControlLabel value="Sim" control={<Radio />} label="Repetir" />
        </RadioGroup>

        {RepetirCampanha == "Sim" ? (
          <>
            <TextField
              label="Data de conclusão"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={DataConclusao}
              onChange={(e) => setDataConclusao(e.target.value)}
              fullWidth
            />
            <h3
              style={{
                padding: "5px",
                fontSize: "0.85rem",
              }}
            >
              Repetir em dias específico
            </h3>
            <div
              style={{
                display: "flex",
                gap: "5px",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "10px",
              }}
            >
              <ComponentDiaSemana
                Texto="S"
                Selected={Segunda}
                OnClick={() => setSegunda(!Segunda)}
              />
              <ComponentDiaSemana
                Texto="T"
                Selected={Terca}
                OnClick={() => setTerca(!Terca)}
              />
              <ComponentDiaSemana
                Texto="Q"
                Selected={Quarta}
                OnClick={() => setQuarta(!Quarta)}
              />
              <ComponentDiaSemana
                Texto="Q"
                Selected={Quinta}
                OnClick={() => setQuinta(!Quinta)}
              />
              <ComponentDiaSemana
                Texto="S"
                Selected={Sexta}
                OnClick={() => setSexta(!Sexta)}
              />
              <ComponentDiaSemana
                Texto="S"
                Selected={Sabado}
                OnClick={() => setSabado(!Sabado)}
              />
              <ComponentDiaSemana
                Texto="D"
                Selected={Domingo}
                OnClick={() => setDomingo(!Domingo)}
              />
            </div>
          </>
        ) : null}
      </CardMarketin>
      <CardMarketin nome="Conluir Campanha">
        <Button
          variant="outlined"
          component="label"
          role={undefined}
          tabIndex={-1}
          startIcon={<FaCheck />}
          onClick={SalvarCampanha}
        >
          Salvar campanha
        </Button>
      </CardMarketin>
    </>
  );
}
