import { CardFatura } from "../cardFatura";
import { CardProjecao } from "../cardProjecao";

export function DesktopPage() {
  return (
    <>
      <CardFatura />
      {/* <CardProjecao /> */}
    </>
  );
}
