// Função para obter o valor do campo 'url' dentro do 'base-url'
function GetLabel() {
  const baseUrl = JSON.parse(localStorage.getItem("base-url"));
  return baseUrl ? baseUrl.url : null;
}

// Grava um item no array associado ao 'url' (exceto para 'base-url')
export function GravaLocal(Categoria, Valor) {
  if (Categoria === "base-url") {
    localStorage.setItem(Categoria, Valor);
    console.log(GetLabel());
  } else {
    // Pega o array associado ao 'url' (pai)
    const label = GetLabel();
    if (label) {
      const array = JSON.parse(localStorage.getItem(label)) || [];
      const itemIndex = array.findIndex((item) => item.Categoria === Categoria);

      // Se o item já existe, atualiza; senão, adiciona
      if (itemIndex !== -1) {
        array[itemIndex].Valor = Valor;
      } else {
        array.push({ Categoria, Valor });
      }

      // Salva o array atualizado no localStorage
      localStorage.setItem(label, JSON.stringify(array));
    }
  }

  return Valor;
}

// Apaga um item do array associado ao 'url'
export function ApagaLocal(Categoria) {
  if (Categoria === "base-url") {
    localStorage.removeItem(Categoria);
  } else {
    const label = GetLabel();
    if (label) {
      const array = JSON.parse(localStorage.getItem(label)) || [];
      const filteredArray = array.filter(
        (item) => item.Categoria !== Categoria
      );

      // Salva o array atualizado sem o item removido
      localStorage.setItem(label, JSON.stringify(filteredArray));
    }
  }
}

// Busca um item no array associado ao 'url'
export function BuscaLocal(Categoria) {
  if (Categoria === "base-url") {
    return localStorage.getItem(Categoria);
  } else if (Categoria === "HOST") {
    return localStorage.getItem(Categoria);
  } else {
    const label = GetLabel();

    if (label) {
      const array = JSON.parse(localStorage.getItem(label)) || [];
      const item = array.find((item) => item.Categoria === Categoria);

      if (item && item.Valor !== "undefined") {
        return item.Valor;
      }
    }
  }
  return null;
}

// Grava um item JSON no array associado ao 'url'
export function GravaLocalJson(Categoria, Valor) {
  return GravaLocal(Categoria, JSON.stringify(Valor));
}

// Busca um item JSON no array associado ao 'url'
export function BuscaLocalJson(Categoria) {
  const valor = BuscaLocal(Categoria);
  return valor ? JSON.parse(valor) : null;
}
