import axios from "axios";
import { HeaderComponent } from "../../component/header";
import { API } from "../../controller/api/api";
import { InputPedido } from "../pedido/componente/input";
import { useState, useEffect } from "react";
import {
  MascaraMonetaria,
  RemoveMascara,
  formatarHora,
} from "../../controller/data";
import { InputParametro } from "./componente/input/input";
import {
  Notifica,
  NotificaWarning,
  PrimeiraMaiuscula,
} from "../../controller/util";
import { Selecao } from "./componente/selecao";
import { SelectControl } from "./componente/select";
import { GarcomComponent } from "../../component/garcom";
import { Button } from "../../component/button";
import { TextField } from "@mui/material";
import { MensagemImagemMarketing } from "./componente/mensagem";
import { PermissaoModulo } from "../../controller/modulos";

var msg = "";

export function PageParameters() {
  const Modulos = [
    { index: 0, name: "Empresa", visible: true },
    { index: 1, name: "Funcionamento", visible: false },
    { index: 2, name: "Whatsapp", visible: PermissaoModulo("whatsapp") },
    { index: 3, name: "Caixa", visible: PermissaoModulo("caixa") },
    { index: 4, name: "Pix", visible: PermissaoModulo("pix") },
    { index: 5, name: "Produto", visible: true },
    { index: 6, name: "Geral", visible: true },
    { index: 7, name: "Pedido", visible: true },
    { index: 8, name: "Site", visible: true },
    { index: 9, name: "iFood", visible: PermissaoModulo("ifood") },
    { index: 10, name: "Marketing", visible: false },
    { index: 11, name: "Impressão", visible: true },
    { index: 12, name: "Garçom", visible: PermissaoModulo("mesas") },
  ];

  const [DadosParametro, setDadosParametro] = useState({});
  const [Segunda, setSegunda] = useState("");
  const [Terca, setTerca] = useState("");
  const [Quarta, setQuarta] = useState("");
  const [Quinta, setQuinta] = useState("");
  const [Sexta, setSexta] = useState("");
  const [Sabado, setSabado] = useState("");
  const [Domingo, setDomingo] = useState("");
  const [ImagemSegunda, setImagemSegunda] = useState("");
  const [ImagemTerca, setImagemTerca] = useState("");
  const [ImagemQuarta, setImagemQuarta] = useState("");
  const [ImagemQuinta, setImagemQuinta] = useState("");
  const [ImagemSexta, setImagemSexta] = useState("");
  const [ImagemSabado, setImagemSabado] = useState("");
  const [ImagemDomingo, setImagemDomingo] = useState("");

  const [text, setText] = useState(msg);
  const [selectedModulo, setSelectedModulo] = useState(0);
  const [CertificadoDigital, setCertificadoDigital] = useState([]);
  const [GruposLolcalizacao, setGrupos] = useState([]);
  const [UrliFood, setUrliFood] = useState("");
  const [CodigoAutenticacaoiFood, setCodigoAutenticacaoiFood] = useState("");

  function AtualizaParametro(campo, valor) {
    let Body = { campo: campo, valor: valor };
    API.post(`/v2/parametro`, Body);
  }

  function Atualiza(name, value) {
    if (name == "CNPJ") {
      if (value.length == 14) {
        API.get(`/v2/cnpj/${value}`).then((response) => {
          const DadosCNPJ = DadosParametro;
          DadosCNPJ.razao = PrimeiraMaiuscula(response.data.nome);
          AtualizaParametro("razao", DadosCNPJ.razao);
          DadosCNPJ.nome = PrimeiraMaiuscula(response.data.fantasia);
          AtualizaParametro("nome", DadosCNPJ.nome);
          DadosCNPJ.rua = PrimeiraMaiuscula(response.data.logradouro);
          AtualizaParametro("rua", DadosCNPJ.rua);
          DadosCNPJ.numero = response.data.numero;
          AtualizaParametro("numero", DadosCNPJ.numero);
          DadosCNPJ.cep = RemoveMascara(response.data.cep);
          AtualizaParametro("cep", DadosCNPJ.cep);
          DadosCNPJ.bairro = PrimeiraMaiuscula(response.data.bairro);
          AtualizaParametro("bairro", DadosCNPJ.bairro);
          DadosCNPJ.cidade = PrimeiraMaiuscula(response.data.municipio);
          AtualizaParametro("cidade", DadosCNPJ.cidade);
          DadosCNPJ.estado = PrimeiraMaiuscula(response.data.uf);
          AtualizaParametro("estado", DadosCNPJ.estado);
          setDadosParametro(DadosCNPJ);
        });
      }
    }
  }

  useEffect(() => {
    API.get(`v2/parametros`)
      .then((response) => {
        msg = response.data[0].mensagemInicio;
        setText(response.data[0].mensagemInicio);
        ////console.log(DadosParametro?.cnpj);
        setDadosParametro(response.data[0]);
      })
      .catch((err) => {});
    API.get(`/v2/dados/certificados`)
      .then((response) => {
        setCertificadoDigital(response.data);
      })
      .catch((err) => {});

    API.get(`/v2/whatsapp/group`)
      .then((response) => {
        setGrupos(response.data);
      })
      .catch((err) => {});

    API.get(`v1/consulta/generica/mensagem/*/dia='segunda'/*`).then(
      (response) => {
        setSegunda(response.data[0]?.texto);
        setImagemSegunda(response.data[0]?.imagem);
      }
    );

    API.get(`v1/consulta/generica/mensagem/*/dia='terca'/*`).then(
      (response) => {
        setTerca(response.data[0]?.texto);
        setImagemTerca(response.data[0]?.imagem);
      }
    );

    API.get(`v1/consulta/generica/mensagem/*/dia='quarta'/*`).then(
      (response) => {
        setQuarta(response.data[0]?.texto);
        setImagemQuarta(response.data[0]?.imagem);
      }
    );

    API.get(`v1/consulta/generica/mensagem/*/dia='quinta'/*`).then(
      (response) => {
        setQuinta(response.data[0]?.texto);
        setImagemQuinta(response.data[0]?.imagem);
      }
    );

    API.get(`v1/consulta/generica/mensagem/*/dia='sexta'/*`).then(
      (response) => {
        setSexta(response.data[0]?.texto);
        setImagemSexta(response.data[0]?.imagem);
      }
    );

    API.get(`v1/consulta/generica/mensagem/*/dia='sabado'/*`).then(
      (response) => {
        setSabado(response.data[0]?.texto);
        setImagemSabado(response.data[0]?.imagem);
      }
    );

    API.get(`v1/consulta/generica/mensagem/*/dia='domingo'/*`).then(
      (response) => {
        setDomingo(response.data[0]?.texto);
        setImagemDomingo(response.data[0]?.imagem);
      }
    );

    // http://localhost:9000/
    return () => {
      // Esta função de limpeza será executada quando o componente for desmontado

      API.post(`v2/sincroniza/parametros`);

      // Coloque aqui o código que você deseja executar quando o usuário sair da tela
      // Por exemplo, você pode fazer uma chamada API para salvar dados ou liberar recursos
      // AtualizaParametro("mensagem_inicio", text);
    };
  }, []);

  function AtualizaMensagem(Dia, Mensagem, Imagem) {
    const body = { dia: Dia, mensagem: Mensagem, imagem: Imagem };
    API.post("/v2/grava/mensagem", body);
  }

  const handleChange = (event) => {
    msg = event.target.value;
    setText(event.target.value);
    AtualizaParametro("mensagem_inicio", msg);
  };

  function GetUrliFood() {
    API.get("v1/util/ifood/autenticacao").then((response) => {
      setUrliFood(response.data);
    });
  }

  function ExecutarImportacao() {
    let body = { url: UrliFood };

    API.post("v2/importacao/topedindo", body).then((response) => {
      setUrliFood("");
      Notifica("Importação Em Progresso!");
    });
  }

  function AutenticarAPlicacao() {
    API.post(`v1/util/ifood/autenticacao/${CodigoAutenticacaoiFood}`);
  }

  const remainingCharacters = 2000 - text.length;

  return (
    <>
      <HeaderComponent>
        {DadosParametro?.nome != undefined ? (
          <>
            <ul
              style={{
                listStyleType: "none",
                padding: 0,
                display: "flex",
                overflowX: "auto",
                whiteSpace: "nowrap",
                gap: "10px", // Adiciona espaçamento entre os itens
                marginBottom: "15px",
              }}
            >
              {Modulos.filter((modulo) => modulo.visible).map(
                (modulo, index) => (
                  <li
                    key={modulo.index}
                    onClick={() => setSelectedModulo(modulo.index)}
                    style={{
                      backgroundColor:
                        selectedModulo === modulo.index
                          ? "rgb(168, 0, 28)"
                          : "#f0f0f0", // Cor de fundo diferente para o item selecionado
                      color: selectedModulo === modulo.index ? "white" : "#333", // Cor de texto contrastante
                      padding: "12px 20px", // Mais padding para tornar o item mais espaçoso
                      cursor: "pointer",
                      borderRadius: "8px", // Bordas arredondadas para um visual moderno
                      boxShadow:
                        selectedModulo === modulo.index
                          ? "0 4px 8px rgba(0, 0, 0, 0.2)" // Sombra para destacar o item selecionado
                          : "0 2px 4px rgba(0, 0, 0, 0.1)", // Sombra leve para itens não selecionados
                      transition: "all 0.3s ease", // Transição suave para mudanças de estilo
                      userSelect: "none", // Evita que o texto seja selecionado ao clicar
                    }}
                  >
                    {modulo.name}
                  </li>
                )
              )}
            </ul>

            {selectedModulo == 0 ? (
              <>
                <div
                  style={{
                    border: "1px solid #e0e0e0",
                    borderRadius: "8px",
                    padding: "20px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    backgroundColor: "#ffffff",
                    maxWidth: "600px",
                    margin: "0 auto",
                  }}
                >
                  <h1
                    style={{
                      color: "#A8001C",
                      marginBottom: "20px",
                      fontSize: "24px",
                    }}
                  >
                    <strong>Empresa</strong>
                  </h1>

                  <SelectControl
                    Descricao="Tipo Empresa"
                    Campo="tipo_empresa"
                    Valores={[
                      { value: 1, descricao: "Simples Nacional" },
                      {
                        value: 2,
                        descricao:
                          "Simples Nacional, excesso sublimite de receita bruta",
                      },
                      { value: 3, descricao: "Regime Normal" },
                    ]}
                    Selecionado={DadosParametro?.tipoEmpresa}
                  />

                  <div style={{ display: "flex", gap: "10px" }}>
                    <InputParametro
                      Descricao="CNPJ"
                      Retorno={Atualiza}
                      campo="cnpj"
                      Valor={DadosParametro?.cnpj}
                    />
                    <InputParametro
                      Descricao="Inscrição Estadual"
                      Retorno={Atualiza}
                      campo="ie"
                      Valor={DadosParametro?.ie}
                    />
                    <InputParametro
                      Descricao="Telefone"
                      Retorno={Atualiza}
                      campo="fone"
                      Valor={DadosParametro?.celular}
                    />
                  </div>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <InputParametro
                      Descricao="Razão Social"
                      Retorno={Atualiza}
                      campo="razao"
                      Valor={DadosParametro?.razao}
                    />
                    <InputParametro
                      Descricao="Fantasia"
                      Retorno={Atualiza}
                      campo="nome"
                      Valor={DadosParametro?.nome}
                    />
                  </div>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <InputParametro
                      Descricao="CEP"
                      Retorno={Atualiza}
                      campo="cep"
                      Valor={DadosParametro?.cep}
                      sx={{ width: "285px" }}
                    />
                    <InputParametro
                      Descricao="Logradouro (Endereço)"
                      campo="rua"
                      Retorno={Atualiza}
                      Valor={DadosParametro?.rua}
                    />
                    <InputParametro
                      Descricao="Número"
                      Retorno={Atualiza}
                      campo="numero"
                      sx={{ width: "185px" }}
                      Valor={DadosParametro?.numero}
                    />
                  </div>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <InputParametro
                      Descricao="Complemento"
                      Retorno={Atualiza}
                      campo="complemento"
                      Valor={DadosParametro?.complemento}
                    />
                    <InputParametro
                      Descricao="Bairro"
                      Retorno={Atualiza}
                      campo="bairro"
                      Valor={DadosParametro?.bairro}
                    />
                  </div>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <InputParametro
                      Descricao="Código Cidade"
                      Retorno={Atualiza}
                      campo="codcidade"
                      Valor={DadosParametro?.codcidade}
                    />
                    <InputParametro
                      Descricao="Cidade"
                      Retorno={Atualiza}
                      campo="cidade"
                      Valor={DadosParametro?.cidade}
                    />
                    <InputParametro
                      Descricao="Estado"
                      Retorno={Atualiza}
                      campo="estado"
                      Valor={DadosParametro?.estado}
                    />
                  </div>

                  <div style={{ display: "flex", gap: "10px" }}>
                    <SelectControl
                      Descricao="Forma Emissão NFC-e"
                      Campo="forma_emissao"
                      Valores={[
                        { value: 0, descricao: "Normal" },
                        { value: 1, descricao: "Contingência" },
                      ]}
                      Selecionado={DadosParametro?.formaEmissao}
                    />
                    <SelectControl
                      Descricao="Ambiente NFC-e"
                      Campo="ambiente"
                      Valores={[
                        { value: 0, descricao: "Produção" },
                        { value: 1, descricao: "Homologação" },
                      ]}
                      Selecionado={DadosParametro?.ambiente}
                    />
                  </div>

                  <SelectControl
                    Descricao="Certificado Digital"
                    Campo="certificado"
                    Valores={CertificadoDigital}
                    Selecionado={DadosParametro?.certificado}
                  />

                  <div style={{ display: "flex", gap: "10px" }}>
                    <InputParametro
                      Descricao="IdToken/IdCSC"
                      Retorno={Atualiza}
                      campo="id_token_scs"
                      Valor={DadosParametro?.idTokenScs}
                    />
                    <InputParametro
                      Descricao="Token/SCS "
                      Retorno={Atualiza}
                      campo="token_scs"
                      Valor={DadosParametro?.tokenScs}
                    />
                  </div>
                </div>
              </>
            ) : null}
            {selectedModulo == 1 ? (
              <>
                <div
                  style={{
                    border: "1px solid #e0e0e0",
                    borderRadius: "8px",
                    padding: "20px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    backgroundColor: "#ffffff",
                    maxWidth: "600px",
                    margin: "0 auto",
                  }}
                >
                  <h1
                    style={{
                      color: "#A8001C",
                      marginBottom: "20px",
                      fontSize: "24px",
                    }}
                  >
                    <strong>Funcionamento</strong>
                  </h1>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <Selecao
                      Descricao="Segunda-Feira"
                      Campo="segunda"
                      Valor={DadosParametro?.segunda == 1}
                    />
                    <Selecao
                      Descricao="Terça-Feira"
                      Campo="terca"
                      Valor={DadosParametro?.terca == 1}
                    />
                    <Selecao
                      Descricao="Quarta-Feira"
                      Campo="quarta"
                      Valor={DadosParametro?.quarta == 1}
                    />
                    <Selecao
                      Descricao="Quinta-Feira"
                      Campo="quinta"
                      Valor={DadosParametro?.quinta == 1}
                    />
                    <Selecao
                      Descricao="Sexta-Feira"
                      Campo="sexta"
                      Valor={DadosParametro?.sexta == 1}
                    />
                    <Selecao
                      Descricao="Sabado"
                      Campo="sabado"
                      Valor={DadosParametro?.sabado == 1}
                    />
                    <Selecao
                      Descricao="Domingo"
                      Campo="domingo"
                      Valor={DadosParametro?.domingo == 1}
                    />
                  </div>

                  <InputParametro
                    Descricao="Abertura"
                    Retorno={Atualiza}
                    campo="horario_abertura"
                    Valor={formatarHora(DadosParametro?.horarioAbertura)}
                  />
                  <InputParametro
                    Descricao="Fechamento"
                    Retorno={Atualiza}
                    campo="horario_fechamento"
                    Valor={formatarHora(DadosParametro?.horarioFechamento)}
                  />
                </div>
              </>
            ) : null}
            {selectedModulo == 2 ? (
              <>
                <div
                  style={{
                    border: "1px solid #e0e0e0",
                    borderRadius: "8px",
                    padding: "20px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    backgroundColor: "#ffffff",
                    maxWidth: "600px",
                    margin: "0 auto",
                  }}
                >
                  <h1
                    style={{
                      color: "#A8001C",
                      marginBottom: "20px",
                      fontSize: "24px",
                    }}
                  >
                    <strong>Whatsapp</strong>
                  </h1>
                  <div style={{ display: "flex", gap: "10px" }}>
                    {/* <Selecao
                      Descricao="Enviar Confirmação De Recebimento do Pedido"
                      Campo="tipo_wpp_confirmacao"
                      Valor={DadosParametro?.tipoWppConfirmacao == 1}
                    />
                    <Selecao
                      Descricao="Enviar Alteração de Status Do Pedido"
                      Campo="tipo_wpp_status"
                      Valor={DadosParametro?.tipoWppStatus == 1}
                    />
                    <Selecao
                      Descricao="Pedir Comprovante Do Pix"
                      Campo="tipo_wpp_pix"
                      Valor={DadosParametro?.tipoWppPix == 1}
                    />
                    <Selecao
                      Descricao="Enviar Confirmação do Envio"
                      Campo="tipo_wpp_motoboy"
                      Valor={DadosParametro?.tipoWppMotoboy == 1}
                    />
                    <Selecao
                      Descricao="Auto Atendimento"
                      Campo="tipo_wpp_auto_bot"
                      Valor={DadosParametro?.tipoWppAutoBot == 1}
                    />
                    <Selecao
                      Descricao="Serviço Whatsapp"
                      Campo="a_whatsapp"
                      Valor={DadosParametro?.aWhatsapp == 1}
                    />
                    <Selecao
                      Descricao="Envio em Massa"
                      Campo="msg_massa"
                      Valor={DadosParametro?.msgMassa == 1}
                    /> */}
                  </div>
                  <div style={{ padding: "10px" }}>
                    <div>Mensagem</div>
                    <textarea
                      value={text}
                      onChange={handleChange}
                      maxLength={2000}
                      style={{
                        width: "100%",
                        height: "400px",
                        border: "1px solid",
                      }}
                    />
                    <div>Carácteres restantes {remainingCharacters}</div>
                  </div>
                  <SelectControl
                    Descricao="Grupo Localização"
                    Campo="localizacao"
                    Valores={GruposLolcalizacao}
                    Selecionado={DadosParametro?.localizacao}
                  />
                </div>
              </>
            ) : null}
            {selectedModulo == 3 ? (
              <>
                <div
                  style={{
                    border: "1px solid #e0e0e0",
                    borderRadius: "8px",
                    padding: "20px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    backgroundColor: "#ffffff",
                    maxWidth: "600px",
                    margin: "0 auto",
                  }}
                >
                  <h1
                    style={{
                      color: "#A8001C",
                      marginBottom: "20px",
                      fontSize: "24px",
                    }}
                  >
                    <strong>Caixa</strong>
                  </h1>
                  <Selecao
                    Descricao="Abrir / Fechar Caixa Automatico"
                    Campo="auto_abrir_fechar_caixa"
                    Valor={DadosParametro?.autoAbrirFecharCaixa == 1}
                  />
                  <InputParametro
                    Descricao="Valor Padrão Abertura Caixa"
                    Retorno={Atualiza}
                    campo="valor_padrao_caixa"
                    Valor={DadosParametro?.valorPadraoCaixa}
                  />
                  <InputParametro
                    Descricao="Taxa Serviço (%)"
                    Retorno={Atualiza}
                    campo="taxa_servico_pedido"
                    Valor={DadosParametro?.taxaServicoPedido}
                  />
                  <Selecao
                    Descricao="Faturar Todos Pedidos Ao Fechar o Caixa"
                    Campo="fat_all_caixa"
                    Valor={DadosParametro?.fatAllCaixa == 1}
                  />

                  <div style={{ display: "flex", gap: "10px" }}>
                    <Selecao
                      Descricao="Impressão Resumo"
                      Campo="imp_caixa_resumo"
                      Valor={DadosParametro?.impCaixaResumo == 1}
                    />
                    <Selecao
                      Descricao="Impressão Completo"
                      Campo="imp_caixa_completo"
                      Valor={DadosParametro?.impCaixaCompleto == 1}
                    />
                    <Selecao
                      Descricao="Impressão Produto"
                      Campo="imp_caixa_produto"
                      Valor={DadosParametro?.impCaixaProduto == 1}
                    />
                    <Selecao
                      Descricao="Impressão Motoboy"
                      Campo="imp_caixa_motoboy"
                      Valor={DadosParametro?.impCaixaMotoboy == 1}
                    />
                  </div>
                  <Selecao
                    Descricao="Fechamento As Cegas (Caixa)"
                    Campo="cx_resumido"
                    Valor={DadosParametro?.cxResumido == 1}
                  />
                </div>
              </>
            ) : null}
            {selectedModulo == 4 ? (
              <>
                <div
                  style={{
                    border: "1px solid #e0e0e0",
                    borderRadius: "8px",
                    padding: "20px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    backgroundColor: "#ffffff",
                    maxWidth: "600px",
                    margin: "0 auto",
                  }}
                >
                  <h1
                    style={{
                      color: "#A8001C",
                      marginBottom: "20px",
                      fontSize: "24px",
                    }}
                  >
                    <strong>PIX</strong>
                  </h1>
                  <Selecao
                    Descricao="Utilizar Integração PIX (Mercado Pago)"
                    Campo="fat_integra_pix"
                    Valor={DadosParametro?.fatIntegraPix == 1}
                  />
                  <InputParametro
                    Descricao="Token (Mercado Pago)"
                    Retorno={Atualiza}
                    campo="token_mp"
                    Valor={DadosParametro?.tokenMp}
                  />
                </div>
              </>
            ) : null}
            {selectedModulo == 5 ? (
              <>
                <div
                  style={{
                    border: "1px solid #e0e0e0",
                    borderRadius: "8px",
                    padding: "20px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    backgroundColor: "#ffffff",
                    maxWidth: "600px",
                    margin: "0 auto",
                  }}
                >
                  <h1
                    style={{
                      color: "#A8001C",
                      marginBottom: "20px",
                      fontSize: "24px",
                    }}
                  >
                    <strong>Produto</strong>
                  </h1>
                  <Selecao
                    Descricao="Controle de Estoque (Produto / Insulmos)"
                    Campo="controle_estoque"
                    Valor={DadosParametro?.controleEstoque == 1}
                  />
                  <Selecao
                    Descricao="Ficha Técnica do Produto"
                    Campo="ficha_tecnica"
                    Valor={DadosParametro?.fichaTecnica == 1}
                  />
                  <InputParametro
                    Descricao="Celular Gerente"
                    Retorno={Atualiza}
                    campo="estoque_wpp_celular"
                    Valor={DadosParametro?.estoqueWppCelular}
                  />
                  <InputParametro
                    Descricao="Estoque Mínimo Recomendado (Padrão)"
                    Retorno={Atualiza}
                    campo="estoque_min_recomendado"
                    Valor={DadosParametro?.estoqueMinRecomendado}
                  />
                </div>
              </>
            ) : null}
            {selectedModulo == 6 ? (
              <>
                <div
                  style={{
                    border: "1px solid #e0e0e0",
                    borderRadius: "8px",
                    padding: "20px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    backgroundColor: "#ffffff",
                    maxWidth: "600px",
                    margin: "0 auto",
                  }}
                >
                  <h1
                    style={{
                      color: "#A8001C",
                      marginBottom: "20px",
                      fontSize: "24px",
                    }}
                  >
                    <strong>Geral</strong>
                  </h1>
                  <Selecao
                    Descricao="Utilizar Comanda"
                    Campo="comanda"
                    Valor={DadosParametro?.comanda == 1}
                  />
                  <Selecao
                    Descricao="Modo de Homologação"
                    Campo="homologacao"
                    Valor={DadosParametro?.homologacao == 1}
                  />
                  <Selecao
                    Descricao="NFC-e"
                    Campo="nfce"
                    Valor={DadosParametro?.nfce == 1}
                  />
                  {/* <Selecao
                    Descricao="Módulo Contabil"
                    Campo="contabilidade"
                    Valor={DadosParametro?.contabilidade == 1}
                  />
                  <InputParametro
                    Descricao="Email Contabilidade"
                    Retorno={Atualiza}
                    campo="emailcontabilidade"
                    Valor={DadosParametro?.emailcontabilidade}
                  />
                  <InputParametro
                    Descricao="Caminho Cache Produto"
                    Retorno={Atualiza}
                    campo="caminho_cache"
                    Valor={DadosParametro?.caminhoCache}
                  /> */}
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      marginBottom: "15px",
                    }}
                  >
                    <InputPedido
                      Descricao="Url Importação ToPedindo"
                      sx={{ width: "80%" }}
                      Valor={UrliFood}
                      Set={setUrliFood}
                    />
                    <Button
                      descricao="Executar"
                      sx={{ width: "20%" }}
                      evento={ExecutarImportacao}
                    />
                  </div>

                  {/* <InputParametro
                    Descricao="Caminho Purge"
                    Retorno={Atualiza}
                    campo="caminho_purge"
                    Valor={DadosParametro?.caminhopurge}
                  /> */}
                </div>
              </>
            ) : null}
            {selectedModulo == 7 ? (
              <>
                <div
                  style={{
                    border: "1px solid #e0e0e0",
                    borderRadius: "8px",
                    padding: "20px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    backgroundColor: "#ffffff",
                    maxWidth: "600px",
                    margin: "0 auto",
                  }}
                >
                  <h1
                    style={{
                      color: "#A8001C",
                      marginBottom: "20px",
                      fontSize: "24px",
                    }}
                  >
                    <strong>Pedido</strong>
                  </h1>
                  <InputParametro
                    Descricao="DDD padrão para os pedidos"
                    Retorno={Atualiza}
                    campo="ddd_padrao"
                    Valor={DadosParametro?.dddpadrao}
                  />

                  <SelectControl
                    Descricao="Tipo Valor Pizza"
                    Campo="tipo_preco_pizza"
                    Valores={[
                      { value: 0, descricao: "Média" },
                      { value: 1, descricao: "Maior" },
                      { value: 2, descricao: "Soma Dos Valores" },
                    ]}
                    Selecionado={DadosParametro?.tipoPrecoPizza}
                  />
                </div>
              </>
            ) : null}
            {selectedModulo == 8 ? (
              <>
                <div
                  style={{
                    border: "1px solid #e0e0e0",
                    borderRadius: "8px",
                    padding: "20px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    backgroundColor: "#ffffff",
                    maxWidth: "600px",
                    margin: "0 auto",
                  }}
                >
                  <h1
                    style={{
                      color: "#A8001C",
                      marginBottom: "20px",
                      fontSize: "24px",
                    }}
                  >
                    <strong>Site</strong>
                  </h1>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <SelectControl
                      Descricao="Pedidos Site"
                      Campo="aceitar_pedidos_site"
                      Valores={[
                        { value: 0, descricao: "Aceitar Manualmente" },
                        { value: 1, descricao: "Aceitar Automaticamente" },
                        { value: 2, descricao: "Cancelar Automaticamente" },
                      ]}
                      Selecionado={DadosParametro?.aceitarPedidosSite}
                    />
                    <SelectControl
                      Descricao="Status Pedido Site"
                      Campo="status_pedidos_site"
                      Valores={[
                        { value: 0, descricao: "Não Aplicado" },
                        { value: 1, descricao: "Em Espera" },
                        { value: 2, descricao: "Em Produção" },
                        { value: 3, descricao: "Pronto" },
                        { value: 4, descricao: "Cancelado" },
                      ]}
                      Selecionado={DadosParametro?.statusPedidosSite}
                    />

                    {/* <SelectControl
                      Descricao="Tipo de Pedido"
                      Campo="permitir_retirada"
                      Valores={[
                        { value: 0, descricao: "Ambos" },
                        { value: 1, descricao: "Apenas Delivery" },
                        { value: 2, descricao: "Apenas Retirada" },
                      ]}
                      Selecionado={DadosParametro?.permitirRetirada}
                    /> */}
                  </div>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <Selecao
                      Descricao="Permitir Delivery"
                      Campo="delivery"
                      Valor={DadosParametro?.delivery == 1}
                    />
                    <Selecao
                      Descricao="Permitir Vem Buscar"
                      Campo="retirada"
                      Valor={DadosParametro?.retirada == 1}
                    />
                  </div>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <InputParametro
                      Descricao="Client ID"
                      Retorno={Atualiza}
                      campo="client_id"
                      Tipo="password"
                      Valor={DadosParametro?.clientId}
                    />
                    <InputParametro
                      Descricao="Client Security"
                      Retorno={Atualiza}
                      campo="client_security"
                      Tipo="password"
                      Valor={DadosParametro?.clientSecurity}
                    />
                  </div>
                  <InputParametro
                    Descricao="Link do site (https://pedir.goopedir.com/demo)"
                    Retorno={Atualiza}
                    campo="url_site"
                    Valor={DadosParametro?.urlSite}
                  />
                  <InputParametro
                    Descricao="Valor Pedido Minímo"
                    Retorno={Atualiza}
                    campo="valor_pedido_minimo"
                    Valor={DadosParametro?.valorPedidoMinimo}
                  />

                  <div>
                    <Selecao
                      Descricao="Programa de Fidelidade"
                      Campo="fidelidade"
                      Valor={DadosParametro?.fidelidade == 1}
                    />
                    {DadosParametro?.fidelidade == 1 ? (
                      <>
                        <InputParametro
                          Descricao="R$ 1,00 Equivale a 1 Ponto"
                          Retorno={Atualiza}
                          campo="fidelidade_ponto"
                          Valor={DadosParametro?.fidelidadePonto}
                        />
                        <span style={{ fontSize: "0.8rem" }}>
                          Caso queira que o 1 ponto seja equivalente 1 real
                          basta deixar 1, ou pode personalizar como desejar.
                        </span>
                        <div
                          style={{
                            display: "flex",
                            gap: "10px",
                            marginTop: "10px",
                          }}
                        >
                          <InputParametro
                            Descricao="Pontos Resgate"
                            Retorno={Atualiza}
                            campo="fidelidade_pontos"
                            Valor={DadosParametro?.fidelidadePontos}
                          />
                          <InputParametro
                            Descricao="Desconto Resgate R$"
                            Retorno={Atualiza}
                            campo="fidelidade_desc"
                            Valor={DadosParametro?.fidelidadeDesc}
                          />
                          <InputParametro
                            Descricao="Pedido Minímo"
                            Retorno={Atualiza}
                            campo="fidelidade_min"
                            Valor={DadosParametro?.fidelidadeMin}
                          />
                        </div>
                      </>
                    ) : null}
                    <div style={{ display: "flex", gap: "10px" }}>
                      <TextField
                        label="Cor de Fundo"
                        type="color"
                        value={DadosParametro?.corFundo}
                        onChange={(e) =>
                          AtualizaParametro("cor_fundo", e.target.value)
                        }
                        InputLabelProps={{ shrink: true }}
                        sx={{ width: "50%" }}
                      />
                      <TextField
                        label="Cor da Fonte"
                        type="color"
                        value={DadosParametro?.corFonte}
                        onChange={(e) =>
                          AtualizaParametro("cor_fonte", e.target.value)
                        }
                        InputLabelProps={{ shrink: true }}
                        sx={{ width: "50%" }}
                      />
                    </div>
                  </div>
                </div>
              </>
            ) : null}
            {selectedModulo == 9 ? (
              <>
                <div
                  style={{
                    border: "1px solid #e0e0e0",
                    borderRadius: "8px",
                    padding: "20px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    backgroundColor: "#ffffff",
                    maxWidth: "600px",
                    margin: "0 auto",
                  }}
                >
                  <h1
                    style={{
                      color: "#A8001C",
                      marginBottom: "20px",
                      fontSize: "24px",
                    }}
                  >
                    <strong>iFood</strong>
                  </h1>
                  <Selecao
                    Descricao="Utilizar"
                    Campo="ifood_integracao"
                    Valor={DadosParametro?.ifoodIntegracao == 1}
                  />
                  {/* <Selecao
                    Descricao="iFood NFC-e"
                    Campo="nfce"
                    Valor={DadosParametro?.nfceIfood == 1}
                  /> */}
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      marginBottom: "15px",
                    }}
                  >
                    <InputPedido
                      Descricao="URL Autenticação"
                      sx={{ width: "50%" }}
                      Valor={UrliFood}
                    />
                    <Button
                      descricao="Gerar URL"
                      sx={{ width: "50%" }}
                      evento={GetUrliFood}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      marginBottom: "15px",
                    }}
                  >
                    <InputPedido
                      Descricao="Código Autenticação"
                      sx={{ width: "50%" }}
                      Valor={CodigoAutenticacaoiFood}
                      Set={setCodigoAutenticacaoiFood}
                    />
                    <Button
                      descricao="Autenticar"
                      sx={{ width: "50%" }}
                      evento={AutenticarAPlicacao}
                    />
                  </div>
                  <InputParametro
                    Descricao="Merchant ID"
                    Retorno={Atualiza}
                    campo="merchant"
                    Valor={DadosParametro?.merchant}
                  />
                  <SelectControl
                    Descricao="Pedidos iFood"
                    Campo="aceitar_pedidos_ifood"
                    Valores={[
                      { value: 0, descricao: "Aceitar Manualmente" },
                      { value: 1, descricao: "Aceitar Automaticamente" },
                    ]}
                    Selecionado={DadosParametro?.aceitarPedidosIfood}
                  />
                  <InputParametro
                    Descricao="% Produtos"
                    Retorno={Atualiza}
                    campo="ifood_percentual"
                    Valor={DadosParametro?.ifoodPercentual}
                  />
                </div>
              </>
            ) : null}
            {selectedModulo == 10 ? (
              <>
                <div style={{ border: "1px solid black", padding: "10px" }}>
                  <h1 style={{ color: "rgb(168, 0, 28)" }}>
                    <strong>Módulo Marketing</strong>
                  </h1>
                  <Selecao
                    Descricao="Marketing"
                    Campo="marketin"
                    Valor={DadosParametro?.marketin == 1}
                  />
                  <InputParametro
                    Descricao="Quantidade Cupom Por Dia"
                    Retorno={Atualiza}
                    campo="marketin_qtd"
                    Valor={DadosParametro?.marketinQtd}
                  />
                  <SelectControl
                    Descricao="Segmento"
                    Campo="marketin_segmento"
                    Valores={[
                      { value: 0, descricao: "Ambos" },
                      { value: 1, descricao: "Com Mais Pedidos" },
                      { value: 2, descricao: "Com Menos Pedidos" },
                    ]}
                    Selecionado={DadosParametro?.aceitarPedidosIfood}
                  />
                  <InputParametro
                    Descricao="Desconto"
                    Retorno={Atualiza}
                    campo="marketin_desc"
                    Valor={DadosParametro?.marketinDesc}
                  />
                  <SelectControl
                    Descricao="Tipo Desconto"
                    Campo="marketin_tipo_desc"
                    Valores={[{ value: 0, descricao: "R$" }]}
                    // , { value: 1, descricao: '%' }
                    Selecionado={DadosParametro?.marketinTipoDesc}
                  />
                  <InputParametro
                    Descricao="Pedido Mínimo"
                    Retorno={Atualiza}
                    campo="marketin_min"
                    Valor={DadosParametro?.marketinMin}
                  />
                  <InputParametro
                    Descricao="Url Site"
                    Retorno={Atualiza}
                    campo="marketin_link"
                    Valor={DadosParametro?.marketinLink}
                  />
                  <h1 style={{ color: "rgb(168, 0, 28)" }}>
                    <strong>Dias Da Semana</strong>
                  </h1>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <Selecao
                      Descricao="Segunda-Feira"
                      Campo="marketin_seg"
                      Valor={DadosParametro?.marketinSeg == 1}
                    />
                    <Selecao
                      Descricao="Terça-Feira"
                      Campo="marketin_ter"
                      Valor={DadosParametro?.marketinTer == 1}
                    />
                    <Selecao
                      Descricao="Quarta-Feira"
                      Campo="marketin_qua"
                      Valor={DadosParametro?.marketinQua == 1}
                    />
                    <Selecao
                      Descricao="Quinta-Feira"
                      Campo="marketin_qui"
                      Valor={DadosParametro?.marketinQui == 1}
                    />
                    <Selecao
                      Descricao="Sexta-Feira"
                      Campo="marketin_sex"
                      Valor={DadosParametro?.marketinSex == 1}
                    />
                    <Selecao
                      Descricao="Sabado"
                      Campo="marketin_sab"
                      Valor={DadosParametro?.marketinSab == 1}
                    />
                    <Selecao
                      Descricao="Domingo"
                      Campo="marketin_dom"
                      Valor={DadosParametro?.marketinDom == 1}
                    />
                  </div>

                  <MensagemImagemMarketing
                    label="Segunda"
                    Value={Segunda}
                    AtualizaMensagem={AtualizaMensagem}
                    Set={setSegunda}
                    Imagem={ImagemSegunda}
                    SetImagem={setImagemSegunda}
                    Dia="segunda"
                  />
                  <MensagemImagemMarketing
                    label="Terça"
                    Value={Terca}
                    AtualizaMensagem={AtualizaMensagem}
                    Set={setTerca}
                    Imagem={ImagemTerca}
                    SetImagem={setImagemTerca}
                    Dia="terca"
                  />
                  <MensagemImagemMarketing
                    label="Quarta"
                    Value={Quarta}
                    AtualizaMensagem={AtualizaMensagem}
                    Set={setQuarta}
                    Imagem={ImagemQuarta}
                    SetImagem={setImagemQuarta}
                    Dia="quarta"
                  />

                  <MensagemImagemMarketing
                    label="Quinta"
                    Value={Quinta}
                    AtualizaMensagem={AtualizaMensagem}
                    Set={setQuinta}
                    Imagem={ImagemQuinta}
                    SetImagem={setImagemQuinta}
                    Dia="quinta"
                  />
                  <MensagemImagemMarketing
                    label="Sexta"
                    Value={Sexta}
                    AtualizaMensagem={AtualizaMensagem}
                    Set={setSexta}
                    Imagem={ImagemSexta}
                    SetImagem={setImagemSexta}
                    Dia="sexta"
                  />
                  <MensagemImagemMarketing
                    label="Sábado"
                    Value={Sabado}
                    AtualizaMensagem={AtualizaMensagem}
                    Set={setSabado}
                    Imagem={ImagemSabado}
                    SetImagem={setImagemSabado}
                    Dia="sabado"
                  />
                  <MensagemImagemMarketing
                    label="Domingo"
                    Value={Domingo}
                    AtualizaMensagem={AtualizaMensagem}
                    Set={setDomingo}
                    Imagem={ImagemDomingo}
                    SetImagem={setImagemDomingo}
                    Dia="domingo"
                  />

                  {/* <TextField
                    label={"Mensagem Segunda"}
                    multiline
                    fullWidth
                    rows={5}
                    value={Segunda}
                    onChange={(e) => [
                      AtualizaMensagem("segunda", e.target.value),
                      setSegunda(e.target.value),
                    ]}
                    sx={{ marginTop: "10px" }}
                    variant="outlined"
                  /> */}
                  {/* <TextField
                    label={"Mensagem Terça"}
                    multiline
                    fullWidth
                    rows={5}
                    value={Terca}
                    onChange={(e) => [
                      AtualizaMensagem("terca", e.target.value),
                      setTerca(e.target.value),
                    ]}
                    sx={{ marginTop: "10px" }}
                    variant="outlined"
                  />
                  <TextField
                    label={"Mensagem Quarta"}
                    multiline
                    fullWidth
                    rows={5}
                    value={Quarta}
                    onChange={(e) => [
                      AtualizaMensagem("quarta", e.target.value),
                      setQuarta(e.target.value),
                    ]}
                    sx={{ marginTop: "10px" }}
                    variant="outlined"
                  />
                  <TextField
                    label={"Mensagem Quinta"}
                    multiline
                    fullWidth
                    rows={5}
                    value={Quinta}
                    onChange={(e) => [
                      AtualizaMensagem("quinta", e.target.value),
                      setQuinta(e.target.value),
                    ]}
                    sx={{ marginTop: "10px" }}
                    variant="outlined"
                  />
                  <TextField
                    label={"Mensagem Sexta"}
                    multiline
                    fullWidth
                    rows={5}
                    value={Sexta}
                    onChange={(e) => [
                      AtualizaMensagem("sexta", e.target.value),
                      setSexta(e.target.value),
                    ]}
                    sx={{ marginTop: "10px" }}
                    variant="outlined"
                  />
                  <TextField
                    label={"Mensagem Sábado"}
                    multiline
                    fullWidth
                    rows={5}
                    value={Sabado}
                    onChange={(e) => [
                      AtualizaMensagem("sabado", e.target.value),
                      setSabado(e.target.value),
                    ]}
                    sx={{ marginTop: "10px" }}
                    variant="outlined"
                  />
                  <TextField
                    label={"Mensagem Domingo"}
                    multiline
                    fullWidth
                    rows={5}
                    value={Domingo}
                    onChange={(e) => [
                      AtualizaMensagem("domingo", e.target.value),
                      setDomingo(e.target.value),
                    ]}
                    sx={{ marginTop: "10px" }}
                    variant="outlined"
                  /> */}
                </div>
              </>
            ) : null}
            {selectedModulo == 11 ? (
              <>
                <div
                  style={{
                    border: "1px solid #e0e0e0",
                    borderRadius: "8px",
                    padding: "20px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    backgroundColor: "#ffffff",
                    maxWidth: "600px",
                    margin: "0 auto",
                  }}
                >
                  <h1
                    style={{
                      color: "#A8001C",
                      marginBottom: "20px",
                      fontSize: "24px",
                    }}
                  >
                    <strong>Impressão</strong>
                  </h1>
                  <Selecao
                    Descricao="Serviço Impressão"
                    Campo="a_impressora"
                    Valor={DadosParametro?.aImpressora == 1}
                  />
                  <Selecao
                    Descricao="Impressão via Cozinha (Apenas para Mesas)"
                    Campo="cozinha_apenas_mesa"
                    Valor={DadosParametro?.cozinhaApenasMesa == 1}
                  />
                  <Selecao
                    Descricao="Impressão Cozinha Agrupada Por Grupo"
                    Campo="impressao_agrupada"
                    Valor={DadosParametro?.impressaoAgrupada == 1}
                  />
                  <Selecao
                    Descricao="Ocultar Categoria da Impressao"
                    Campo="oculta_categoria"
                    Valor={DadosParametro?.ocultaCategoria == 1}
                  />
                  {/* <InputParametro
                    Descricao="Quantidade Vias Impressão"
                    Retorno={Atualiza}
                    campo="vias_pedido"
                    Valor={DadosParametro?.viasPedido}
                  /> */}
                  <SelectControl
                    Descricao="Configuração Bobina Impressão"
                    Campo="tipo_impressao"
                    Valores={[
                      { value: 0, descricao: "58mm" },
                      { value: 1, descricao: "80mm" },
                    ]}
                    Selecionado={DadosParametro?.tipoImpressao}
                  />
                  <div style={{ display: "flex", gap: "10px" }}>
                    <InputParametro
                      Descricao="Margem Cima"
                      Retorno={Atualiza}
                      campo="margin_top"
                      Valor={DadosParametro?.marginTop}
                    />
                    <InputParametro
                      Descricao="Margem Baixo"
                      Retorno={Atualiza}
                      campo="margin_botton"
                      Valor={DadosParametro?.marginBotton}
                    />
                    <InputParametro
                      Descricao="Margem Esquerda"
                      Retorno={Atualiza}
                      campo="margin_left"
                      Valor={DadosParametro?.marginLeft}
                    />
                    <InputParametro
                      Descricao="Margem Direita"
                      Retorno={Atualiza}
                      campo="margin_rigth"
                      Valor={DadosParametro?.marginRigth}
                    />
                  </div>
                </div>
              </>
            ) : null}
            {selectedModulo == 12 ? (
              <>
                <GarcomComponent />
              </>
            ) : null}

            {/* <InputPedido Descricao="Tipo Empresa" Retorno={Atualiza} Valor={0} /> */}
            {/* Siples Nacional */}
          </>
        ) : null}
      </HeaderComponent>
    </>
  );
}
