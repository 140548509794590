import { GravaLocal } from "../../../../../../controller/storage";

const storageKey = "items";
let AtualizouDados = false;

// Carrega o array do localStorage
export function loadData() {
  const data = localStorage.getItem(storageKey);
  return data ? JSON.parse(data) : [];
}

// Salva o array no localStorage
export function saveData(data) {
  GravaLocal(storageKey, JSON.stringify(data));
}

// Adiciona um novo item ou atualiza o 'total' de um item existente
export function addItem(codigo, valor, qtd) {
  const data = loadData();
  const index = data.findIndex((item) => item.id === codigo);
  if (index === -1) {
    data.push({ id: codigo, total: valor, quantidade: 1, min: qtd });
  } else {
    data[index].total = valor;
  }
  saveData(data);
  AtualizouDados = true;
}

// Remove um item com base no código
export function removeItem(codigo) {
  let data = loadData();
  data = data.filter((item) => item.id !== codigo);
  saveData(data);
  AtualizouDados = true;
}

// Atualiza os valores 'total' e 'quantidade' para um item específico
export function setValues(codigo, total, quantidade) {
  const data = loadData();
  const index = data.findIndex((item) => item.id === codigo);
  if (index !== -1) {
    data[index].total = total;
    data[index].quantidade = quantidade;
    saveData(data);
  }
  AtualizouDados = false;
}

export function getQuantidade(codigo) {
  const data = loadData();
  const index = data.findIndex((item) => item.id === codigo);
  if (index !== -1) {
    return data[index].quantidade ? data[index].quantidade : 1;
  }
  return 1;
}

// Soma todos os 'total' e 'quantidades' dos itens
export function summarize() {
  const data = loadData();
  return data.reduce(
    (acc, item) => {
      acc.total += item.total / item.quantidade;
      acc.quantidade += item.quantidade;
      return acc;
    },
    { total: 0, quantidade: 0 }
  );
}

export function RemoverSelected() {
  localStorage.removeItem(storageKey);
}

export function PegouDados() {
  AtualizouDados = true;
}

export function BuscarNovoValor() {
  let valor = summarize();
  if (valor.total == 0) {
    return false;
  }
  return !AtualizouDados;
}
