import { useState, useEffect, useMemo } from "react";
import { API } from "../../controller/api/api";
import { ComponenteEntradaSaidaEstoque } from "../../component/estoque/ModalEntradaSaida";
import "./style.css";
import { HeaderComponent } from "../../component/header";
import { PrimeiraMaiuscula } from "../../controller/util";
import { MascaraNumericaTresCasas } from "../../controller/data";
import {
  FaArrowDown,
  FaArrowUp,
  FaInfoCircle,
  FaRegTimesCircle,
} from "react-icons/fa";
import Table from "../../component/tabela";
import { EconomyModal } from "../../component/estoque/ModalEconomy";
export function PageEstoque() {
  const [Tipo, setTipo] = useState(1);
  const [Estoque, setEstoque] = useState([]);
  const [Data, setData] = useState([]);
  const [isModalEntrada, setIsModalEntrada] = useState(false);
  const [CodigoSelecionado, setCodigoSelecionado] = useState(0);
  const [DataFiltro, setDataFiltro] = useState([]);
  const [Busca, setBusca] = useState("");
  const [TipoEntradaSaida, setTipoEntradaSaida] = useState("entrada");
  const [DadosEconomia, setDadosEconomia] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    BuscaEstoque();
  }, [Tipo]);

  useEffect(() => {
    AtualizacaoInput();
  }, [Busca]);

  useEffect(() => {
    if (Data.length > 0) {
      AtualizacaoInput();
    }
  }, [Data]);

  useEffect(() => {
    BuscaEstoque();
  }, []);

  function AtualizacaoInput() {
    ////console.log(Busca.length != 0);
    if (Busca.length != 0) {
      const filtered = Data.filter((pesquisa) =>
        pesquisa.nome.toLowerCase().includes(Busca.toLowerCase())
      );
      ////console.log(filtered);
      setDataFiltro(filtered);
    } else {
      setDataFiltro(Data);
    }
  }

  function BuscaEstoque() {
    API.get("v1/util/estoque/geral").then(function (dados) {
      setEstoque(dados.data);

      let resultado = dados.data
        .filter((item) => item.tipo === Tipo)
        .map((item, index) => ({
          id: item.id,
          nome: item.nome,
          un: item.un,
          entrada: item.entrada,
          sequencial: item.sequencial,
          qtd: item.qtd,
          min: item.min,
          index: index,
        }))
        .sort((a, b) => a.qtd - b.qtd);

      setData(resultado);
      // setDataFiltro(resultado);
      AtualizacaoInput();
    });
  }

  function Close(dados) {
    BuscaEstoque();
    setIsModalEntrada(false);
    setDadosEconomia(dados);
    setShowModal(true);
  }

  const columns = useMemo(
    () => [
      {
        Header: "Produto",
        accessor: "nome",
        size: "80%",
        format: PrimeiraMaiuscula,
      },
      {
        Header: "Estoque",
        accessor: "qtd",
        size: "20%",
        // format: PintaValores,
        formatOption: PintaValores,
        textAlign: "center",
      },
      {
        Header: "Entrada",
        size: "150px",
        component: (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <FaArrowUp color="#269931" style={{ cursor: "pointer" }} />
          </div>
        ),
        func: Entrada,
      },
      {
        Header: "Saida",
        size: "150px",
        component: (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <FaArrowDown color="#A8001C" style={{ cursor: "pointer" }} />
          </div>
        ),
        func: Saida,
      },
    ],
    []
  );

  function Entrada(dados) {
    setTipoEntradaSaida("entrada");
    setCodigoSelecionado(dados);
    setIsModalEntrada(true);
  }

  function Saida(dados) {
    setTipoEntradaSaida("saida");
    setCodigoSelecionado(dados);
    setIsModalEntrada(true);
  }

  function PintaValores(valor, dataFiltro) {
    //console.log(dataFiltro);
    var item = dataFiltro.find(function (elemento) {
      return elemento.id === valor.id;
    });
    console.log(item);
    if (item?.qtd > 0) {
      if (item?.qtd <= item?.min) {
        return (
          <div
            style={{
              color: "#f06e18",
              display: "flex",
              justifyContent: "center",
              gap: "3px",
              alignItems: "center",
            }}
          >
            <FaInfoCircle style={{ cursor: "pointer" }} />
            <span>
              {MascaraNumericaTresCasas(item?.qtd) +
                " " +
                PrimeiraMaiuscula(item?.un)}
            </span>
          </div>
        );
      } else {
        return (
          <span>
            {MascaraNumericaTresCasas(item.qtd) +
              " " +
              PrimeiraMaiuscula(item?.un)}
          </span>
        );
      }
    } else {
      return (
        <div
          style={{
            color: "#A8001C",
            display: "flex",
            justifyContent: "center",
            gap: "3px",
            alignItems: "center",
          }}
        >
          <FaRegTimesCircle style={{ cursor: "pointer" }} />
          <span>
            {MascaraNumericaTresCasas(item?.qtd) +
              " " +
              PrimeiraMaiuscula(item?.un)}
          </span>
        </div>
      );
    }
  }

  return (
    <>
      <HeaderComponent>
        <div className="DivPrincipal">
          <div className="DivMenu">
            <h1
              onClick={() => setTipo(1)}
              className={Tipo === 1 ? "ProdutoSelected" : "ProdutoUnSelected"}
            >
              Produto
            </h1>
            <h1
              onClick={() => setTipo(2)}
              className={Tipo === 2 ? "InsumoSelected" : "InsumoUnSelected"}
            >
              Insumo
            </h1>
          </div>
          {DataFiltro ? (
            <>
              <Table
                columns={columns}
                data={DataFiltro}
                codigo={"id"}
                //   func={AlterarDadosCliente}
              />
            </>
          ) : null}
          {/* <div className="Barra"></div> */}
          {/* <BarraDePesquisa onPesquisa={setBusca} />
          <Tabela dados={DataFiltro} tipo={Tipo} atualizacao={BuscaEstoque} /> */}
        </div>

        {isModalEntrada ? (
          <ComponenteEntradaSaidaEstoque
            tipo={Tipo}
            codigo={CodigoSelecionado}
            close={Close}
            tipoEntradaSaida={TipoEntradaSaida}
          />
        ) : null}
        {showModal ? (
          <EconomyModal
            data={DadosEconomia}
            onClose={() => setShowModal(false)}
          />
        ) : null}
      </HeaderComponent>
    </>
  );
}
