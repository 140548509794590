import { useEffect, useMemo, useState } from "react";
import { HeaderComponent } from "../../component/header";
import { ModalGravarDados } from "../../component/gravar";
import { InputPedido } from "../pedido/componente/input";
import {
  Autocomplete,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Notifica, PrimeiraMaiuscula } from "../../controller/util";
import { ComponentCadastro } from "../../component/header/cadastro";
import {
  formatarValor,
  MascaraNumericaTresCasas,
  RemoveMascara,
} from "../../controller/data";
import { FaEdit, FaHamburger, FaRegTrashAlt } from "react-icons/fa";
import { API } from "../../controller/api/api";
import { ItemFichaTecnica } from "./ItemFicha";
import Table from "../../component/tabela";
import { BuscaLocal } from "../../controller/storage";
import LoadingOverlay from "../../component/load";

let DadosPadrao = [];
export function PageIngredientes() {
  const [isCadastro, setIsCadastro] = useState(false);
  const [Codigo, setCodigo] = useState(0);
  const [Nome, setNome] = useState("");
  const [Unidade, setUnidade] = useState("KG");
  const [Tipo, setTipo] = useState(1);
  const [Quantidade, setQuantidade] = useState(0);
  const [Dados, setDados] = useState([]);
  const [FichaTecnica, setFichatecnica] = useState([]);
  const [InsulmoSelecionado, setInsulmoSelecionado] = useState(null);
  const [loading, setloading] = useState(false);

  function SalvarDados() {
    setloading(true);
    let body = {
      id: Codigo,
      descricao: Nome,
      unidade: Unidade,
      tipo: Tipo,
      quantidade: RemoveMascara(Quantidade),
      ficha: FichaTecnica,
      custo: CustoTotal() > 0 ? CustoTotal() / RemoveMascara(Quantidade) : 0,
    };

    API.post("v2/insulmos", body).then((response) => {
      BuscaDados();
      setIsCadastro(false);
      Notifica("Insulmo salvo com sucesso!");
    });
  }

  function Novo() {
    setCodigo(0);
    setNome("");
    setQuantidade(0);
    setUnidade("KG");
    setTipo(1);
    setFichatecnica([]);
    setIsCadastro(true);
  }

  useEffect(() => {
    BuscaDados();
  }, []);

  function BuscaDados() {
    setloading(true);
    API.get(`/v1/consulta/generica/ingredientes/*/*/tipo desc, descricao`).then(
      (response) => {
        setDados(response.data);
        DadosPadrao = response.data;
        setloading(false);
      }
    );
  }

  function TipoInsulmo(valor, dataFiltro) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {valor == 2 ? "Produzido" : "Produto"}
        {/* <Switch size="small" checked={valor == 1 ? true : false} /> */}
      </div>
    );
  }

  const columns = useMemo(() => {
    return [
      {
        Header: "Descrição",
        accessor: "descricao",
        size: "100px",
        // textAlign: "center",
        format: PrimeiraMaiuscula,
      },
      {
        Header: "Tipo",
        accessor: "tipo",
        size: "100px",
        // textAlign: "center",
        format: TipoInsulmo,
      },

      {
        Header: "Unidade",
        accessor: "unidade",
        size: "10px",
        textAlign: "center",
        format: PrimeiraMaiuscula,
      },
      {
        Header: "Saldo",
        accessor: "saldo",
        size: "100px",
        // textAlign: "center",
        // format: PintaValores,
        format: MascaraNumericaTresCasas,
      },

      {
        Header: "Ficha Técnica",
        size: "50px",
        component: (
          <div
            style={{
              display: "flex",
            }}
          >
            <IconButton color="primary">
              <FaHamburger />
            </IconButton>
          </div>
        ),
        func: AlterarDados,
      },
    ];
  }, []);

  function AlterarDados(dados) {
    setloading(true);
    const objetoEncontrado = DadosPadrao.find((objeto) => objeto.id === dados);
    setCodigo(objetoEncontrado.id);
    setNome(objetoEncontrado.descricao);
    setQuantidade(objetoEncontrado.quantidade);
    setUnidade(objetoEncontrado.unidade);
    setTipo(objetoEncontrado.tipo == 0 ? 1 : objetoEncontrado.tipo);

    API.get(`/v2/insulmos/ficha/${objetoEncontrado.id}`).then((response) => {
      setFichatecnica(response.data);
      console.log(response.data);
      setIsCadastro(true);
      setloading(false);
    });
  }

  const handleAutocompleteChange = (event, newValue) => {
    if (newValue) {
      const index = Dados.findIndex(
        (item) => PrimeiraMaiuscula(item.descricao) === newValue
      );
      setInsulmoSelecionado(Dados[index]);
    } else {
      setInsulmoSelecionado(null);
    }
  };

  function AdicionaInsulmo() {
    setFichatecnica((prevFichatecnica) => {
      // Verifica se o id_composicao já existe na lista
      const itemExiste = prevFichatecnica.some(
        (item) => item.idComposicao === InsulmoSelecionado.id
      );

      // Se o item já existir, não adiciona
      if (itemExiste) {
        return prevFichatecnica; // Retorna o estado anterior sem modificações
      }

      // Caso contrário, adiciona o novo item
      return [
        ...prevFichatecnica,
        {
          id: 0,
          idIngrediente: Codigo,
          idComposicao: InsulmoSelecionado.id,
          descricao: InsulmoSelecionado.descricao,
          quantidade: 0,
          unidade: InsulmoSelecionado.unidade,
          custo: InsulmoSelecionado.custo,
        },
      ];
    });
  }

  function RemoveInsulmo(index) {
    setFichatecnica((prevFichatecnica) => {
      return prevFichatecnica.filter((_, i) => i !== index);
    });
  }

  function AtualizaQuantidade(index, novaQuantidade) {
    setFichatecnica((prevFichatecnica) => {
      return prevFichatecnica.map((item, i) =>
        i === index ? { ...item, quantidade: novaQuantidade } : item
      );
    });
  }

  function CustoTotal() {
    const custoTotal = FichaTecnica.reduce((total, item) => {
      return total + item.custo * item.quantidade;
    }, 0);

    return custoTotal;
  }

  return (
    <>
      <HeaderComponent>
        {loading && <LoadingOverlay />}
        <ComponentCadastro Titulo="Insulmos" Funcao={Novo} />
        <Table columns={columns} data={Dados} codigo={"id"} />
        {isCadastro ? (
          <>
            <ModalGravarDados
              Descricao={Codigo == 0 ? "Cadastro" : "Alteração"}
              closeButton={() => setIsCadastro(false)}
              Funcao={SalvarDados}
            >
              <InputPedido
                Descricao="Nome"
                Set={setNome}
                Valor={PrimeiraMaiuscula(Nome)}
                sx={{ width: "100%", marginBottom: "15px" }}
              />
              <FormControl fullWidth>
                <InputLabel id="Unidade">Unidade de Medida</InputLabel>
                <Select
                  labelId="Unidade"
                  id="Unidade"
                  value={Unidade}
                  label="Unidade de Medida"
                  onChange={(e) => setUnidade(e.target.value)}
                  sx={{ width: "100%", marginBottom: "15px" }}
                  size="small"
                >
                  <MenuItem value={"UN"}>Unidade (Un)</MenuItem>
                  <MenuItem value={"G"}>Grama (g)</MenuItem>
                  <MenuItem value={"KG"}>Quilograma (kg)</MenuItem>
                  <MenuItem value={"L"}>Litros (l)</MenuItem>
                  <MenuItem value={"ML"}>Mililitro (ml)</MenuItem>
                </Select>
              </FormControl>

              <FormControl fullWidth>
                <InputLabel id="Tipo">Tipo do Insulmo</InputLabel>
                <Select
                  labelId="Tipo"
                  id="Tipo"
                  value={Tipo}
                  label="Tipo do Insulmo"
                  onChange={(e) => setTipo(e.target.value)}
                  sx={{ width: "100%", marginBottom: "15px" }}
                  size="small"
                >
                  <MenuItem value={1}>Produto</MenuItem>
                  <MenuItem value={2}>Produzido</MenuItem>
                </Select>
              </FormControl>
              {Tipo == 2 ? (
                <>
                  <h3 style={{ textAlign: "center", marginBottom: "10px" }}>
                    <b>Composição do Insulmo</b>
                  </h3>
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    <Autocomplete
                      //   value={InsulmoSelecionado}
                      onChange={handleAutocompleteChange}
                      options={Dados.map((item) =>
                        PrimeiraMaiuscula(item.descricao)
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Insulmo"
                          variant="outlined"
                        />
                      )}
                      fullWidth
                      size="small"
                    />
                    <Button
                      onClick={AdicionaInsulmo}
                      sx={{ width: "100px" }}
                      variant="outlined"
                    >
                      Adicionar
                    </Button>
                  </div>

                  {FichaTecnica.map((item, index) => (
                    <ItemFichaTecnica
                      key={index}
                      Unidade={item.unidade}
                      Descricao={item.descricao}
                      Quantidade={item.quantidade}
                      Id={index}
                      Remove={RemoveInsulmo}
                      Atualiza={AtualizaQuantidade}
                      Custo={item.custo}
                    />
                  ))}

                  <TextField
                    label="Quantidade"
                    id="outlined-start-adornment"
                    style={{ marginBottom: "10px", width: "100%" }}
                    value={Quantidade}
                    onChange={(e) => setQuantidade(e.target.value)}
                    onBlur={(e) => [
                      setQuantidade(
                        MascaraNumericaTresCasas(RemoveMascara(e.target.value))
                      ),
                    ]}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {PrimeiraMaiuscula(Unidade)}
                        </InputAdornment>
                      ),
                    }}
                  />

                  <div style={{ display: "flex", gap: "10px" }}>
                    <TextField
                      label="Custo Total"
                      style={{ marginBottom: "10px", width: "100%" }}
                      value={MascaraNumericaTresCasas(CustoTotal())}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">R$</InputAdornment>
                        ),
                      }}
                    />
                    <TextField
                      label={PrimeiraMaiuscula(`Custo Por ${Unidade}`)}
                      style={{ marginBottom: "10px", width: "100%" }}
                      value={MascaraNumericaTresCasas(
                        CustoTotal() / RemoveMascara(Quantidade)
                      )}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">R$</InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </>
              ) : null}
              {/* FichaTecnica */}
              {/* <ItemFichaTecnica /> */}
            </ModalGravarDados>
          </>
        ) : null}
      </HeaderComponent>
    </>
  );
}
