import React, { useEffect, useState } from "react";
import { FaWhatsapp, FaCog } from "react-icons/fa"; // Ícones para WhatsApp e engrenagem
import "./PageForaDoAr.css"; // Importação do arquivo de estilos
import { axiosCheckService } from "../../controller/api/api";
import { BuscaLocal } from "../../controller/storage";

export function PageForaDoAr() {
  const handleRetry = () => {
    window.history.back(); // Voltar para a última página
  };

  const [ErroInesperado, setErroInesperado] = useState(
    BuscaLocal("ErroInterno") ? BuscaLocal("ErroInterno") : null
  );

  useEffect(() => {
    if (BuscaLocal("ErroInterno") == null) {
      setErroInesperado(null);
      const intervalId = setInterval(async () => {
        try {
          // Aqui, faça a requisição para verificar o status do serviço
          const response = await axiosCheckService.get("/v2/status");
          if (response.status === 200) {
            // Se o serviço voltou, redireciona o usuário de volta à página anterior
            const previousPage = localStorage.getItem("previousPage");
            if (previousPage) {
              window.location.href = previousPage; // Redireciona para a página anterior
            } else {
              window.location.href = "/"; // Ou redireciona para uma página padrão
            }
          }
        } catch (error) {
          console.log("Serviço ainda indisponível");
        }
      }, 5000); // Verifica a cada 5 segundos
      return () => clearInterval(intervalId); // Limpa o intervalo quando o componente desmonta}
    }
  }, []);

  return (
    <div className="modern-container">
      <div className="message-container">
        <FaCog className="spinning-gear" />
        <div className="text-message">
          {ErroInesperado ? (
            <>
              <h1>Ops, Aconteceu Um Erro Inesperado</h1>
              <h2>Rota: {ErroInesperado?.url}</h2>
              <h2>Metodo: {ErroInesperado?.metodo}</h2>
              <h2>
                <b>{ErroInesperado?.erro}</b>
              </h2>
              <h3>Contate o suporte, se possivel envie uma foto dessa tela!</h3>
            </>
          ) : (
            <>
              <h1>Sem Conexão!</h1>
            </>
          )}
        </div>
      </div>

      <div className="contact-container">
        <FaWhatsapp className="whatsapp-icon" />
        <a
          href="https://wa.me/554835124865"
          target="_blank"
          rel="noopener noreferrer"
          className="contact-link"
        >
          Entre em contato com o suporte +55 (48) 3512-4865
        </a>
      </div>

      <button onClick={handleRetry} className="modern-retry-button">
        Tentar Novamente
      </button>

      <div className="logo-container">
        <img
          src="/logo-goopedir-p.png"
          alt="GooPedir LTDA"
          className="modern-company-logo"
        />
      </div>
    </div>
  );
}
