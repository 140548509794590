import { useEffect, useMemo, useState } from "react";
import { HeaderComponent } from "../../component/header";
import { NovaCampanha } from "./novo";
import { API, GooPedirAPI } from "../../controller/api/api";
import { Notifica, PrimeiraMaiuscula } from "../../controller/util";
import {
  formatarData,
  formatarDataHora,
  MascaraInteiro,
  MascaraMonetaria,
} from "../../controller/data";
import LoadingOverlay from "../../component/load";
import Table from "../../component/tabela";
import { IconButton } from "@mui/material";
import { FaEllipsisV } from "react-icons/fa";
import { ShowModal } from "../../component/modalPai";
import { ComponentCadastro } from "../../component/header/cadastro";
import { AcoesMarketin } from "./acoes";
import { GravaLocal } from "../../controller/storage";

let DadosMemorizados = [];
export function PageMarketin() {
  const [UserID, setUserID] = useState(0);
  const [loading, setLoading] = useState(false);
  const [Dados, setDados] = useState([]);
  const [ModalCampanha, setModalCampanha] = useState(false);
  const [DadosCopia, setDadosCopia] = useState(null);

  useEffect(() => {
    API.get("/v2/status").then((response) => {
      setUserID(response.data.user);
    });
  }, []);

  useEffect(() => {
    if (UserID > 0) {
      BuscarCampanhas();
    }
  }, [UserID]);

  function BuscarCampanhas() {
    setLoading(true);
    GooPedirAPI.get(`ws/v1/campanhas.php?codigo=${UserID}`).then(function (
      response
    ) {
      setDados(response.data ? response.data : []);
      DadosMemorizados = response.data ? response.data : [];
      console.log(response.data);
      setLoading(false);
    });
  }

  const columns = useMemo(
    () => [
      {
        Header: "Nome Campanha",
        accessor: "nome",
        size: "80%",
        formatOption: DescricaoCampanha,
        textAlign: "",
      },
      {
        Header: "Mensagem",
        accessor: "estimativa",
        size: "50px",
        // format: PintaValores,
        // format: MascaraInteiro,
        formatOption: PintaMensagem,
        textAlign: "center",
      },
      {
        Header: "Tempo",
        accessor: "inicio_data",
        size: "50px",
        // format: PintaValores,
        // format: MascaraInteiro,
        formatOption: PintaTempo,
        textAlign: "center",
      },
      {
        Header: "Convertidos",
        accessor: "enviadas",
        size: "20%",
        // format: PintaValores,
        // format: MascaraInteiro,
        formatOption: PintaValores,
        textAlign: "center",
      },
      // {
      //   Header: "Conversões",
      //   accessor: "enviadas1",
      //   size: "100px",
      //   // format: PintaValores,
      //   format: MascaraInteiro,
      //   textAlign: "",
      // },

      //   {
      //     Header: "Notificação WPP",
      //     accessor: "naoreceber",
      //     size: "100px",
      //     // format: PintaValores,
      //     format: RecebeNotificacao,
      //     textAlign: "center",
      //   },

      {
        Header: "Ações",
        size: "50px",
        component: (
          <AcoesMarketin
            Duplicar={DuplicarMarketin}
            Excluir={ExcluirMarketin}
          />
        ),

        func: Acoes,
      },
    ],
    []
  );

  function ExcluirMarketin(dados) {
    GooPedirAPI.post(
      `ws/v1/deleta_campanha.php?codigo=${dados.id}&user=${dados.user_id}`
    ).then(function (response) {
      Notifica("Campanha excluida com sucesso!");
      Close();
    });
  }

  function DuplicarMarketin(dados) {
    // //console.log(dados);
    setLoading(true);

    GooPedirAPI.get(`ws/v1/campanha_completa.php?codigo=${dados.id}`).then(
      function (response) {
        console.log(response.data[0]);
        setDadosCopia(response.data[0]);
        setLoading(false);
        setModalCampanha(true);
      }
    );

    // setDadosCopia(dados);
  }

  function Acoes(dados) {
    const registro = DadosMemorizados.find((dado) => dado.id === dados);
    GravaLocal("marketin_selecionado", JSON.stringify(registro));
  }

  function New() {
    setDadosCopia(null);
    setModalCampanha(true);
  }

  function Close() {
    setDadosCopia(null);
    setModalCampanha(false);
    setLoading(true);
    BuscarCampanhas();
  }

  function PintaValores(valor, dataFiltro) {
    var item = dataFiltro.find(function (elemento) {
      return elemento.id === valor.id;
    });
    // //console.log(item);
    return (
      <>
        <p>
          <b>
            {item.pedidos_convertidos +
              " - " +
              MascaraMonetaria(parseFloat(item.pedidos_total))}
          </b>
        </p>

        <p>
          <span style={{ fontSize: "0.75rem" }}>
            <i>
              {MascaraMonetaria(
                parseFloat(item.pedidos_total) /
                  parseInt(item.pedidos_convertidos)
              )}{" "}
              média dos pedidos
            </i>
          </span>
        </p>
      </>
    );
  }

  function DescricaoCampanha(valor, dataFiltro) {
    var item = dataFiltro.find(function (elemento) {
      return elemento.id === valor.id;
    });
    function Repetir(item) {
      let dias = [];

      if (parseInt(item.seg) === 1) {
        dias.push("Seg");
      }
      if (parseInt(item.ter) === 1) {
        dias.push("Ter");
      }
      if (parseInt(item.qua) === 1) {
        dias.push("Qua");
      }
      if (parseInt(item.qui) === 1) {
        dias.push("Qui");
      }
      if (parseInt(item.sex) === 1) {
        dias.push("Sex");
      }
      if (parseInt(item.sab) === 1) {
        dias.push("Sab");
      }
      if (parseInt(item.dom) === 1) {
        dias.push("Dom");
      }

      return dias.join(","); // Retorna os dias concatenados com vírgula
    }
    function StatusExecucao(item) {
      const hoje = new Date();
      const conclusao = new Date(item.conclusao);
      console.log(conclusao);
      console.log(hoje);

      if (hoje > conclusao) {
        return "Concluído";
      } else {
        return `Até: ${formatarData(item.conclusao)}`;
      }
    }

    return (
      <>
        <p>
          <b>{item.nome}</b>
        </p>

        {Repetir(item) != "" ? (
          <>
            <div style={{ color: "#ccc" }}>
              <p>
                <b>
                  Dias:{" "}
                  <span style={{ fontSize: "0.65rem" }}>{Repetir(item)}</span>
                </b>
              </p>

              <p>
                <b>{StatusExecucao(item)}</b>
              </p>
            </div>
          </>
        ) : null}
      </>
    );
  }

  function PintaMensagem(valor, dataFiltro) {
    var item = dataFiltro.find(function (elemento) {
      return elemento.id === valor.id;
    });
    // //console.log(item);
    return (
      <>
        <p>
          <b>
            {item.estimativa}/{item.enviadas}
          </b>
        </p>
        <p>
          <i>{calcularPercentual(item.enviadas, item.estimativa)}%</i>
        </p>
      </>
    );
  }

  function calculaTempoPassado(inicio, fim) {
    // Converte a string da data inicial em um objeto Date
    const dataInicio = new Date(inicio);

    // Obtém a data e hora definida como 'fim'
    const agora = new Date(fim);

    // Calcula a diferença em milissegundos
    const diferenca = agora - dataInicio;

    // Converte a diferença para minutos, horas e dias
    const segundosTotal = Math.floor(diferenca / 1000);
    const minutosTotal = Math.floor(segundosTotal / 60);
    const horasTotal = Math.floor(minutosTotal / 60);

    // Extração dos segundos e minutos restantes
    const segundos = segundosTotal % 60;
    const minutos = minutosTotal % 60;

    // Formatação para manter sempre dois dígitos
    const horasFormatadas = String(horasTotal).padStart(2, "0");
    const minutosFormatados = String(minutos).padStart(2, "0");
    const segundosFormatados = String(segundos).padStart(2, "0");

    // Retorna a string formatada
    return `${horasFormatadas}:${minutosFormatados}:${segundosFormatados}`;
  }

  function PintaTempo(valor, dataFiltro) {
    var item = dataFiltro.find(function (elemento) {
      return elemento.id === valor.id;
    });
    // //console.log(item);
    return (
      <>
        <p>
          <b>
            {item.inicio_data
              ? calculaTempoPassado(item.inicio_data, item.fim_data)
              : null}
          </b>
        </p>
        <p>
          {/* <i>{calcularPercentual(item.enviadas, item.estimativa)}%</i> */}
        </p>
      </>
    );
  }

  function calcularPercentual(parte, total) {
    if (total === 0) return "0.00"; // Evitar divisão por zero e retornar 0.00
    const percentual = (parte / total) * 100;
    return percentual.toFixed(2); // Formatar com 2 casas decimais
  }

  return (
    <>
      <HeaderComponent>
        {/* <NovaCampanha UserID={UserID}/> */}
        <ComponentCadastro Titulo="Cadastrar campanha" Funcao={New} />
        {loading && <LoadingOverlay />}{" "}
        <Table
          columns={columns}
          data={Dados}
          codigo={"id"}
          //   func={AlterarDadosCliente}
        />
        {ModalCampanha ? (
          <>
            <ShowModal closeButton={() => setModalCampanha(false)}>
              <NovaCampanha
                UserID={UserID}
                Close={Close}
                DadosCopia={DadosCopia}
              />
            </ShowModal>
          </>
        ) : null}
      </HeaderComponent>
    </>
  );
}
