import { API } from "../../controller/api/api";
import { HeaderComponent } from "../../component/header";
import { ComponentCadastro } from "../../component/header/cadastro";
import { useEffect, useMemo, useState } from "react";
import {
  NotificaWarning,
  PrimeiraMaiuscula,
  SwitchPadraoTabela,
} from "../../controller/util";
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import { FaPen } from "react-icons/fa";
import Table from "../../component/tabela";
import { ModalGravarDados } from "../../component/gravar";
import LoadingOverlay from "../../component/load";
import { MascaraNumerica, RemoveMascara } from "../../controller/data";

let DadosEstatico = [];
export function PageTipoPagamento() {
  const [Dados, setDados] = useState([]);
  const [loading, setLoading] = useState(false);

  const [isCadastro, setIsCadastro] = useState(false);
  const [Codigo, setCodigo] = useState(0);
  const [Descricao, setDescricao] = useState("");
  const [Movimentacao, setMovimentacao] = useState(1);
  const [Troco, setTroco] = useState(0);
  const [Ativo, setAtivo] = useState(0);
  const [TaxaTP, setTaxaTP] = useState(0);

  const columns = useMemo(
    () => [
      {
        Header: "Nome",
        accessor: "descricao",
        size: "100px",
        format: PrimeiraMaiuscula,
      },
      {
        Header: "Movimentação",
        accessor: "movimentacao",
        size: "100px",
        textAlign: "center",
        format: PintaValores,
      },
      {
        Header: "Troco",
        accessor: "trocoDelivery",
        size: "100px",
        format: SwitchPadraoTabela,
      },
      {
        Header: "Ativo",
        accessor: "ativo",
        size: "100px",
        format: SwitchPadraoTabela,
      },
      {
        Header: "Alterar",
        size: "50px",
        component: (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <IconButton color="primary">
              <FaPen />
            </IconButton>
          </div>
        ),
        func: AlterarDados,
      },
    ],
    []
  );

  function PintaValores(valor, dataFiltro) {
    return <>{valor == 2 ? "Fiado" : "Á Vista"}</>;
  }

  function AlterarDados(dados) {
    const objetoEncontrado = DadosEstatico.find(
      (objeto) => objeto.codigo === dados
    );

    setCodigo(objetoEncontrado.codigo);
    setDescricao(objetoEncontrado.descricao);
    setMovimentacao(objetoEncontrado.movimentacao == 2 ? 2 : 1);
    setTroco(objetoEncontrado.trocoDelivery == 1 ? true : false);
    setAtivo(objetoEncontrado.ativo == 1 ? true : false);
    setTaxaTP(
      objetoEncontrado.taxa ? MascaraNumerica(objetoEncontrado.taxa) : 0
    );
    setIsCadastro(true);
  }

  useEffect(() => {
    BuscaDados();
  }, []);

  function BuscaDados() {
    setIsCadastro(false);
    API.get("v1/consulta/todos/tipo_pagamento").then(function (data) {
      setDados(data.data);
      DadosEstatico = data.data;
      setLoading(false);
    });
  }

  function Add() {
    setCodigo(0);
    setDescricao("");
    setMovimentacao(1);
    setTroco(false);
    setAtivo(true);
    setIsCadastro(true);
  }

  function SalvarDados() {
    if (Descricao.length == 0) {
      NotificaWarning("Descrição não informada!");
      return false;
    }

    const body = {
      tabela: "tipo_pagamento",
      campo: "codigo",
      valor: Codigo,
      campos: [
        { campo: "descricao", valor: Descricao },
        { campo: "movimentacao", valor: Movimentacao },
        { campo: "troco_delivery", valor: Troco ? 1 : 0 },
        {
          campo: "taxa",
          valor: TaxaTP ? parseFloat(RemoveMascara(TaxaTP)) : 0,
        },
        { campo: "ativo", valor: Ativo ? 1 : 0 },
        { campo: "modificado_site", valor: 0 },
      ],
    };
    console.log(body);
    setLoading(true);
    API.post("v2/cadastro/geral", body).then(function (response) {
      BuscaDados();
    });
  }

  return (
    <>
      <HeaderComponent>
        {loading && <LoadingOverlay />}{" "}
        <ComponentCadastro Titulo="Forma de Pagamento" Funcao={Add} />
        <Table columns={columns} data={Dados} codigo={"codigo"} />
        {isCadastro ? (
          <>
            <ModalGravarDados
              Descricao={Codigo == 0 ? "Cadastro" : "Alteração"}
              closeButton={() => setIsCadastro(false)}
              Funcao={SalvarDados}
            >
              <TextField
                id="descricao"
                label="Descrição"
                size="small"
                sx={{ width: "100%", marginBottom: "15px" }}
                value={PrimeiraMaiuscula(Descricao)}
                onChange={(e) => setDescricao(e.target.value)}
              />
              <FormControl fullWidth>
                <InputLabel id="movimentacao">Movimentação</InputLabel>
                <Select
                  labelId="movimentacao"
                  id="movimentacao"
                  value={Movimentacao}
                  label="Movimentação"
                  onChange={(e) => setMovimentacao(e.target.value)}
                  sx={{ width: "100%", marginBottom: "15px" }}
                  size="small"
                >
                  <MenuItem value={1}>Á Vista</MenuItem>
                  <MenuItem value={2}>Fiado</MenuItem>
                </Select>
              </FormControl>

              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={Troco}
                      onChange={(e) => setTroco(!Troco)}
                    />
                  }
                  label="Troco"
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={Ativo}
                      onChange={(e) => setAtivo(!Ativo)}
                    />
                  }
                  label="Ativo"
                />
              </FormGroup>
              <TextField
                id="taxa"
                label="Taxa Transação"
                size="small"
                sx={{ width: "100%", marginBottom: "15px" }}
                value={TaxaTP}
                onChange={(e) => setTaxaTP(e.target.value)}
                onBlur={(e) =>
                  setTaxaTP(
                    MascaraNumerica(parseFloat(RemoveMascara(e.target.value)))
                  )
                }
              />
            </ModalGravarDados>
          </>
        ) : null}
      </HeaderComponent>
    </>
  );
}
