import { useEffect, useState } from "react";
import { API, GetParametros } from "../../../../../controller/api/api";
import { Button, InputAdornment, TextField } from "@mui/material";
import {
  MascaraMonetaria,
  MascaraNumericaTresCasas,
  RemoveMascara,
} from "../../../../../controller/data";
import {
  Parametro,
  ParametroConfiguracao,
} from "../../../../../controller/parametros";
import { FaCheckCircle } from "react-icons/fa";
import { Notifica } from "../../../../../controller/util";

export function CustoMercadoriaVendida({
  codigo,
  ficha,
  setBodyCMV,
  ValorVenda,
}) {
  const [Custo, setCusto] = useState(0);
  const [CustoIndireto, setCustoIndireto] = useState(0);
  const [PercentualInposto, setPercentualInposto] = useState(0);
  const [PercentualCartao, setPercentualCartao] = useState(0);
  const [PercentualLucro, setPercentualLucro] = useState(50);
  const [PercentualiFood, setPercentualiFood] = useState(
    Parametro("ifoodPercentual")
  );

  const [inputCusto, setInputCusto] = useState("0.000");
  const [inputCustoIndireto, setInputCustoIndireto] = useState(CustoIndireto);
  const [inputPercentualInposto, setInputPercentualInposto] =
    useState(PercentualInposto);
  const [inputPercentualCartao, setInputPercentualCartao] =
    useState(PercentualCartao);
  const [inputPercentualLucro, setInputPercentualLucro] =
    useState(PercentualLucro);

  // Atualiza o custo dos ingredientes e os inputs
  useEffect(() => {
    console.log(codigo);
    if (codigo > 0) {
      API.get(`/v2/cmv/${codigo}`).then((response) => {
        console.log(response.data[0]);
        let result = response.data[0];
        if (result) {
          console.log(result.custoIngrediente);
          //setCusto(result.custoIngrediente);
          setCustoIndireto(result.custoIndiretos);
          setPercentualInposto(result.percentualImposto);
          setPercentualCartao(result.percentualCartao);
          setPercentualLucro(result.percentualLucro);
          setPercentualLucro(result.percentualLucro);

          //setInputCusto(result.custoIngrediente);
          setInputCustoIndireto(result.custoIndiretos);
          setInputPercentualInposto(result.percentualImposto);
          setInputPercentualCartao(result.percentualCartao);
          setInputPercentualLucro(result.percentualLucro);
        }
      });
    }
  }, [codigo]); // Recalcula sempre que os dados da ficha mudarem

  // Atualiza o custo dos ingredientes e os inputs
  useEffect(() => {
    const custoAtualizado = CustoIngredientes();
    setCusto(custoAtualizado);
    setInputCusto(MascaraNumericaTresCasas(custoAtualizado));
  }, [ficha]); // Recalcula sempre que os dados da ficha mudarem

  useEffect(() => {
    let body = {
      produto: codigo,
      custoIngrediente: CustoIngredientes(),
      custoIndireto: parseFloat(CustoIndireto),
      percentualImposto: parseFloat(RemoveMascara(PercentualInposto)),
      percentualCartao: parseFloat(RemoveMascara(PercentualCartao)),
      percentualiFood: parseFloat(PercentualiFood),
      percentualLucro: parseFloat(RemoveMascara(PercentualLucro)),
      valorImposto: ValorVendaImposto(),
      valorCartao: ValorVendaCartao(),
      valoriFood: PrecoSugeridoiFood(),
      valorLucro: CustoComLucro(),
      precoSugerido: PrecoSugerido(),
    };

    setBodyCMV(body);
  }, [CustoIndireto, PercentualInposto, PercentualCartao, PercentualLucro]);

  function CustoIngredientes() {
    const totalCusto = ficha.reduce((acc, item) => {
      return acc + item.custo * item.quantidade;
    }, 0);
    // console.log(totalCusto);
    return totalCusto;
  }

  function CustoBruto() {
    return RemoveMascara(Custo) + RemoveMascara(CustoIndireto);
  }

  function CustoComLucro() {
    return (CustoBruto() * RemoveMascara(PercentualLucro)) / 100;
  }

  function PrecoVenda() {
    return CustoBruto() + CustoComLucro();
  }

  function ValorVendaCartao() {
    return (PrecoVenda() * RemoveMascara(PercentualCartao)) / 100;
  }

  function ValorVendaImposto() {
    return (
      ((PrecoVenda() + PrecoVenda()) * RemoveMascara(PercentualInposto)) / 100
    );
  }

  function PrecoSugerido() {
    return PrecoVenda() + ValorVendaImposto() + ValorVendaCartao();
  }

  function PrecoSugeridoiFood() {
    return (
      PrecoSugerido() + (PrecoSugerido() * parseFloat(PercentualiFood)) / 100
    );
  }

  function AplicarPreco() {
    ValorVenda(PrecoSugerido());
    Notifica("Preço foi aplicado, porem deve-se salvar o produto!");
  }

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          gap: "10px",
        }}
      >
        <TextField
          label={"Custo Ingredientes"}
          size="small"
          style={{ marginBottom: "10px", width: "100%" }}
          value={inputCusto}
          disabled="true"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">R$</InputAdornment>
            ),
          }}
        />
        <TextField
          label={"Custo Indiretos"}
          size="small"
          value={inputCustoIndireto}
          onChange={(e) => [
            setInputCustoIndireto(e.target.value),
            setCustoIndireto(RemoveMascara(e.target.value)),
          ]}
          onBlur={() =>
            setCustoIndireto(
              MascaraNumericaTresCasas(RemoveMascara(inputCustoIndireto))
            )
          }
          style={{ marginBottom: "10px", width: "100%" }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">R$</InputAdornment>
            ),
          }}
        />
      </div>

      <div
        style={{
          width: "100%",
          display: "flex",
          gap: "10px",
        }}
      >
        <TextField
          label={"% Imposto"}
          size="small"
          style={{ marginBottom: "10px", width: "100%" }}
          value={inputPercentualInposto}
          onChange={(e) => [
            setInputPercentualInposto(e.target.value),
            setPercentualInposto(RemoveMascara(e.target.value)),
          ]}
          onBlur={() =>
            setPercentualInposto(
              MascaraNumericaTresCasas(RemoveMascara(inputPercentualInposto))
            )
          }
          InputProps={{
            startAdornment: <InputAdornment position="start">%</InputAdornment>,
          }}
        />
        <TextField
          label={"Taxa Cartão"}
          size="small"
          style={{ marginBottom: "10px", width: "100%" }}
          value={inputPercentualCartao}
          onChange={(e) => setInputPercentualCartao(e.target.value)}
          onBlur={() =>
            setPercentualCartao(
              MascaraNumericaTresCasas(RemoveMascara(inputPercentualCartao))
            )
          }
          InputProps={{
            startAdornment: <InputAdornment position="start">%</InputAdornment>,
          }}
        />

        <TextField
          label={"Taxa iFood"}
          size="small"
          style={{ marginBottom: "10px", width: "100%" }}
          value={MascaraNumericaTresCasas(PercentualiFood)}
          InputProps={{
            startAdornment: <InputAdornment position="start">%</InputAdornment>,
          }}
        />

        <TextField
          label={"% Lucro"}
          size="small"
          style={{ marginBottom: "10px", width: "100%" }}
          value={inputPercentualLucro}
          onChange={(e) => [
            setInputPercentualLucro(e.target.value),
            setPercentualLucro(RemoveMascara(e.target.value)),
          ]}
          onBlur={() =>
            setPercentualLucro(
              MascaraNumericaTresCasas(RemoveMascara(inputPercentualLucro))
            )
          }
          InputProps={{
            startAdornment: <InputAdornment position="start">%</InputAdornment>,
          }}
        />
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          gap: "10px",
        }}
      >
        <TextField
          label={"Imposto"}
          size="small"
          style={{ marginBottom: "10px", width: "100%" }}
          value={MascaraNumericaTresCasas(ValorVendaImposto())}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">R$</InputAdornment>
            ),
          }}
        />
        <TextField
          label={"Cartão"}
          size="small"
          style={{ marginBottom: "10px", width: "100%" }}
          value={MascaraNumericaTresCasas(ValorVendaCartao())}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">R$</InputAdornment>
            ),
          }}
        />

        <TextField
          label={"iFood"}
          size="small"
          style={{ marginBottom: "10px", width: "100%" }}
          value={MascaraNumericaTresCasas(PrecoSugeridoiFood())}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">R$</InputAdornment>
            ),
          }}
        />

        <TextField
          label={"Lucro"}
          size="small"
          style={{ marginBottom: "10px", width: "100%" }}
          value={MascaraNumericaTresCasas(CustoComLucro())}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">R$</InputAdornment>
            ),
          }}
        />
      </div>
      <div style={{ display: "flex", gap: "10px" }}>
        <TextField
          label={"Preço Sugerido"}
          size="small"
          style={{ marginBottom: "10px", width: "100%" }}
          value={MascaraNumericaTresCasas(PrecoSugerido())}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">R$</InputAdornment>
            ),
          }}
        />

        <Button
          variant="outlined"
          style={{ width: "150px", height: "100%" }}
          startIcon={<FaCheckCircle />}
          onClick={AplicarPreco}
        >
          Aplicar
        </Button>
      </div>
    </>
  );
}
