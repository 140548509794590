import { API } from "../api/api";
import { BuscaLocal, GravaLocal } from "../storage";

const CACHE_KEY = "whatsapp_config"; // Chave para armazenar os dados no localStorage
const CACHE_EXPIRATION_TIME = 5 * 60 * 1000; // Tempo de expiração do cache em milissegundos (5 minutos)

export async function ParametroConfiguracao(name) {
  let dados = BuscaLocal("parametros");
  console.log(dados[name]);
  return dados[name];

  // // let CACHE_EXPIRATION_TIME = CACHE_EXPIRATION_TIME;
  // // Verifica se os dados estão armazenados no cache e se ainda estão dentro do tempo de validade
  // const cachedData = JSON.parse(localStorage.getItem(CACHE_KEY));
  // if (cachedData && Date.now() - cachedData.timestamp < CACHE_EXPIRATION_TIME) {
  //   return cachedData.data[name] ? cachedData.data[name] : 0;
  // }

  // // Caso contrário, realiza a consulta e armazena os novos dados no cache
  // const response = await API.get(`v2/parametros`);
  // const data = response.data[0];
  // if (data !== undefined) {
  //   const newData = data[name] ? data[name] : 0;
  //   const newCachedData = { data, timestamp: Date.now() };
  //   GravaLocal(CACHE_KEY, JSON.stringify(newCachedData));
  //   return newData;
  // }
}

export function PermissaoAcesso(name) {
  let data = JSON.parse(BuscaLocal("user"));

  if (!data) {
    return false;
  }

  if (name == "garcom") {
    try {
      if (data[0][name] == undefined) {
        return true;
      }
    } catch {}
  }

  return parseInt(data[0][name]) == 1;
}

export function Parametro(name) {
  let dados = BuscaLocal("parametros");
  return dados[name];
}
