import { useState, useEffect } from "react";
import {
  API,
  GetParametros,
  GooPedirAPI,
} from "../../../../controller/api/api";
import { PrimeiraMaiuscula } from "../../../../controller/util";
import { MascaraMonetaria, RemoveMascara } from "../../../../controller/data";
import { Button } from "@mui/material";

function isDatePast(dueDateString) {
  if (dueDateString == null) {
    return "";
  }
  // Split the date string into day, month, year
  const [day, month, year] = dueDateString.split("/");

  // Create a date object with the given date
  const dueDate = new Date(year, month - 1, day);

  // Get current date
  const currentDate = new Date();

  // Clear time part for accurate comparison
  currentDate.setHours(0, 0, 0, 0);

  // Return true if current date is past the due date, false otherwise
  return currentDate > dueDate;
}

function abrirEmNovaAba(url) {
  var win = window.open(url, "_blank");
  win.focus();
}

export function CardFatura() {
  const [Faturas, setFaturas] = useState([]);
  const [Tipo, setTipo] = useState(1);

  useEffect(() => {
    const fetchData = async () => {
      API.get(`v2/user/id`).then((response) => {
        if (parseInt(response.data) > 0) {
          GooPedirAPI.get("/ws/v1/faturas/" + response.data).then(
            (response) => {
              if (response.data != null) {
                let data = response.data;

                setFaturas(data.filter((item) => parseInt(item.status) !== 5));
                console.log(response.data);
              }
            }
          );
        }
      });
    };
    GetParametros();
    fetchData();
  }, []);

  const Card = ({ data, index, tipo }) => {
    const [isHovered, setIsHovered] = useState(false);

    function DescricaoStatus(status) {
      if (status == 5) {
        return "Cancelado";
      }

      if (status == 2) {
        return "Pago";
      }

      if (isDatePast(data.vencimento)) {
        return "Vencida";
      } else {
        return "Não Vencida";
      }
    }

    const handleMouseEnter = () => {
      setIsHovered(true);
    };

    const handleMouseLeave = () => {
      setIsHovered(false);
    };

    function CorStatus(status) {
      if (status == 5) {
        return "rgba(204,0,0,0.9)";
      }

      if (status == 2) {
        return "rgba(49,84,31,0.8)";
      }

      if (isDatePast(data.vencimento)) {
        return "rgba(60,10,38,0.8)";
      } else {
        return "rgba(22,83,126,0.8)";
      }
    }

    const linkStyle = {
      cursor: "pointer",
      color: "black", // Cor padrão
      transition: "color 0.3s ease", // Transição suave para a cor
    };

    const hoverColor = {
      color: "rgb(168, 0, 28)", // Cor ao passar o mouse
    };

    function TipoAtual() {
      if (data.status == 5) {
        return "Cancelado";
      }

      if (data.status == 2) {
        return 3;
      }

      if (isDatePast(data.vencimento)) {
        return 1;
      } else {
        return 2;
      }

      return 1, 2, 3;
    }

    function DeveMostrar() {
      if (tipo == 4) {
        return true;
      }
      if (TipoAtual() == tipo) {
        return true;
      } else {
        return false;
      }
    }

    return (
      <>
        {DeveMostrar() ? (
          <div
            key={index}
            style={{
              width: "350px",
              border: "1px solid #ddd",
              borderRadius: "10px",
              marginRight: "15px",
              marginBottom: "15px",
              overflow: "hidden",
              boxShadow: isHovered
                ? "0 4px 8px rgba(0, 0, 0, 0.2)" // Sombra mais intensa quando o mouse está sobre o card
                : "0 2px 4px rgba(0, 0, 0, 0.1)", // Sombra leve
              transition: "transform 0.3s ease, box-shadow 0.3s ease", // Transição suave para hover
              cursor: "pointer",
              transform: isHovered ? "scale(1.02)" : "scale(1)", // Leve zoom ao passar o mouse
            }}
            onClick={() => abrirEmNovaAba(data.link)}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <div
              style={{
                width: "100%",
                borderLeft: `10px solid ${CorStatus(data.status)}`,
                padding: "20px",
                backgroundColor: "#f8f9fa", // Fundo suave para contraste
                borderTopLeftRadius: "10px",
                borderBottomLeftRadius: "10px",
              }}
            >
              <h4
                style={{
                  margin: "0 0 10px 0",
                  color: "#333",
                  fontSize: "1rem",
                }}
              >
                Vencimento: {data.vencimento}
              </h4>
              <h5
                style={{
                  margin: "0 0 10px 0",
                  color: "#555",
                  fontSize: "0.9rem",
                }}
              >
                Status: {DescricaoStatus(data.status)}
              </h5>
              <h5
                style={{
                  margin: "0 0 20px 0",
                  color: "#555",
                  fontSize: "0.9rem",
                }}
              >
                Total: {MascaraMonetaria(RemoveMascara(data.total))}
              </h5>
              <h6>
                <strong
                  style={
                    isHovered ? { ...linkStyle, ...hoverColor } : linkStyle
                  }
                >
                  Clique aqui para visualizar.
                </strong>
              </h6>
            </div>
          </div>
        ) : null}
      </>
    );
  };

  return (
    <>
      {/* //GooPedirAPI */}
      {Faturas?.length != 0 ? (
        <>
          <div style={{ padding: "10px" }}>
            <strong>{PrimeiraMaiuscula(Faturas[0].cliente)}</strong> abaixo suas
            faturas.
            <div
              style={{ display: "flex", gap: "10px", justifyContent: "end" }}
            >
              <Button
                variant={Tipo == 1 ? "contained" : "outlined"}
                color="primary"
                onClick={() => setTipo(1)}
              >
                Vencidas
              </Button>
              <Button
                variant={Tipo == 2 ? "contained" : "outlined"}
                color="blue"
                onClick={() => setTipo(2)}
              >
                Não Vencidas
              </Button>

              <Button
                variant={Tipo == 3 ? "contained" : "outlined"}
                color="success"
                onClick={() => setTipo(3)}
              >
                Pago
              </Button>

              <Button
                variant={Tipo == 4 ? "contained" : "outlined"}
                color="black"
                onClick={() => setTipo(4)}
              >
                Todas
              </Button>
            </div>
            <div
              style={{
                display: "flex",
                overflowX: "auto",
                width: "100%",
                marginTop: "15px",
              }}
            >
              {Faturas.map((faturas, index) => (
                <Card key={index} data={faturas} index={index} tipo={Tipo} />
              ))}
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}
