import React, { useState, useEffect } from "react";
import {
  MascaraMonetaria,
  MascaraNumericaTresCasas,
  RemoveMascara,
} from "../../../controller/data";
import { PrimeiraMaiuscula } from "../../../controller/util";

// Modal Component
export const EconomyModal = ({ data, onClose }) => {
  const [totalEconomy, setTotalEconomy] = useState(0);
  const [totalPercentageEconomy, setTotalPercentageEconomy] = useState(0);

  // Função para calcular a economia total e percentual de cada item
  const calculateEconomy = (item) => {
    const totalEconomy = (item.old - item.new) * item.qtd;
    const percentageEconomy = ((item.old - item.new) / item.old) * 100;
    return {
      totalEconomy: totalEconomy.toFixed(2), // Limita a 2 casas decimais
      percentageEconomy: percentageEconomy.toFixed(2),
    };
  };

  // Calcula o total de economia e percentual geral
  useEffect(() => {
    let totalEconomyValue = 0;
    let totalOldValue = 0;
    let totalNewValue = 0;

    data.forEach((item) => {
      totalEconomyValue += (item.old - item.new) * item.qtd;
      totalOldValue += item.old * item.qtd;
      totalNewValue += item.new * item.qtd;
    });

    const totalPercentage =
      ((totalOldValue - totalNewValue) / totalOldValue) * 100;

    setTotalEconomy(totalEconomyValue.toFixed(2));
    setTotalPercentageEconomy(totalPercentage.toFixed(2));
  }, [data]);

  return (
    <div style={styles.modalBackdrop}>
      <div style={styles.modalContent}>
        <button style={styles.closeButton} onClick={onClose}>
          X
        </button>

        {/* Cabeçalho fixo */}
        <div style={styles.header}>
          <h1>Economia de Ingredientes e Produtos</h1>
        </div>

        {/* Conteúdo rolável */}
        <div style={styles.scrollableContent}>
          {data.map((item) => {
            const { totalEconomy, percentageEconomy } = calculateEconomy(item);
            return (
              <div key={item.id} style={styles.item}>
                <strong>
                  {PrimeiraMaiuscula(item.name)} ({item.type})
                </strong>
                <p>
                  Economia: {MascaraMonetaria(RemoveMascara(totalEconomy))}
                  <br />
                  Percentual:{" "}
                  {MascaraNumericaTresCasas(RemoveMascara(percentageEconomy))}%
                </p>
              </div>
            );
          })}
        </div>

        {/* Rodapé fixo */}
        <div style={styles.footer}>
          <h3>Economizado: {MascaraMonetaria(RemoveMascara(totalEconomy))}</h3>
          <h3>
            Percentual Economizado:{" "}
            {MascaraNumericaTresCasas(RemoveMascara(totalPercentageEconomy))}%
          </h3>
        </div>
      </div>
    </div>
  );
};

// Estilos puros
const styles = {
  modalBackdrop: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  modalContent: {
    backgroundColor: "white",
    padding: "20px",
    borderRadius: "8px",
    width: "500px",
    maxHeight: "80vh",
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  closeButton: {
    position: "absolute",
    top: "10px",
    right: "10px",
    background: "none",
    border: "none",
    fontSize: "16px",
    cursor: "pointer",
  },
  header: {
    flexShrink: 0, // Mantém o cabeçalho fixo
    // borderBottom: "1px solid #ddd",
    paddingBottom: "10px",
  },
  scrollableContent: {
    flexGrow: 1, // O conteúdo rolável cresce para ocupar o espaço disponível
    overflowY: "auto", // Habilita o scroll
    margin: "10px 0",
  },
  footer: {
    flexShrink: 0, // Mantém o rodapé fixo
    // borderTop: "1px solid #ddd",
    paddingTop: "10px",
  },
  item: {
    padding: "10px 0",
    borderBottom: "1px solid #ddd",
  },
};
