import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, useMap } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-routing-machine";
import { GetDadosModulo } from "../../controller/modulos";
import axios from "axios";
import { PrimeiraMaiuscula } from "../../controller/util";
import { HeaderComponent } from "../../component/header";
import "./styles.css";
import {
  DataAtual,
  MascaraMonetaria,
  PrimeiroDiaDoMes,
} from "../../controller/data";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { ShowModal } from "../../component/modalPai";
import { InputPedido } from "../pedido/componente/input";
import { Avatar } from "@mui/material";

function CorStatus(status) {
  if (status === "Finalizado") return "rgba(38,153,49,0.7)";
  if (status === "Saiu Para Entrega") return "rgba(75,0,100,0.7)";
  if (status === "Novo Pedido" || status === "Em Produção")
    return "rgba(240,186,24,0.7)";
  if (status === "Cancelado") return "rgba(168,0,28,0.7)";
  if (status === "Pronto") return "rgba(38,153,49,0.7)";
  return "rgba(240,186,24,0.7)";
}

const createCustomIcon = (
  codigo,
  valor,
  nomeCliente,
  nomeOuFotoMotoboy,
  status
) => {
  let letraInicialMotoboy = "";
  let isUrl = false;
  let visivel = false;

  const primeiroNomeCliente = nomeCliente.split(" ")[0]; // Pega o primeiro nome do cliente
  // const primeiroNomeMotoboy = nomeOuFotoMotoboy.split(" ")[0]; // Pega o primeiro nome do motoboy, caso seja um nome

  try {
    letraInicialMotoboy = nomeOuFotoMotoboy.charAt(0).toUpperCase(); // Primeira letra do nome do motoboy
    isUrl =
      nomeOuFotoMotoboy.startsWith("http") ||
      nomeOuFotoMotoboy.startsWith("https"); // Verifica se é uma URL
    visivel = true;
  } catch (error) {
    letraInicialMotoboy = "";
  }

  return L.divIcon({
    className: "custom-icon",
    html: `
      <div style="background-color: ${CorStatus(
        status
      )}; color: black; padding: 5px; border-radius: 5px; text-align: center; display: flex; flex-direction: column; align-items: center;">
        ${
          visivel
            ? isUrl
              ? `<img src="${nomeOuFotoMotoboy}" alt="Motoboy" style="width: 40px; height: 40px; border-radius: 50%; margin-top: 5px;" />`
              : `<div 
                style="
                  background-color: #ff5722; 
                  color: white; 
                  width: 40px; 
                  height: 40px; 
                  border-radius: 50%; 
                  display: flex; 
                  justify-content: center; 
                  align-items: center; 
                  margin-top: 5px;
                "
              >
                <strong>${letraInicialMotoboy}</strong>
              </div>`
            : ""
        }
                <div style="display:flex; gap:10px;">
          <i class="fas fa-map-marker-alt"></i>
          <div style="margin-top: 0px;"><strong>${
            codigo + " - " + primeiroNomeCliente
          }</strong></div>
        </div>
        <div style="margin-top: 0px;"><strong> ${status}</strong></div>
      </div>
    `,
    iconSize: [150, 100], // Aumentei o tamanho do ícone para acomodar as informações
    iconAnchor: [75, 100],
  });
};

const createCustomIconMeu = () => {
  return L.divIcon({
    className: "custom-icon",
    html: `
      <div style="position: relative;">
        <img src="https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png" style="position: absolute; top: 0; left: 0;" />
      </div>
    `,
    iconSize: [25, 41],
    iconAnchor: [12.5, 41],
  });
};

const RouteDrawer = ({ pedidos, setKmTotal }) => {
  const map = useMap();

  useEffect(() => {
    if (pedidos.length > 0) {
      // Remove camadas de roteamento existentes
      map.eachLayer((layer) => {
        if (layer instanceof L.Routing.Control) {
          map.removeControl(layer);
        }
      });

      // Cria e adiciona uma nova camada de roteamento
      const routingControl = L.Routing.control({
        waypoints: [
          L.latLng(
            parseFloat(GetDadosModulo("latitude")),
            parseFloat(GetDadosModulo("longitude"))
          ),
          ...pedidos.map((pedido) =>
            L.latLng(parseFloat(pedido.lat), parseFloat(pedido.lgn))
          ),
        ],
        routeWhileDragging: true,
        lineOptions: {
          styles: [{ color: "#A8001C", weight: 6, opacity: 0.7 }],
        },
        createMarker: () => null, // Não cria marcadores nos pontos de rota
        showAlternatives: false, // Desativa a exibição de rotas alternativas
        plan: undefined, // Desativa o plano de rotas
        routeMarker: false, // Desativa os marcadores de rota
        itinerary: false, // Desativa o itinerário
        routes: [], // Inicia sem rotas
      })
        .on("routesfound", (e) => {
          const route = e.routes[0];
          const distanceKm = route.summary.totalDistance / 1000; // Converte para km
          setKmTotal(distanceKm.toFixed(2)); // Define a distância total (em km)
        })
        .addTo(map);

      return () => {
        if (routingControl) {
          map.removeControl(routingControl);
        }
      };
    }
  }, [pedidos, map, setKmTotal]);

  return null;
};

const PedidoMap = () => {
  const [pedidos, setPedidos] = useState([]);
  const [kmTotal, setKmTotal] = useState(0);
  const [ModalFiltro, setModalFiltro] = useState(false);
  const [DataInicial, setDataInicial] = useState(DataAtual());
  const [DataFinal, setDataFinal] = useState(DataAtual());

  useEffect(() => {
    BuscarPedidos();
    // Configura o intervalo para atualizar os pedidos a cada 15 segundos
    const intervalId = setInterval(BuscarPedidos, 30000);

    // Limpa o intervalo quando o componente for desmontado
    return () => clearInterval(intervalId);
  }, []);

  function BuscarPedidos() {
    axios
      .get(
        `https://ws.goopedir.com/localizacao/index.php?codigo=${GetDadosModulo(
          "usuario"
        )}&data_inicio=${DataInicial}&data_fim=${DataFinal}`
      )
      .then((response) => setPedidos(response.data))
      .catch((error) => console.error("Erro ao buscar pedidos:", error));
  }

  function AlteraValoresInput(type, valor) {
    if (type == "Data Inicial") {
      setDataInicial(valor);
      // BuscarDados();
    }
    if (type == "Data Final") {
      setDataFinal(valor);
      // BuscarDados();
    }
  }

  return (
    <HeaderComponent ModalFiltro={() => setModalFiltro(true)}>
      <h1>
        <b>Controle de Rotas de Pedidos</b> <span>{kmTotal}km</span>
      </h1>

      <MapContainer
        center={[
          parseFloat(GetDadosModulo("latitude")),
          parseFloat(GetDadosModulo("longitude")),
        ]}
        zoom={8}
        style={{ height: "89vh", width: "100%", zIndex: 0 }}
        zIndexOffset={60}
      >
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <Marker
          key={0}
          position={[
            parseFloat(GetDadosModulo("latitude")),
            parseFloat(GetDadosModulo("longitude")),
          ]}
          icon={createCustomIconMeu()}
          zIndexOffset={80}
        />

        {pedidos.map((pedido) => (
          <Marker
            key={pedido.id}
            position={[parseFloat(pedido.lat), parseFloat(pedido.lgn)]}
            icon={createCustomIcon(
              pedido.codigo_pedido,
              parseFloat(pedido.sub_total),
              PrimeiraMaiuscula(pedido.nome),
              pedido.motoboy_foto ? pedido.motoboy_foto : pedido.motoboy,
              pedido.status_atual
            )}
          />
        ))}

        {/* Adiciona a rota entre os marcadores */}
        <RouteDrawer pedidos={pedidos} setKmTotal={setKmTotal} />
      </MapContainer>

      {ModalFiltro ? (
        <>
          <ShowModal closeButton={() => setModalFiltro(false)}>
            <div style={{ width: "250px", paddingTop: "20px" }}>
              <InputPedido
                Tipo="date"
                Descricao="Data Inicial"
                Retorno={AlteraValoresInput}
                Valor={DataInicial}
                sx={{ marginBottom: "15px" }}
              />

              <InputPedido
                Tipo="date"
                Descricao="Data Final"
                Retorno={AlteraValoresInput}
                Valor={DataFinal}
                sx={{ marginBottom: "15px" }}
              />
              {/*
                <FormControl fullWidth>
                  <InputLabel id="tipo-grafico">Tipo do Gráfico</InputLabel>
                  <Select
                    value={TipoGrafico}
                    id="tipo-grafico"
                    label="Tipo do Gráfico"
                    onChange={(e) => setTipoGrafico(e.target.value)}
                    size="small"
                  >
                    <MenuItem value={0}>Unidade</MenuItem>
                    <MenuItem value={1}>Valor</MenuItem>
                  </Select>
                </FormControl>
                <Toggle
                  Titulo={`Ocultar Relatório Venda`}
                  Change={() => [
                    setOcutarRelatorio(!OcutarRelatorio),
                    GravaLocal("OcutarRelatorio", !OcutarRelatorio),
                  ]}
                  Checked={OcutarRelatorio}
                /> */}
            </div>
          </ShowModal>
        </>
      ) : null}
    </HeaderComponent>
  );
};

export default PedidoMap;
