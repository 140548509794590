import { HeaderComponent } from "../../component/header";
import { CardMesa } from "../../component/cardmesa";
import { useState, useEffect, useRef } from "react";
import { API } from "../../controller/api/api";
import { GravaLocal } from "../../controller/storage";
import { ParametroConfiguracao } from "../../controller/parametros";
import { PrimeiraMaiuscula } from "../../controller/util";
import { InputPedido } from "../pedido/componente/input";
import { Button } from "../../component/button";
import { FaStickyNote } from "react-icons/fa";
import { ComponentComanda } from "../../component/comanda";
import { isMobileDevice } from "../../controller/mobile";

export function SalaoPage() {
  const [mesa, setMesa] = useState([]);
  const [TaxaServico, setTaxaServico] = useState(0);
  const [UsaComanda, setUsaComanda] = useState(2);
  const [MostraComanda, setMostraComanda] = useState(false);

  useEffect(() => {
    GravaLocal("VOLTAR", "/principal");
    Parametros();
  }, []);
  let intervalId;
  useEffect(() => {
    console.log(UsaComanda);
    if (UsaComanda < 2) {
      BuscaDados();

      if (UsaComanda == 1) {
        intervalId = setInterval(() => {
          BuscaDados();
        }, 3000);
      }
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [UsaComanda]);

  function BuscaDados() {
    API.get("v1/mesas/").then(function (dados) {
      if (dados.data != mesa) {
        setMesa(dados.data);
      }
    });
  }

  async function Parametros() {
    setTaxaServico(await ParametroConfiguracao("taxaServicoPedido"));
    setUsaComanda(await ParametroConfiguracao("comanda"));
  }

  const cardGridStyle = {
    display: "grid",
    justifyContent: "center",
    gridTemplateColumns: isMobileDevice()
      ? "repeat(auto-fit, calc(50% - 5px))"
      : "repeat(auto-fit, 150px)",
    gridGap: "10px", // Espaço de 10 pixels entre as células
  };

  return (
    <>
      <HeaderComponent>
        {UsaComanda == 1 ? (
          <>
            <div
              style={{
                display: isMobileDevice() ? "" : "flex",
                gap: "15px",
                marginBottom: "10px",
              }}
            >
              <div
                style={{
                  width: isMobileDevice() ? "100%" : "80%",
                  marginBottom: isMobileDevice() ? "15px" : "0",
                }}
              >
                <InputPedido />
              </div>
              <div
                style={{
                  width: isMobileDevice() ? "100%" : "20%",
                  marginBottom: isMobileDevice() ? "15px" : "0",
                }}
              >
                <Button
                  borderColor="black"
                  icon={<FaStickyNote />}
                  descricao={"Comanda"}
                  fontColor={"black"}
                  heigth={"100%"}
                  evento={() => setMostraComanda(true)}
                />
              </div>
            </div>
          </>
        ) : null}
        <div
          style={{
            ...cardGridStyle,
          }}
        >
          {mesa.map((dadosMesa, index) => {
            if (UsaComanda === 1 && dadosMesa.totMesa <= 0) {
              // Se UsaComanda é 1 e totMesa é <= 0, não renderize o CardMesa
              return null;
            }

            return (
              <CardMesa
                Heigth={isMobileDevice() ? "calc(50%)" : "150px"}
                key={index}
                id={dadosMesa.idMesa}
                descricao={
                  dadosMesa.descricao
                    ? PrimeiraMaiuscula(dadosMesa.descricao)
                    : PrimeiraMaiuscula(dadosMesa.descricao1)
                }
                numero={dadosMesa.nrMesa}
                pedido={dadosMesa.selecionada}
                total={dadosMesa.totMesa}
                tempo={dadosMesa.tempo}
                servico={TaxaServico}
                status={dadosMesa.stsMesa}
              ></CardMesa>
            );
          })}
        </div>
        {MostraComanda ? (
          <ComponentComanda
            comanda={""}
            close={() => setMostraComanda(false)}
          />
        ) : null}
      </HeaderComponent>
    </>
  );
}
