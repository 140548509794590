import { useEffect, useState } from "react";
import { InputPedido } from "../../../pedido/componente/input";
import { API } from "../../../../controller/api/api";
import {
  MonitorSmall,
  Notifica,
  NotificaWarning,
  PrimeiraMaiuscula,
} from "../../../../controller/util";
import { StatusComponent } from "../tabela/item/status";
import { Button } from "../../../../component/button";
import { Selected } from "../../../../component/selected";
import { BiCloset } from "react-icons/bi";
import axios from "axios";
import {
  ApagaLocal,
  BuscaLocal,
  GravaLocal,
} from "../../../../controller/storage";
import {
  MascaraInteiro,
  MascaraMonetaria,
  MascaraNumerica,
  RemoveMascara,
} from "../../../../controller/data";
import { FichaTecnica } from "./ficha";
import Select from "react-select";
import { ComponenteEntradaSaidaEstoque } from "../../../../component/estoque/ModalEntradaSaida";
import { Toggle } from "../../../../component/toggle";
import { ComponentDiaSemana } from "./DiaSemana";
import LoadingOverlay from "../../../../component/load";
import { CopiaExtra } from "./CopiaExtra";
import { TextField } from "@mui/material";
import { AppCortar } from "../../../../component/cortarImage";
import { CustoMercadoriaVendida } from "./cmv";

const InputGroup = ({ label, value, name, selectedValue, onChange, svg }) => {
  return (
    <div
      style={{
        display: "grid",
        height: "90px",
        width: "90px",
        backgroundColor: "white",
        borderRadius: "5px",
        justifyContent: "center",
        marginRight: "12px",
      }}
    >
      <input
        type="radio"
        name={name}
        value={value}
        checked={value === selectedValue}
        onChange={onChange}
        style={{
          position: "position",
          height: "16px",
          width: "16px",
          justifySelf: "center",
          marginTop: "5px",
          cursor: "pointer",
        }}
      />
      {svg ? (
        <img
          src={`/img/${svg}.svg`}
          style={{
            position: "position",
            height: "16px",
            width: "16px",
            justifySelf: "center",
          }}
        />
      ) : null}
      <label style={{ fontSize: "0.75rem", marginTop: svg ? "" : "28px" }}>
        {label}
      </label>
    </div>
  );
};

let test64 = "";
export function NovoProduct({
  codigo,
  category,
  close,
  data,
  tipo,
  product,
  pizza,
  sabor,
}) {
  // console.log(data);
  const [Tipo, setTipo] = useState(tipo);
  const [loading, setloading] = useState(false);
  const [Aba, setAba] = useState(1);
  const [Category, setCategory] = useState([]);
  const [CategorySelected, setCategorySelected] = useState(category);
  const [NomeProduto, setNomeProduto] = useState("");
  const [DescricaoProduto, setDescricaoProduto] = useState("");
  const [DescTipoSabor, setDescTipoSabor] = useState({
    value: "Tradicional",
    label: "Tradicional",
  });
  const [urlProduct, setUrlProduct] = useState("./img/sem-foto.jpg");
  const [ModalImagem, setModalImagem] = useState(false);
  const [ImagemSelecionada, setImagemSelecionada] = useState("");
  const [Base64, setBase64] = useState("./img/sem-foto.jpg");
  const [ExternalCode, setExternalCode] = useState(0);
  const [selectUN, setselectUN] = useState({ value: "UN", label: "UN" });
  const [isExtra, setisExtra] = useState(false);
  const [Option, setOption] = useState([
    { value: "KG", label: "KG" },
    { value: "UN", label: "UN" },
    { value: "CX", label: "CX" },
  ]);

  const [OptionTipoSabor, setOptionTipoSabor] = useState([
    { value: "Tradicional", label: "Tradicional" },
    { value: "Especial", label: "Especial" },
    { value: "Doce", label: "Doce" },
    { value: "Zero Lactose", label: "Zero Lactose" },
    { value: "Sem Glúten", label: "Sem Glúten" },
  ]);

  //Aba 2

  const [Preco, setPreco] = useState(0);
  const [PrecoDesconto, setPrecoDesconto] = useState(0);
  const [PercentualDesconto, setPercentualDesconto] = useState(0);
  const [EstoqueAtual, setEstoqueAtual] = useState(0);
  const [EstoqueMinimo, setEstoqueMinimo] = useState(0);
  const [usaEstoque, setusaEstoque] = useState(false);
  const [TamanhoPizza, setTamanhoPizza] = useState([]);
  const [PontosFidelidade, setPontosFidelidade] = useState(0);
  const [isEntradaEstoque, setIsEntradaEstoque] = useState(false);
  const [AdicionalEntrega, setAdicionalEntrega] = useState(0);

  const [TipoSabor, setTipoSabor] = useState([]);
  const [TipoSaborSelecionado, setTipoSaborSelecionado] = useState(0);
  const [SaborAntigo, setSaborAntigo] = useState("");
  const [DisponivelDiasSemana, setDisponivelDiasSemana] = useState(false);
  const [Novidade, setNovidade] = useState(false);
  const [ApenasVB, setApenasVB] = useState(false);
  const [ApenasEntrega, setApenasEntrega] = useState(false);
  const [Segunda, setSegunda] = useState(1);
  const [Terca, setTerca] = useState(1);
  const [Quarta, setQuarta] = useState(1);
  const [Quinta, setQuinta] = useState(1);
  const [Sexta, setSexta] = useState(1);
  const [Sabado, setSabado] = useState(1);
  const [Domingo, setDomingo] = useState(1);

  const [UserID, setUserID] = useState(0);
  const [Pizza, setPizza] = useState(false);

  //Fiscal

  const [CodigoBarras, setCodigoBarras] = useState("");
  const [NCM, setNCM] = useState(0);
  const [CEST, setCEST] = useState(0);
  const [CFOP, setCFOP] = useState(0);
  const [CSTPIS, setCSTPIS] = useState(0);
  const [CSTIPI, setCSTIPI] = useState(0);
  const [CSTICMS, setCSTICMS] = useState(0);
  const [CSTCOFINS, setCSTCOFINS] = useState(0);
  const [CSOSN, setCSOSN] = useState(0);
  const [IPI, setIPI] = useState(0);
  const [ICMS, setICMS] = useState(0);
  const [PIS, setPIS] = useState(0);
  const [COFINS, setCOFINS] = useState(0);
  const [FRETE, setFRETE] = useState(0);

  //Fiscal
  const [DATACOFINS, setDATACOFINS] = useState([]);
  const [DATACSOSNICMS, setDATACSOSNICMS] = useState([]);
  const [DATACSTICMS, setDATACSTICMS] = useState([]);
  const [DATACSTIPI, setDATACSTIPI] = useState([]);
  const [DATACSTORIGEM, setDATACSTORIGEM] = useState([]);
  const [DATACSTPIS, setDATACSTPIS] = useState([]);
  const [DATANCM, setDATANCM] = useState([]);
  const [DATACFOP, setDATACFOP] = useState([]);

  const [DATAPRODESTOQUE, setDATAPRODESTOQUE] = useState([]);
  const [DATAFICHAINSULMO, setDATAFICHAINSULMO] = useState([]);

  const [ProdutoEstoque, setProdutoEstoque] = useState(0);

  const [selectedOption, setSelectedOption] = useState("0");

  const [fiscalCompleted, setFiscalCompleted] = useState(false);

  const APIFISCAL = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL_SERVER
      ? process.env.REACT_APP_BASE_URL_SERVER + "fiscal/"
      : document.querySelector('link[name="url-server"]').href + "fiscal/",
    headers: {
      "Content-Type": "application/json; text/html; charset=utf-8",
    },
  });

  function MovimentarEstoque() {
    setIsEntradaEstoque(true);
  }

  function FechaEstoque() {
    BuscaEstoque();
    setIsEntradaEstoque(false);
  }

  function BuscaEstoque() {
    API.get(`v2/estoque/produto/insulmo/${1}/${codigo}`).then(function (data) {
      if (data.data.length > 0) {
        setEstoqueAtual(data.data[0].estoque);
      }
    });
  }

  async function Fiscal() {
    const promises = [
      APIFISCAL.get("COFINS.json").then(function (result) {
        let Result = [];
        for (var i = 0; i < result.data.length; i++) {
          Result.push({
            value: result.data[i].id,
            label: `${result.data[i].id} - ${PrimeiraMaiuscula(
              result.data[i].nome
            )}`,
          });
        }
        setDATACOFINS(Result);
      }),
      APIFISCAL.get("CSOSNICMS.json").then(function (result) {
        let Result = [];
        for (var i = 0; i < result.data.length; i++) {
          Result.push({
            value: result.data[i].id,
            label: `${result.data[i].id} - ${PrimeiraMaiuscula(
              result.data[i].nome
            )}`,
          });
        }
        setDATACSOSNICMS(Result);
      }),
      APIFISCAL.get("CSTICMS.json").then(function (result) {
        let Result = [];
        for (var i = 0; i < result.data.length; i++) {
          Result.push({
            value: result.data[i].id,
            label: `${result.data[i].id} - ${PrimeiraMaiuscula(
              result.data[i].nome
            )}`,
          });
        }
        setDATACSTICMS(Result);
      }),
      APIFISCAL.get("CSTIPI.json").then(function (result) {
        let Result = [];
        for (var i = 0; i < result.data.length; i++) {
          Result.push({
            value: result.data[i].id,
            label: `${result.data[i].id} - ${PrimeiraMaiuscula(
              result.data[i].nome
            )}`,
          });
        }
        setDATACSTIPI(Result);
      }),
      APIFISCAL.get("CSTORIGEM.json").then(function (result) {
        let Result = [];
        for (var i = 0; i < result.data.length; i++) {
          Result.push({
            value: result.data[i].id,
            label: `${result.data[i].id} - ${PrimeiraMaiuscula(
              result.data[i].nome
            )}`,
          });
        }

        setDATACSTORIGEM(Result);
      }),
      APIFISCAL.get("CSTPIS.json").then(function (result) {
        let Result = [];
        for (var i = 0; i < result.data.length; i++) {
          Result.push({
            value: result.data[i].id,
            label: `${result.data[i].id} - ${PrimeiraMaiuscula(
              result.data[i].nome
            )}`,
          });
        }

        setDATACSTPIS(Result);
      }),
      APIFISCAL.get("NCM.json").then(function (result) {
        let Result = [];
        for (var i = 0; i < result.data.length; i++) {
          Result.push({
            value: result.data[i].id,
            label: `${formatarNCM(result.data[i].codigo)} - ${
              result.data[i].item
            } ${PrimeiraMaiuscula(result.data[i].descricao)}`,
            cest: result.data[i].cest,
            codigo: parseInt(result.data[i].codigo),
          });
        }

        setDATANCM(Result);
      }),
      APIFISCAL.get("CFOP.json").then(function (result) {
        let Result = [];
        for (var i = 0; i < result.data.length; i++) {
          Result.push({
            value: result.data[i].codigo,
            label: `${formatarCFOP(
              result.data[i].codigo
            )} - ${PrimeiraMaiuscula(result.data[i].descricao)}`,
          });
        }

        setDATACFOP(Result);

        //item.operacao === "S" ? (
      }),
    ];

    // Wait for all promises to resolve
    await Promise.all(promises);

    // After all promises have resolved, you can return or perform any other actions
    return;
  }

  useEffect(() => {
    if (NCM?.cest) {
      setCEST(NCM.cest);
    }
  }, [NCM]);

  useEffect(() => {
    Fiscal().then(() => {
      // Set the state variable to true when Fiscal completes
      setFiscalCompleted(true);
    });
    API.get("/v2/produtos/estoque/ativo").then(function (result) {
      let dados = result.data;
      dados.push({ value: 0, label: "Sem Produto" });
      setDATAPRODESTOQUE(result.data);
    });

    API.get("v1/consulta/todos/ingredientes").then(function (result) {
      let dados = result.data;

      dados = result.data.map((item) => ({
        value: item.id,
        label: `${PrimeiraMaiuscula(item.descricao)} (${item.unidade})`,
      }));
      dados.push({ value: 0, label: "Sem Insulmo" });

      setDATAFICHAINSULMO(dados);
    });
  }, []);

  const [DadosExtra, setDadosExtra] = useState([]);

  function SalvarSabor() {
    let body = {};
    body.id = 0;
    body.category = parseInt(CategorySelected);
    body.flavorOld = SaborAntigo;
    body.type = DescTipoSabor.value;
    body.name = PrimeiraMaiuscula(NomeProduto);
    body.description = PrimeiraMaiuscula(DescricaoProduto);
    body.base64 = test64;
    body.size = TamanhoPizza;

    API.post("/v2/flavor", body);
    close();
    //window.location.reload();
  }

  function SalvarProduto() {
    let DadosAdicionaisAlterados = DadosExtra;

    for (var i = 0; i < DadosAdicionaisAlterados.length; i++) {
      let DadosAlteradosNome = JSON.parse(BuscaLocal("EXTRA_NOME_" + i));
      let DadosAlteradosValor = JSON.parse(BuscaLocal("EXTRA_VALOR_" + i));
      let DadosAlteradosDescricao = JSON.parse(
        BuscaLocal("EXTRA_DESCRICAO_" + i)
      );

      for (var j = 0; j < DadosAdicionaisAlterados[i].extra.length; j++) {
        if (DadosAlteradosNome) {
          try {
            DadosAdicionaisAlterados[i].extra[j].name = DadosAlteradosNome[j];
          } catch (error) {}
        }
        if (DadosAlteradosDescricao) {
          try {
            DadosAdicionaisAlterados[i].extra[j].description =
              DadosAlteradosDescricao[j];
          } catch (error) {}
        }
        if (DadosAlteradosValor) {
          try {
            DadosAdicionaisAlterados[i].extra[j].value = RemoveMascara(
              DadosAlteradosValor[j]
            );
          } catch (error) {}
        }
      }

      ApagaLocal("EXTRA_DESCRICAO_" + i);
    }

    let body = {};
    body.id = codigo;
    body.category = parseInt(CategorySelected);
    body.name = PrimeiraMaiuscula(NomeProduto);
    body.description = PrimeiraMaiuscula(DescricaoProduto);
    body.url = urlProduct;
    body.base64 = test64;
    body.value = Preco;
    body.value_discont = PrecoDesconto;
    body.value_percent = PercentualDesconto;
    body.extra = DadosAdicionaisAlterados;
    body.people = parseInt(selectedOption);
    body.quanty = EstoqueAtual;

    body.cest = CEST ? CEST : 0;
    body.ncm = NCM?.codigo ? NCM.codigo : 0;

    body.cfop = CFOP?.value ? CFOP.value : 0;
    body.cstipi = CSTIPI?.value ? CSTIPI.value : 0;
    body.csticms = CSTICMS?.value ? CSTICMS.value : 0;
    body.cstpis = CSTPIS?.value ? CSTPIS.value : 0;
    body.cstcofins = CSTCOFINS?.value ? CSTCOFINS.value : 0;
    body.csosn = CSOSN?.value ? CSOSN.value : 0;

    body.icms = ICMS;
    body.ipi = IPI;
    body.pis = PIS;
    body.cofins = COFINS;
    body.frete = FRETE;
    body.un = selectUN?.value ? selectUN.value : "UN";
    body.fidelidade = PontosFidelidade;
    body.entrega = RemoveMascara(AdicionalEntrega)
      ? RemoveMascara(AdicionalEntrega)
      : 0;
    body.novidade = Novidade ? 1 : 0;
    body.vembuscar = ApenasVB ? 1 : 0;
    body.delivery = ApenasEntrega ? 1 : 0;
    body.dias = DisponivelDiasSemana ? 1 : 0;
    body.segunda = Segunda ? 1 : 0;
    body.terca = Terca ? 1 : 0;
    body.quarta = Quarta ? 1 : 0;
    body.quinta = Quinta ? 1 : 0;
    body.sexta = Sexta ? 1 : 0;
    body.sabado = Sabado ? 1 : 0;
    body.domingo = Domingo ? 1 : 0;
    body.stock_min = EstoqueMinimo ? EstoqueMinimo : 0;

    if (usaEstoque) {
      body.stock = 1;
    } else {
      body.stock = 0;
    }

    setloading(true);
    API.post("/v2/product", body).then(function (response) {
      Notifica("Produto salvo com sucesso!");
      close();
      setloading(false);
    });

    // window.location.reload();
  }

  const divStyle = {
    position: "fixed",
    top: "0",
    right: "0",
    width: MonitorSmall() ? "100%" : "40%",
    height: "100%",
    backgroundColor: "rgba(255, 255, 255,1)",
    zIndex: "999",
    padding: "20px",
    overflowY: "auto",
  };

  const divStyleBackground = {
    position: "fixed",
    top: "0",
    right: "0",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.1)",
    zIndex: "99999",
  };

  useEffect(() => {
    if (usaEstoque) {
      BuscaEstoque();
    }
  }, [usaEstoque]);

  useEffect(() => {
    if (fiscalCompleted) {
      API.get(`v2/user/id`).then((response) => {
        setUserID(response.data);
      });

      if (sabor != undefined && tipo == 2) {
        setAba(4);
      }

      API.get(`v1/consulta/generica/tipo_sabor/*/*/*`).then((response) => {
        setTipoSabor(response.data);
        if (response.data.length > 0) {
          setTipoSaborSelecionado(response.data[0].id);
        }
      });

      test64 = "";
      API.get("/v1/categorias").then((response) => {
        setCategory(response.data);
        if (data == undefined) {
          if (category > 0) {
            setCategorySelected(category);
          } else {
            setCategorySelected(response.data[0].codigo);
          }
        }
      });

      if (tipo == 1) {
        if (product != undefined) {
          let Size = [];

          for (let i = 0; i < product.length; i++) {
            Size.push({ id: product[i].id, size: product[i].name, value: 0 });
          }

          setDescTipoSabor({
            value: sabor.type,
            label: sabor.type,
          });

          if (sabor.product != undefined) {
            if (sabor.product.length > 0) {
              Size = [];

              for (let i = 0; i < sabor.product.length; i++) {
                Size.push({
                  id: sabor.product[i].id,
                  size: sabor.product[i].name,
                  value: sabor.product[i].value,
                });
              }
            }

            setTipoSaborSelecionado(sabor.type);
            setNomeProduto(sabor.flavor);
            setDescricaoProduto(sabor.description);
            setSaborAntigo(sabor.flavor);
          }
          setTamanhoPizza(Size);
        }
      }

      if (data != undefined) {
        setNomeProduto(data.name);
        if (data.img != "") {
          setUrlProduct(atob(data.img));
        }

        API.get(`v1/produto/adicional/${data.id}`).then((response) => {
          let dados = [];

          response.data.forEach((Extra) => {
            let extras = [];
            Extra.categoryItens.forEach((Item) => {
              extras.push({
                id: Item.itensId,
                name: Item.itensName,
                description: Item.itensDescription,
                value: Item.itensValue,
                status: Item.itensStatus,
                stock: Item.itensProdStock,
                insulmo: Item.itensInsumo,
                //Insulmo
              });
            });

            dados.push({
              id: Extra.categoryId,
              name: Extra.categoryName,
              min: Extra.categoryMin,
              max: Extra.categoryMax,
              status: Extra.categoryStatus,
              extra: extras,
            });
          });

          setDadosExtra(dados);
        });

        setCategorySelected(parseInt(data.category));
        setDescricaoProduto(data.description);
        setSelectedOption(data.people);
        setEstoqueAtual(parseInt(data.quanty));
        setPreco(parseFloat(data.value));

        setPercentualDesconto(parseFloat(data.value_percent));
        setPrecoDesconto(parseFloat(data.value_discont));
        setPontosFidelidade(data.fidelidade ? parseInt(data.fidelidade) : 0);
        setAdicionalEntrega(
          data.tax_delivery ? parseFloat(data.tax_delivery) : 0
        );

        setSegunda(parseInt(data.segunda) === 1);
        setTerca(parseInt(data.terca) === 1);
        setQuarta(parseInt(data.quarta) === 1);
        setQuinta(parseInt(data.quinta) === 1);
        setSexta(parseInt(data.sexta) === 1);
        setSabado(parseInt(data.sabado) === 1);
        setDomingo(parseInt(data.domingo) === 1);
        setDisponivelDiasSemana(parseInt(data.dias) === 1);
        setNovidade(parseInt(data.new) === 1);
        setApenasVB(parseInt(data.vembuscar) === 1);
        setApenasEntrega(parseInt(data.delivery) === 1);

        setExternalCode(data.externalCode);
        // setNCM(data.ncm);
        let resultNCM = DATANCM.find((item) => item.codigo === data.ncm);
        // resultNCM.cest = data.cest;

        setNCM(resultNCM);
        setCEST(data.cest);

        setCFOP(DATACFOP.find((item) => item.value === data.cfop));
        setCSTIPI(DATACSTIPI.find((item) => item.value === data.cstipi));
        setCSTICMS(DATACSTICMS.find((item) => item.value === data.csticms));
        setCSTPIS(DATACSTPIS.find((item) => item.value === data.cstpis));
        setCSTCOFINS(DATACOFINS.find((item) => item.value === data.cstcofins));
        setCSOSN(DATACSOSNICMS.find((item) => item.value === data.csosn));
        setICMS(data.icms);
        setIPI(data.ipi);
        setPIS(data.pis);
        setCOFINS(data.cofins);
        setFRETE(data.frete);

        setselectUN({ value: data.un, label: data.un });

        if (data.ifood_id != "" || data.ifood_id != undefined) {
          setUrlProduct(data.ifood_img);
        }

        if (data.ifood_img == "") {
          setUrlProduct(
            document.querySelector('link[name="url-foto"]').href +
              "/fotos/" +
              btoa(data.externalCode)
          );
        }
        // console.log(data.stock);
        if (parseFloat(data.stock) > 0) {
          setusaEstoque(true);
        }

        setEstoqueMinimo(data.stock_min ? data.stock_min : 0);
        //console.log(data.stock_min);
      }
    }
  }, [fiscalCompleted]);

  function funcaoSelected(value) {
    setCategorySelected(value);
  }

  function funcaoSelectedSabor(value) {
    setTipoSaborSelecionado(value);
  }

  function AtualizaNomeProduto(name, value) {
    setNomeProduto(PrimeiraMaiuscula(value));
  }

  function AtualizaDescricaoProduto(name, value) {
    setDescricaoProduto(PrimeiraMaiuscula(value));

    if (tipo == 1) {
      return false;
    }

    let Array = [];
    const meuArrayVirgula = value.split(",");
    const meuArrayPonto = value.split(".");
    const meuArrayPontoVirgula = value.split(";");

    if (meuArrayVirgula.length > 1) {
      Array = meuArrayVirgula;
    }
    if (meuArrayPonto.length > 1) {
      Array = meuArrayPonto;
    }
    if (meuArrayPontoVirgula.length > 1) {
      Array = meuArrayPontoVirgula;
    }

    if (Array != 1) {
      let Dados = [];
      const updatedItems = [...DadosExtra];
      const index = updatedItems.findIndex(
        (item) => item.name.toLowerCase() === "ingredientes"
      );
      try {
        Dados = updatedItems[index].extra;
      } catch (error) {}

      if (index != -1) {
      }

      for (let i = 0; i < Array.length; i++) {
        let ingrediente = "Sem " + Array[i].toLowerCase().trim();

        if (
          Dados.findIndex(
            (item) => item.name.toLowerCase() === ingrediente.toLowerCase()
          ) == -1
        ) {
          Dados.push({
            id: 0,
            name: PrimeiraMaiuscula(ingrediente.trim()),
            description: "",
            status: 1,
            value: 0,
          });
        }
      }

      if (index != -1) {
        updatedItems[index].extra = Dados;
      } else {
        updatedItems.push({
          id: 0,
          name: PrimeiraMaiuscula("ingredientes"),
          min: 0,
          max: Array.length,
          status: 1,
          extra: Dados,
        });
      }

      setDadosExtra(updatedItems);
    }
  }

  function AlteraPrecoSabor(index, valor) {
    let updatedItems = [...TamanhoPizza];
    updatedItems[index].value = RemoveMascara(valor);
    setTamanhoPizza(updatedItems);
  }

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  function resizeImage(file, maxWidth, maxHeight) {
    return new Promise((resolve, reject) => {
      const img = new Image();

      img.onload = () => {
        let width = img.width;
        let height = img.height;

        if (width > maxWidth || height > maxHeight) {
          const aspectRatio = width / height;
          if (width > maxWidth) {
            width = maxWidth;
            height = width / aspectRatio;
          }
          if (height > maxHeight) {
            height = maxHeight;
            width = height * aspectRatio;
          }
        }

        const canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;

        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob(
          (blob) => {
            resolve(blob);
          },
          "image/jpeg",
          0.9
        ); // Use 'image/png' for PNG format
      };

      img.onerror = (error) => reject(error);

      img.src = URL.createObjectURL(file);
    });
  }

  function imageToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  const handlePhotoUploadPizza = (event) => {
    setPizza(true);
    handlePhotoUpload(event);
  };

  function NovaImage(value) {
    //console.log(value);
    setBase64(value.base64);
    setUrlProduct(value.url);
    setModalImagem(false);
  }

  const handlePhotoUpload = (event) => {
    // Obtém o arquivo de imagem carregado pelo usuário

    const file = event.target.files[0];
    const photoUrl = URL.createObjectURL(file);
    setImagemSelecionada(photoUrl);
    //console.log(file);
    setModalImagem(true);
    return true;

    // // Converte o arquivo em um objeto URL que pode ser exibido na página
    // const photoUrl = URL.createObjectURL(file);
    // setUrlProduct(photoUrl);

    // // setUrlProduct(photoUrl)

    // const reader = new FileReader();

    // resizeImage(file, 300, 275)
    //   .then((resizedBlob) => {
    //     const reader = new FileReader();
    //     reader.readAsDataURL(resizedBlob);
    //     reader.onloadend = () => {
    //       const resizedBase64 = reader.result;

    //       GravaLocal("BASE64", resizedBase64);

    //       test64 = resizedBase64;
    //       setBase64(resizedBase64);

    //       // Agora você pode fazer o que desejar com a imagem redimensionada em base64
    //     };
    //   })
    //   .catch((error) => {});
  };

  useEffect(() => {
    //Evio a foto para o site, quando tem conexão

    if (Base64 != null && Pizza) {
      let name = UserID + NomeProduto.replace(/\s/g, "-");

      const headers = {
        "Content-Type": "application/json",
        nome: name,
      };

      axios
        .post("https://fotos.goopedir.com/", JSON.stringify(Base64), {
          headers,
        })
        .then((response) => {
          Notifica("Imagem carregada com sucesso!");
        })
        .catch((error) => {
          NotificaWarning("Erro ao enviar foto:", error);
        });
    }

    if (Base64 != null && ExternalCode > 0) {
      const headers = {
        "Content-Type": "application/json",
        nome: ExternalCode,
      };

      axios
        .post("https://fotos.goopedir.com/photo.php", JSON.stringify(Base64), {
          headers,
        })
        .then((response) => {
          console.log(response.data.url);
          let body = {
            campo: "foto_ifood",
            valor: response.data.url,
            codigo: codigo,
          };

          API.post(`v1/atualiza/produto`, body);

          //vps.goopedir.com:9001/v1/atualiza/produto/8/valor_venda/19
          Notifica("Imagem carregada com sucesso!");
        })
        .catch((error) => {
          NotificaWarning("Erro ao enviar foto:", error);
        });
    }
  }, [Base64]);

  function AtualizaPreco(name, value) {
    setPreco(RemoveMascara(value));
  }

  function AtualizaPrecoDesconto(name, value) {
    setPrecoDesconto(RemoveMascara(value));
    let percentual =
      ((RemoveMascara(Preco) - RemoveMascara(value)) / RemoveMascara(Preco)) *
      100;

    setPercentualDesconto(percentual);
  }

  function AtualizaFidelidade(name, value) {
    setPontosFidelidade(value);
  }

  function AtualizaPercentual(name, value) {
    setPercentualDesconto(RemoveMascara(value));
    let NovoValor =
      RemoveMascara(Preco) -
      (RemoveMascara(Preco) * RemoveMascara(value)) / 100;

    setPrecoDesconto(NovoValor);
  }
  function AtualizaEstoque(name, value) {
    // setEstoqueAtual(RemoveMascara(parseInt(value)));
  }

  function AtualizaMinimo(name, value) {
    setEstoqueMinimo(RemoveMascara(value));
  }

  function AlteraNomeExtra(index, valor) {
    const updatedItems = [...DadosExtra];
    updatedItems[index].name = valor;
    setDadosExtra(updatedItems);
  }

  function AlteraMinExtra(index, valor) {
    const updatedItems = [...DadosExtra];
    updatedItems[index].min = valor;
    setDadosExtra(updatedItems);
  }

  function AlteraMaxExtra(index, valor) {
    const updatedItems = [...DadosExtra];
    updatedItems[index].max = valor;
    setDadosExtra(updatedItems);
  }

  function AddExtra() {
    const updatedItems = [...DadosExtra];
    updatedItems.push({
      id: 0,
      name: "",
      min: 0,
      max: 0,
      status: 1,
      extra: [],
    });
    setDadosExtra(updatedItems);
  }

  function CopyExtra() {
    setisExtra(true);
  }

  function AlteraNameExtraitem(index, valor) {
    const arraySeparado = index.split("-");

    const updatedItems = [...DadosExtra];
    updatedItems[arraySeparado[0]].extra[arraySeparado[1]].name = valor;
    setDadosExtra(updatedItems);
  }

  function AlteraValorExtraitem(index, valor) {
    const arraySeparado = index.split("-");

    const updatedItems = [...DadosExtra];
    updatedItems[arraySeparado[0]].extra[arraySeparado[1]].value =
      RemoveMascara(valor);
    setDadosExtra(updatedItems);
  }

  function AlteraDescricaoExtraitem(index, valor) {
    const arraySeparado = index.split("-");

    const updatedItems = [...DadosExtra];
    updatedItems[arraySeparado[0]].extra[arraySeparado[1]].description = valor;
    setDadosExtra(updatedItems);
  }

  function AlteraStock(index, index2, valor) {
    const updatedItems = [...DadosExtra];
    updatedItems[index].extra[index2].stock = valor.value;
    updatedItems[index].extra[index2].label = valor.label;
    setDadosExtra(updatedItems);
  }

  function AlteraInsulmo(index, index2, valor) {
    const updatedItems = [...DadosExtra];
    updatedItems[index].extra[index2].insulmo = valor.value;
    updatedItems[index].extra[index2].insulmolabel = valor.label;
    setDadosExtra(updatedItems);
  }

  function AdicionarExtra(index) {
    let updatedItems = [...DadosExtra];

    updatedItems[index].extra.push({
      id: 0,
      name: "",
      description: "",
      value: 0,
      status: 1,
      stock: 0,
    });

    setDadosExtra(updatedItems);
  }

  function Ativar(index) {
    const arraySeparado = index.split("-");

    const updatedItems = [...DadosExtra];
    updatedItems[arraySeparado[0]].extra[arraySeparado[1]].status = 1;
    setDadosExtra(updatedItems);
  }

  function Pausar(index) {
    const arraySeparado = index.split("-");

    const updatedItems = [...DadosExtra];
    updatedItems[arraySeparado[0]].extra[arraySeparado[1]].status = 0;
    setDadosExtra(updatedItems);
  }

  const Opcionais = ({ data, ID }) => {
    const [nomes, setNomes] = useState(
      data.map((extra) => PrimeiraMaiuscula(extra.name))
    );
    const [valores, setValores] = useState(
      data.map((extra) => MascaraNumerica(extra.value))
    );
    const [descricoes, setDescricoes] = useState(
      data.map((extra) => PrimeiraMaiuscula(extra.description))
    );

    const handleNomeChange = (index, value, ID) => {
      const newNomes = [...nomes];
      newNomes[index] = PrimeiraMaiuscula(value);
      setNomes(newNomes);
      // //console.log(newNomes);

      // updatedItems[index].extra.push({
      //   id: 0,
      //   name: "",
      //   description: "",
      //   value: 0,
      //   status: 1,
      // });
    };

    const OnBlurExit = (index, ID) => {
      let updatedItems = [...DadosExtra];
      updatedItems[ID].extra[index].name = nomes[index];
      updatedItems[ID].extra[index].description = descricoes[index];
      updatedItems[ID].extra[index].value = RemoveMascara(valores[index])
        ? RemoveMascara(valores[index])
        : 0;
      //console.log(updatedItems);
    };

    const handleValorChange = (index, value, ID) => {
      const newValores = [...valores];
      newValores[index] = value;
      setValores(newValores);
    };

    const handleDescricaoChange = (index, value, ID) => {
      const newDescricoes = [...descricoes];
      newDescricoes[index] = PrimeiraMaiuscula(value);
      setDescricoes(newDescricoes);
    };

    useEffect(() => {
      GravaLocal("EXTRA_NOME_" + ID, JSON.stringify(nomes));
      GravaLocal("EXTRA_VALOR_" + ID, JSON.stringify(valores));
      GravaLocal("EXTRA_DESCRICAO_" + ID, JSON.stringify(descricoes));
    }, [nomes, valores, descricoes]);

    return (
      <>
        {data.map((extra, index) => (
          <div
            key={index}
            style={{
              padding: "10px",
              border: "1px solid black",
              borderRadius: "5px",
              marginBottom: "10px",
            }}
          >
            <div style={{ display: "flex" }}>
              <TextField
                size="small"
                label="Nome"
                value={nomes[index]}
                onChange={(e) => handleNomeChange(index, e.target.value, ID)}
                onBlur={(e) => OnBlurExit(index, ID)}
                sx={{ marginRight: "10px", width: "100%" }}
              />

              <TextField
                size="small"
                label="Valor"
                value={valores[index]}
                onChange={(e) => handleValorChange(index, e.target.value, ID)}
                onBlur={(e) => OnBlurExit(index, ID)}
                sx={{ marginBottom: "15px", width: "100%" }}
              />
            </div>
            <div style={{ width: "100%", marginRight: "10px" }}>
              <TextField
                size="small"
                label="Descrição"
                value={descricoes[index]}
                onChange={(e) =>
                  handleDescricaoChange(index, e.target.value, ID)
                }
                onBlur={(e) => OnBlurExit(index, ID)}
                sx={{ marginBottom: "15px", width: "100%" }}
              />
            </div>

            <div
              style={{
                width: "100%",
                marginRight: "10px",
                marginBottom: "10px",
              }}
            >
              <h3>
                <strong>Produto Baixa Estoque</strong>
              </h3>
              <Select
                value={DATAPRODESTOQUE.find(
                  (option) => option.value === extra.stock
                )}
                onChange={(selectedOption) =>
                  AlteraStock(ID, index, selectedOption)
                }
                options={DATAPRODESTOQUE}
                isSearchable={true}
                placeholder=""
                styles={customStyles1}
              />
            </div>

            <div
              style={{
                width: "100%",
                marginRight: "10px",
                marginBottom: "10px",
              }}
            >
              <h3>
                <strong>Insulmo Ficha Técnica</strong>
              </h3>
              <Select
                value={DATAFICHAINSULMO.find(
                  (option) => option.value === extra.insulmo
                )}
                onChange={(selectedOption) =>
                  AlteraInsulmo(ID, index, selectedOption)
                }
                options={DATAFICHAINSULMO}
                isSearchable={true}
                placeholder=""
                styles={customStyles1}
              />
            </div>

            <div style={{ width: "190px" }}>
              <StatusComponent
                status={extra.status}
                Ativar={() => Ativar(ID + "-" + index)}
                Pausar={() => Pausar(ID + "-" + index)}
              />
            </div>
          </div>
        ))}
      </>
    );
  };

  function AtualizaCEST(name, value) {
    setCEST(SoNumero(value));
  }

  function AtualizaNCM(name, value) {
    setNCM(SoNumero(value));
  }

  function SoNumero(str) {
    str = str || "";
    // Removendo todos os caracteres que não são dígitos
    const resultado = str.replace(/[^0-9]/g, "");

    // Verificando se o resultado está vazio e retornando '0' se for o caso
    return resultado === "" ? "0" : resultado;
  }

  function AtualizaFiscal(name, value) {
    if (name == "ICMS %") {
      setICMS(SoNumero(value));
    }

    if (name == "IPI %") {
      setIPI(SoNumero(value));
    }

    if (name == "PIS %") {
      setPIS(SoNumero(value));
    }

    if (name == "COFINS %") {
      setCOFINS(SoNumero(value));
    }

    if (name == "FRETE %") {
      setFRETE(SoNumero(value));
    }
  }

  function NCMCEST(valor) {
    var dados = valor.split("/");
    setNCM(dados[0]);
    setCEST(dados[1]);
  }

  function formatarNCM(input) {
    // Convertendo o input para string, se não for
    let str = String(input);

    // Removendo todos os caracteres não numéricos
    str = str.replace(/[^0-9]/g, "");

    // Aplicando a máscara de acordo com o tamanho
    if (str.length > 7) {
      return str.slice(0, 4) + "." + str.slice(4, 6) + "." + str.slice(6);
    } else if (str.length > 5) {
      return str.slice(0, 4) + "." + str.slice(4);
    } else if (str.length > 4) {
      return str.slice(0, 4) + "." + str.slice(4);
    } else {
      return str;
    }
  }

  function formatarCFOP(input) {
    // Convertendo o input para string, se não for
    let str = String(input);

    // Removendo todos os caracteres não numéricos
    str = str.replace(/[^0-9]/g, "");

    // Verificando se a entrada é válida
    if (str.length !== 4) {
      throw new Error("O CFOP deve ter exatamente 4 dígitos.");
    }

    // Aplicando a máscara
    return str.slice(0, 1) + "." + str.slice(1);
  }

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "#f5f5f5",
      minHeight: "34px",
      height: "34px",
      borderColor: "black",
      transition: "border-color 0.1s ease 0s",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#A8001C"
        : state.isFocused
        ? "#e5e5e5"
        : null,
      color: state.isSelected ? "white" : null,
    }),
  };

  const customStyles1 = {
    control: (provided) => ({
      ...provided,
      // backgroundColor: "#f5f5f5",
      minHeight: "34px",
      height: "34px",
      borderColor: "black",
      transition: "border-color 0.1s ease 0s",
      width: "100%",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#A8001C"
        : state.isFocused
        ? "#e5e5e5"
        : null,
      color: state.isSelected ? "white" : null,
    }),
  };

  function AtualizaValorSabores(index, value) {
    setTamanhoPizza((prevState) => {
      return prevState.map((item, i) => {
        if (i === index) {
          return { ...item, value: parseFloat(RemoveMascara(value)) };
        } else {
          return item;
        }
      });
    });
  }

  return (
    <>
      <div style={divStyleBackground}>
        <div style={divStyle}>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <strong onClick={close} style={{ cursor: "pointer" }}>
              X
            </strong>
          </div>
          <div style={{ height: "7rem" }}>
            <h1 style={{ fontSize: "2rem" }}>
              <strong>
                {NomeProduto != ""
                  ? PrimeiraMaiuscula(NomeProduto)
                  : "Novo Item"}
              </strong>
            </h1>
            {tipo != 1 ? (
              <>
                <div style={{ padding: "10px" }}>
                  <div style={{ display: "flex", gap: "10px" }}>
                    <div
                      style={{
                        borderBottom: `3px solid ${
                          Aba == 1 ? "rgb(168, 0, 28)" : "rgba(168, 0, 28, 0.3)"
                        }`,
                        color: `${
                          Aba == 1 ? "rgb(168, 0, 28)" : "rgba(168, 0, 28, 0.3)"
                        }`,
                        cursor: "pointer",
                      }}
                      onClick={() => setAba(1)}
                    >
                      Principal
                    </div>
                    {pizza ? null : (
                      <>
                        <div
                          style={{
                            borderBottom: `3px solid ${
                              Aba == 2
                                ? "rgb(168, 0, 28)"
                                : "rgba(168, 0, 28, 0.3)"
                            }`,
                            color: `${
                              Aba == 2
                                ? "rgb(168, 0, 28)"
                                : "rgba(168, 0, 28, 0.3)"
                            }`,
                            cursor: "pointer",
                          }}
                          onClick={() => setAba(2)}
                        >
                          Preço / Estoque
                        </div>
                      </>
                    )}
                    <div
                      style={{
                        borderBottom: `3px solid ${
                          Aba == 3 ? "rgb(168, 0, 28)" : "rgba(168, 0, 28, 0.3)"
                        }`,
                        color: `${
                          Aba == 3 ? "rgb(168, 0, 28)" : "rgba(168, 0, 28, 0.3)"
                        }`,
                        cursor: "pointer",
                      }}
                      onClick={() => setAba(3)}
                    >
                      Extra
                    </div>
                    <div
                      style={{
                        borderBottom: `3px solid ${
                          Aba == 5 ? "rgb(168, 0, 28)" : "rgba(168, 0, 28, 0.3)"
                        }`,
                        color: `${
                          Aba == 5 ? "rgb(168, 0, 28)" : "rgba(168, 0, 28, 0.3)"
                        }`,
                        cursor: "pointer",
                      }}
                      onClick={() => setAba(5)}
                    >
                      Fiscal
                    </div>
                    <div
                      style={{
                        borderBottom: `3px solid ${
                          Aba == 6 ? "rgb(168, 0, 28)" : "rgba(168, 0, 28, 0.3)"
                        }`,
                        color: `${
                          Aba == 6 ? "rgb(168, 0, 28)" : "rgba(168, 0, 28, 0.3)"
                        }`,
                        cursor: "pointer",
                      }}
                      onClick={() => setAba(6)}
                    >
                      Ficha Técnica
                    </div>
                  </div>
                </div>
              </>
            ) : null}
          </div>
          {Aba == 6 ? (
            <>
              <FichaTecnica codigo={codigo} ValorVenda={setPreco} />
            </>
          ) : null}
          {loading && <LoadingOverlay />}{" "}
          {Aba == 1 ? (
            <>
              <div
                style={{
                  height: "350px",
                  width: "100%",
                  display: "flex",
                }}
              >
                {ModalImagem ? (
                  <>
                    <AppCortar
                      imagem={ImagemSelecionada}
                      NovaImage={NovaImage}
                    />
                  </>
                ) : (
                  <>
                    <div
                      style={{
                        height: "100%",
                        width: "350px",
                        padding: "10px",
                      }}
                    >
                      <h3>
                        <strong>Foto do item</strong>
                      </h3>
                      <h4
                        style={{ color: "rgba(0,0,0,0.5)", fontSize: "0.9rem" }}
                      >
                        Aparece na listagem e no detalhe do item
                      </h4>
                      <div
                        style={{
                          height: "150px",
                          width: "100%",
                          marginTop: "20px",
                          border: "2px dashed #a6a6a5",
                          borderRadius: "5px",
                        }}
                      >
                        <label htmlFor={"img"}>
                          <img
                            style={{ height: "100%", width: "100%" }}
                            src={`${urlProduct}`}
                          />
                          {/* {Base64 != null && <img style={{ height: '100%', width: '100%' }} src={Base64} alt="Imagem em base64" />} */}
                        </label>

                        <input
                          type="file"
                          name="img"
                          id="img"
                          onChange={(event) => handlePhotoUpload(event)}
                          accept="image/*"
                          style={{ display: "none" }}
                        />
                        {/* {data.ifood_img == '' ?
                                     : null} */}
                      </div>
                      Carregar a foto aqui imagem
                      <h5 style={{ fontSize: "0.9rem" }}>
                        Máximo: <strong>10 MB</strong>
                      </h5>
                      <h5 style={{ fontSize: "0.9rem" }}>
                        Resolução mín: <strong>300x275</strong>
                      </h5>
                    </div>
                    <div
                      style={{ height: "100%", width: "100%", padding: "10px" }}
                    >
                      <h3>
                        <strong>Categoria</strong>{" "}
                        <span style={{ color: "rgba(0,0,0,0.5)" }}>
                          (obrigatório)
                        </span>
                      </h3>
                      <div style={{ flex: "0 0 100%", zIndex: 1 }}>
                        <Selected
                          data={Category}
                          descricao={"descricao"}
                          value={"codigo"}
                          selected={CategorySelected}
                          funcaoSelected={funcaoSelected}
                          ativo={tipo == 1 ? 1 : 0}
                        />
                      </div>
                      <h3>
                        <strong>
                          Nome do {tipo == 1 ? "Sabor" : "Produto"}
                        </strong>{" "}
                        <span style={{ color: "rgba(0,0,0,0.5)" }}>
                          (obrigatório)
                        </span>
                      </h3>
                      <InputPedido
                        Descricao=""
                        Retorno={AtualizaNomeProduto}
                        Valor={PrimeiraMaiuscula(NomeProduto)}
                      />
                      <h3>
                        <strong>
                          Descrição do {tipo == 1 ? "Sabor" : "Produto"}
                        </strong>{" "}
                      </h3>
                      <InputPedido
                        Descricao=""
                        Retorno={AtualizaDescricaoProduto}
                        Valor={PrimeiraMaiuscula(DescricaoProduto)}
                      />
                      {tipo != 1 ? (
                        <>
                          <h3>
                            <strong>Unidade do Produto</strong>{" "}
                          </h3>
                          <Select
                            value={selectUN}
                            onChange={setselectUN}
                            options={Option}
                            isSearchable={true}
                            placeholder=""
                            styles={customStyles}
                          />
                        </>
                      ) : (
                        <>
                          <h3>
                            <strong>Tipo</strong>{" "}
                          </h3>
                          <Select
                            value={DescTipoSabor}
                            onChange={setDescTipoSabor}
                            options={OptionTipoSabor}
                            isSearchable={true}
                            placeholder=""
                            styles={customStyles}
                          />
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>

              {tipo == 1 ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      marginBottom: "15px",
                    }}
                  >
                    {TamanhoPizza.map((extra, index) => (
                      <>
                        <InputPedido
                          Descricao={extra.size}
                          index={index}
                          Retorno={AtualizaValorSabores}
                          Valor={MascaraMonetaria(extra.value)}
                        />
                      </>
                    ))}
                  </div>
                </>
              ) : (
                <div>
                  {!ModalImagem ? (
                    <>
                      <div
                        style={{
                          width: "100%",
                          borderRadius: "5px",
                          backgroundColor: "#f2f2f2",
                          padding: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        <h2 style={{ fontSize: "1.3rem" }}>
                          <strong>
                            Para quantas pessoas esse item se aplica?
                          </strong>
                        </h2>
                        <div style={{ display: "flex", padding: "5px" }}>
                          <InputGroup
                            label="Não se aplica"
                            value="0"
                            name="group1"
                            selectedValue={selectedOption}
                            onChange={handleOptionChange}
                          />
                          <InputGroup
                            label="1 pessoa"
                            svg={1}
                            value="1"
                            name="group1"
                            selectedValue={selectedOption}
                            onChange={handleOptionChange}
                          />
                          <InputGroup
                            label="2 pessoas"
                            svg={2}
                            value="2"
                            name="group1"
                            selectedValue={selectedOption}
                            onChange={handleOptionChange}
                          />
                          <InputGroup
                            label="3 pessoas"
                            svg={3}
                            value="3"
                            name="group1"
                            selectedValue={selectedOption}
                            onChange={handleOptionChange}
                          />
                          <InputGroup
                            label="4 pessoas"
                            svg={4}
                            value="4"
                            name="group1"
                            selectedValue={selectedOption}
                            onChange={handleOptionChange}
                          />
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>
              )}
            </>
          ) : null}
          {Aba == 2 ? (
            <>
              {/* Preço */}
              <h1>
                <strong>Preço e Desconto</strong>
              </h1>
              <div style={{ display: "flex", gap: "10px", marginTop: "10px" }}>
                <InputPedido
                  Descricao="Preço Atual  (obrigatório)"
                  Retorno={AtualizaPreco}
                  Valor={MascaraMonetaria(Preco)}
                />
                <InputPedido
                  Descricao="Preço com Desconto"
                  Retorno={AtualizaPrecoDesconto}
                  Valor={MascaraMonetaria(PrecoDesconto)}
                />

                <InputPedido
                  Descricao="% de Desconto"
                  Retorno={AtualizaPercentual}
                  Valor={MascaraNumerica(PercentualDesconto)}
                />
              </div>
              <div style={{ display: "flex", gap: "10px", marginTop: "15px" }}>
                <InputPedido
                  Descricao="Valor Adicional Para Entrega"
                  Set={setAdicionalEntrega}
                  Valor={AdicionalEntrega}
                  Change={true}
                />

                <InputPedido
                  Descricao="Pontos de Fidelidade Para Resgate"
                  Retorno={AtualizaFidelidade}
                  Valor={PontosFidelidade}
                />
              </div>
              <div>
                <h3>
                  <strong>Controle de Estoque</strong>
                </h3>

                <Toggle
                  Titulo="Estoque"
                  Checked={usaEstoque}
                  Clique={() => setusaEstoque(!usaEstoque)}
                />
                {usaEstoque ? (
                  <>
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <InputPedido
                        Descricao="Estoque atual"
                        Retorno={AtualizaEstoque}
                        Valor={parseInt(EstoqueAtual)}
                      />
                      <InputPedido
                        Descricao="Estoque Mínimo"
                        Retorno={AtualizaMinimo}
                        Valor={MascaraNumerica(EstoqueMinimo)}
                      />
                      <Button
                        descricao="Movimentar Estoque"
                        evento={MovimentarEstoque}
                        color="white"
                        borderColor="#269931"
                        fontColor="#269931"
                        BorderRadius="5px"
                        // sx={{ height: "100%" }}
                      />
                    </div>
                  </>
                ) : null}
                <h3>
                  <strong>Disponibilidade Em Dias Específico</strong>
                </h3>
                <Toggle
                  Titulo="Disponibilidade"
                  Checked={DisponivelDiasSemana}
                  Clique={() => setDisponivelDiasSemana(!DisponivelDiasSemana)}
                />
                {DisponivelDiasSemana == 1 ? (
                  <div
                    style={{
                      display: "flex",
                      gap: "5px",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <ComponentDiaSemana
                      Texto="S"
                      Selected={Segunda}
                      OnClick={() => setSegunda(!Segunda)}
                    />
                    <ComponentDiaSemana
                      Texto="T"
                      Selected={Terca}
                      OnClick={() => setTerca(!Terca)}
                    />
                    <ComponentDiaSemana
                      Texto="Q"
                      Selected={Quarta}
                      OnClick={() => setQuarta(!Quarta)}
                    />
                    <ComponentDiaSemana
                      Texto="Q"
                      Selected={Quinta}
                      OnClick={() => setQuinta(!Quinta)}
                    />
                    <ComponentDiaSemana
                      Texto="S"
                      Selected={Sexta}
                      OnClick={() => setSexta(!Sexta)}
                    />
                    <ComponentDiaSemana
                      Texto="S"
                      Selected={Sabado}
                      OnClick={() => setSabado(!Sabado)}
                    />
                    <ComponentDiaSemana
                      Texto="D"
                      Selected={Domingo}
                      OnClick={() => setDomingo(!Domingo)}
                    />
                  </div>
                ) : null}
              </div>

              <h3>
                <strong>Parametros Site</strong>
              </h3>
              <Toggle
                Titulo="Novidade"
                Checked={Novidade}
                Clique={() => setNovidade(!Novidade)}
              />
              <Toggle
                Titulo="Apenas Vem Buscar"
                Checked={ApenasVB}
                Clique={() => setApenasVB(!ApenasVB)}
              />
              <Toggle
                Titulo="Apenas Delivery"
                Checked={ApenasEntrega}
                Clique={() => setApenasEntrega(!ApenasEntrega)}
              />
            </>
          ) : null}
          {Aba == 3 ? (
            <>
              <h1>
                <strong>Extra</strong>
              </h1>
              <h4>
                São os adicionais e os ingredientes que o cliente pode adicionar
                ou remover do seu item.
              </h4>

              <div
                style={{
                  height: "100%",
                  width: "100%",
                  padding: "10px",
                  overflow: "auto",
                  height: "700px",
                }}
              >
                <div
                  style={{ marginBottom: "20px", display: "flex", gap: "10px" }}
                >
                  <Button
                    descricao="Adicionar Extra"
                    evento={AddExtra}
                    color="white"
                    borderColor="#a4001b"
                    fontColor="#a4001b"
                    BorderRadius="5px"
                  />
                  <Button
                    descricao="Copiar Extra"
                    evento={CopyExtra}
                    color="white"
                    borderColor="#a4001b"
                    fontColor="#a4001b"
                    BorderRadius="5px"
                  />
                  {isExtra && (
                    <CopiaExtra
                      Extra={DadosExtra}
                      Set={setDadosExtra}
                      Close={() => setisExtra(false)}
                    />
                  )}
                </div>

                {DadosExtra.map((extra, index) => (
                  <div
                    style={{
                      border: "1px solid black",
                      width: "100%",
                      borderRadius: "5px",
                      padding: "10px",
                      marginBottom: "25px",
                    }}
                  >
                    <div key={index} style={{ display: "flex" }}>
                      <div style={{ width: "100%" }}>
                        <h3>
                          <strong>{PrimeiraMaiuscula(extra.name)}</strong>
                        </h3>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          width: "100px",
                          backgroundColor: "#cccccc",
                          textAlign: "center",
                          borderRadius: "6px",
                          fontSize: "10px",
                          color: "#3E3E3E",
                          alignItems: "center",
                          justifyItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <p>
                          <strong>
                            {extra.min == extra.max
                              ? "OBRIGATÓRIO"
                              : "OPICIONAL"}
                          </strong>
                        </p>
                      </div>
                    </div>
                    <h4 style={{ marginTop: "-5px" }}>
                      {extra.extra.length > 0
                        ? `${
                            extra.extra.length == 1
                              ? "1 opção"
                              : extra.extra.length + " opções"
                          }`
                        : null}
                    </h4>

                    <div style={{ display: "flex" }}>
                      <div style={{ width: "100%", marginRight: "10px" }}>
                        <h3>
                          <strong>Descrição</strong>
                        </h3>
                        <InputPedido
                          Descricao=""
                          index={index}
                          Retorno={AlteraNomeExtra}
                          Valor={PrimeiraMaiuscula(extra.name)}
                        />
                      </div>
                      <div style={{ width: "100px", marginRight: "10px" }}>
                        <h3>
                          <strong>Minimo</strong>{" "}
                        </h3>
                        <InputPedido
                          Descricao=""
                          index={index}
                          Retorno={AlteraMinExtra}
                          Valor={extra.min}
                        />
                      </div>
                      <div style={{ width: "100px" }}>
                        <h3>
                          <strong>Máximo</strong>
                        </h3>
                        <InputPedido
                          Descricao=""
                          index={index}
                          Retorno={AlteraMaxExtra}
                          Valor={extra.max}
                        />
                      </div>
                    </div>
                    <div>
                      <Button
                        descricao="+ Extra"
                        evento={() => AdicionarExtra(index)}
                        color="white"
                        borderColor="#a4001b"
                        fontColor="#a4001b"
                        BorderRadius="5px"
                        sx={{ marginTop: "15px", width: "100%" }}
                      />
                      Opções
                      <Opcionais data={extra.extra} ID={index} />
                    </div>
                  </div>
                ))}
              </div>
            </>
          ) : null}
          {Aba == 4 ? (
            <>
              <div
                style={{
                  height: "350px",
                  width: "100%",
                  display: "flex",
                }}
              >
                <div
                  style={{ height: "100%", width: "350px", padding: "10px" }}
                >
                  <h3>
                    <strong>Foto do item</strong>
                  </h3>
                  <h4 style={{ color: "rgba(0,0,0,0.5)", fontSize: "0.9rem" }}>
                    Aparece na listagem e no detalhe do item
                  </h4>
                  <div
                    style={{
                      height: "150px",
                      width: "100%",
                      marginTop: "20px",
                      border: "2px dashed #a6a6a5",
                      borderRadius: "5px",
                    }}
                  >
                    {/* //urlProduct   */}
                    <label htmlFor={"img"}>
                      <img
                        style={{ height: "100%", width: "100%" }}
                        src={`${urlProduct}`}
                      />
                      {/* {Base64 != null && <img style={{ height: '100%', width: '100%' }} src={Base64} alt="Imagem em base64" />} */}
                    </label>
                    <input
                      type="file"
                      name="img"
                      id="img"
                      onChange={(event) => handlePhotoUploadPizza(event)}
                      accept="image/*"
                      style={{ display: "none" }}
                    />

                    {/* {data.ifood_img == '' ?
                                    <input type="file" name="img" id="img" onChange={(event) => handlePhotoUpload(event)} accept="image/*" style={{ display: 'none' }} /> : null} */}
                  </div>
                  <h5 style={{ fontSize: "0.9rem" }}>
                    Máximo: <strong>10 MB</strong>
                  </h5>
                  <h5 style={{ fontSize: "0.9rem" }}>
                    Resolução mín: <strong>300x275</strong>
                  </h5>
                </div>
                <div style={{ height: "100%", width: "100%", padding: "10px" }}>
                  {/* <h3><strong>Categoria</strong> <span style={{ color: 'rgba(0,0,0,0.5)' }}>(obrigatório)</span></h3>
                            <div style={{ flex: '0 0 100%', zIndex: 1 }}>
                                <Selected data={Category} descricao={'descricao'} value={'codigo'} selected={CategorySelected} funcaoSelected={funcaoSelected} />
                            </div> */}
                  <h3>
                    <strong>Tipo do sabor</strong>{" "}
                    <span style={{ color: "rgba(0,0,0,0.5)" }}>
                      (obrigatório)
                    </span>
                  </h3>
                  <Selected
                    data={TipoSabor}
                    descricao={"nome"}
                    value={"id"}
                    selected={TipoSaborSelecionado}
                    funcaoSelected={funcaoSelectedSabor}
                  />
                  <h3>
                    <strong>Nome do sabor</strong>{" "}
                    <span style={{ color: "rgba(0,0,0,0.5)" }}>
                      (obrigatório)
                    </span>
                  </h3>
                  <InputPedido
                    Descricao=""
                    Retorno={AtualizaNomeProduto}
                    Valor={PrimeiraMaiuscula(NomeProduto)}
                  />
                  <h3>
                    <strong>Descrição do sabor</strong>{" "}
                  </h3>
                  <InputPedido
                    Descricao=""
                    Retorno={AtualizaDescricaoProduto}
                    Valor={PrimeiraMaiuscula(DescricaoProduto)}
                  />
                </div>
              </div>

              <div>
                {TamanhoPizza.map((pizza, index) => (
                  <>
                    <h3>
                      <strong>{pizza.size}</strong>{" "}
                    </h3>
                    <InputPedido
                      Descricao=""
                      index={index}
                      Retorno={AlteraPrecoSabor}
                      Valor={MascaraMonetaria(pizza.value)}
                    />
                  </>
                ))}

                {/* <div style={{ width: '100%', borderRadius: '5px', backgroundColor: '#f2f2f2', padding: '10px' }}>
                            <h2 style={{ fontSize: '1.3rem' }}><strong>Para quantas pessoas esse item se aplica?</strong></h2>
                            <div style={{ display: 'flex', padding: '5px' }}>
                                <InputGroup label="Não se aplica" value="0" name="group1" selectedValue={selectedOption} onChange={handleOptionChange} />
                                <InputGroup label="1 pessoa" svg={1} value="1" name="group1" selectedValue={selectedOption} onChange={handleOptionChange} />
                                <InputGroup label="2 pessoas" svg={2} value="2" name="group1" selectedValue={selectedOption} onChange={handleOptionChange} />
                                <InputGroup label="3 pessoas" svg={3} value="3" name="group1" selectedValue={selectedOption} onChange={handleOptionChange} />
                                <InputGroup label="4 pessoas" svg={4} value="4" name="group1" selectedValue={selectedOption} onChange={handleOptionChange} />
                            </div>

                        </div> */}
              </div>
            </>
          ) : null}
          {Aba == 5 ? (
            <>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                }}
              >
                <div style={{ height: "100%", width: "100%", padding: "10px" }}>
                  {/* <h3><strong>Unidade</strong> <span style={{ color: 'rgba(0,0,0,0.5)' }}></span></h3> */}

                  <span>NCM</span>
                  <Select
                    value={NCM}
                    onChange={setNCM}
                    options={DATANCM}
                    isSearchable={true}
                    placeholder=""
                    styles={customStyles1}
                  />
                  {/* <div style={{ display: "flex", gap: "10px" }}>
                    <InputPedido
                      Descricao=""
                      Retorno={AtualizaNCM}
                      Valor={NCM}
                    />
                    <select
                      style={{
                        height: "34px",
                        width: "100%",
                        border: "1px solid",
                        marginBottom: "10px",
                      }}
                      onChange={(e) => NCMCEST(e.target.value)}
                    >
                      <option value={-1} selected={true}>
                        Selecione
                      </option>
                      {DATANCM.map((item, index) => (
                        <option
                          key={index}
                          value={item.codigo + "/" + item.cest}
                          selected={NCM == item.codigo ? true : false}
                        >
                          {formatarNCM(item.codigo) +
                            " - " +
                            PrimeiraMaiuscula(item.descricao)}
                        </option>
                      ))}
                    </select>
                  </div> */}

                  <span>CEST</span>
                  <InputPedido
                    Tipo={"text"}
                    Descricao=""
                    Retorno={AtualizaCEST}
                    Valor={CEST}
                  />

                  <span>CFOP</span>
                  <Select
                    value={CFOP}
                    onChange={setCFOP}
                    options={DATACFOP}
                    isSearchable={true}
                    placeholder=""
                    styles={customStyles1}
                  />

                  <span>CST IPI</span>
                  <Select
                    value={CSTIPI}
                    onChange={setCSTIPI}
                    options={DATACSTIPI}
                    isSearchable={true}
                    placeholder=""
                    styles={customStyles1}
                  />

                  <span>CST ICMS</span>
                  <Select
                    value={CSTICMS}
                    onChange={setCSTICMS}
                    options={DATACSTICMS}
                    isSearchable={true}
                    placeholder=""
                    styles={customStyles1}
                  />

                  <span>CST PIS</span>
                  <Select
                    value={CSTPIS}
                    onChange={setCSTPIS}
                    options={DATACSTPIS}
                    isSearchable={true}
                    placeholder=""
                    styles={customStyles1}
                  />

                  <span>CST COFINS</span>

                  <Select
                    value={CSTCOFINS}
                    onChange={setCSTCOFINS}
                    options={DATACOFINS}
                    isSearchable={true}
                    placeholder=""
                    styles={customStyles1}
                  />

                  <span>CSOSN</span>
                  <Select
                    value={CSOSN}
                    onChange={setCSOSN}
                    options={DATACSOSNICMS}
                    isSearchable={true}
                    placeholder=""
                    styles={customStyles1}
                  />

                  <div
                    style={{ display: "flex", gap: "10px", marginTop: "10px" }}
                  >
                    <InputPedido
                      Descricao="ICMS %"
                      Retorno={AtualizaFiscal}
                      Valor={ICMS}
                    />
                    <InputPedido
                      Descricao="IPI %"
                      Retorno={AtualizaFiscal}
                      Valor={IPI}
                    />
                    <InputPedido
                      Descricao="PIS %"
                      Retorno={AtualizaFiscal}
                      Valor={PIS}
                    />
                    <InputPedido
                      Descricao="COFINS %"
                      Retorno={AtualizaFiscal}
                      Valor={COFINS}
                    />
                    <InputPedido
                      Descricao="FRETE %"
                      Retorno={AtualizaFiscal}
                      Valor={FRETE}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : null}
          {Aba === 6 ? null : tipo != 1 ? (
            <>
              {!ModalImagem ? (
                <Button
                  descricao="Salvar Produto"
                  evento={SalvarProduto}
                  color="white"
                  borderColor="#a4001b"
                  fontColor="#a4001b"
                  BorderRadius="5px"
                />
              ) : null}
            </>
          ) : (
            <>
              <Button
                descricao="Salvar Sabor"
                evento={SalvarSabor}
                color="white"
                borderColor="#a4001b"
                fontColor="#a4001b"
                BorderRadius="5px"
              />
            </>
          )}
        </div>
      </div>
      {isEntradaEstoque ? (
        <ComponenteEntradaSaidaEstoque
          tipo={1}
          codigo={codigo}
          close={FechaEstoque}
        />
      ) : null}
    </>
  );
}
